import styled from "styled-components";

const DisabledButton = styled.button.attrs(() => ({
  name: "disabled-button"
}))`
  border: 1px solid;
  cursor: ${props => (props.disabled ? "disabled" : "pointer")};
  ${props => (props.disabled ? "background-color: #f2f3f4 !important;" : "")}
  ${props => (props.disabled ? `color: ${props.theme.grey} !important;` : "")}
  ${props => (props.disabled ? "border-color: #c9c9c9 !important;" : "")}
`;

const PrimaryButton = styled(DisabledButton).attrs(() => ({
  className: "btn btn-primary",
  name: "primary-button"
}))`
  &&& {
    color: #ffffff;
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
  }
`;

export const PrimaryButtonStyled = styled(PrimaryButton).attrs(() => ({
  className: "justify-content-center d-flex align-items-center",
  type: "submit"
}))`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  border-radius: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #3a8cff;
  border-color: #3a8cff;
  color: #3a8cff;

  /* firefox only */
  @supports (-moz-appearance: none) {
    line-height: ${props => `${props.height - 2}px`};
  }
`;
