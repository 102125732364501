import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon, DatePickerInput, Input } from "best-common-react";
import { addToDate, formatDate, getUTCdate, isValidDate } from "../../utils/DateUtils";

const DeleteIconHolderStyle = styled.div.attrs(() => ({
  className: "d-flex justify-content-between align-items-center ml-2 mt-2"
}))``;

const HoverIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const StopPropagationWrapper = ({ children }) => (
  <div style={{ width: "100%" }} onClick={e => e.stopPropagation()}>
    {children}
  </div>
);

const DateSelect = styled.div`
    & div.input-group-text {
        justify-content: center;
    }
    
    & div.react-datepicker {
        display: flex;
`;

const DateFormatter = ({ rosterSetting, editRosterSetting, type }) => {
  const date =
    rosterSetting[type] && isValidDate(rosterSetting[type]) ? addToDate(rosterSetting[type], 12, "hours") : ""; // DatePicker assumes American/New_York
  return (
    <DatePickerInput
      onChange={date => {
        if (type === "endDate") {
          if (rosterSetting.startDate !== "") {
            if (getUTCdate(date) < getUTCdate(rosterSetting.startDate)) {
              const newStartDate = addToDate(date, "weeks", -1).valueOf();
              editRosterSetting(rosterSetting.index, "startDate", new Date(formatDate(newStartDate)));
            }
          }
        } else if (type === "startDate") {
          if (rosterSetting.endDate !== "") {
            if (getUTCdate(date) > getUTCdate(rosterSetting.endDate)) {
              const newEndDate = addToDate(date, "weeks", 1).valueOf();
              editRosterSetting(rosterSetting.index, "endDate", new Date(formatDate(newEndDate)));
            }
          }
        }

        editRosterSetting(rosterSetting.index, type, new Date(formatDate(date)));
      }}
      value={date}
      showTimeSelect={false}
      popperPlacement="bottom-start"
      isClearable={true}
    />
  );
};

const TextInputFormatter = ({ rosterSetting, editRosterSetting, type }) => {
  return (
    <StopPropagationWrapper>
      <Input
        value={rosterSetting[type]}
        onChange={val => {
          editRosterSetting(rosterSetting.index, type, val.target.value);
        }}
      />
    </StopPropagationWrapper>
  );
};

const DeleteFormatter = ({ rosterSetting, deleteRosterSetting }) => {
  return (
    <DeleteIconHolderStyle>
      <HoverIcon
        iconName="fa-trash-alt"
        onClick={() => {
          deleteRosterSetting(rosterSetting.index);
        }}
      />
    </DeleteIconHolderStyle>
  );
};

const Container = styled.div.attrs(() => ({
  className: ""
}))`
  height: ${props => (props.height ? props.height : "250")}px;
`;

const HeaderContainer = styled.div.attrs(() => ({
  className: "d-flex"
}))`
  height: 30px;
  background-color: #dee5ed;
`;

const RowContainer = styled.div.attrs(() => ({
  className: "d-flex mb-1"
}))`
  height: 40px;
`;

const Header = styled.div.attrs(() => ({ className: "mt-1 ml-2 mr-2" }))`
  width: ${props => (props.width ? props.width : "100")}px;
`;

const RequiredHeader = ({ headerName, width }) => {
  return (
    <Header width={width}>
      <div className="d-flex">
        <div className="mr-1">{headerName}</div>
        <div style={{ color: "red" }}>*</div>
      </div>
    </Header>
  );
};

const Row = styled.div.attrs(() => ({ className: "mt-1 ml-2 mr-2" }))`
  width: ${props => (props.width ? props.width : "100")}px;
`;

const RosterSettingsTable = ({ rosterSettings, editRosterSetting, removeRosterSetting }) => {
  const [data, setData] = useState(rosterSettings);

  useEffect(() => {
    setData(rosterSettings);
  }, [rosterSettings]);

  return (
    <Container height={rosterSettings.length * 75}>
      <HeaderContainer>
        <Header width="25" />
        <RequiredHeader headerName="Start Date" width={220} />
        <RequiredHeader headerName="End Date" width={220} />
        <RequiredHeader headerName="Roster Max" width={220} />
        <Header width="220">Pitchers Max</Header>
      </HeaderContainer>
      {data &&
        data.map((rosterSetting, key) => {
          return (
            <RowContainer key={key}>
              <Row width="25">
                <DeleteFormatter rosterSetting={rosterSetting} deleteRosterSetting={removeRosterSetting} />
              </Row>
              <Row width="220">
                <DateSelect>
                  <DateFormatter rosterSetting={rosterSetting} editRosterSetting={editRosterSetting} type="startDate" />
                </DateSelect>
              </Row>
              <Row width="220">
                <DateSelect>
                  <DateFormatter rosterSetting={rosterSetting} editRosterSetting={editRosterSetting} type="endDate" />
                </DateSelect>
              </Row>
              <Row width="220">
                <TextInputFormatter
                  rosterSetting={rosterSetting}
                  editRosterSetting={editRosterSetting}
                  type="rosterCap"
                />
              </Row>
              <Row width="220">
                <TextInputFormatter
                  rosterSetting={rosterSetting}
                  editRosterSetting={editRosterSetting}
                  type="pitcherCap"
                />
              </Row>
            </RowContainer>
          );
        })}
    </Container>
  );
};

export default RosterSettingsTable;
