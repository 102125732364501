import React, { useContext, useEffect } from "react";
import { BestLabel, BrowseFiles, Icon } from "best-common-react";
import styled from "styled-components";
import { EditCustomCardContext } from "../contexts/EditCustomCardContext";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { useAlert } from "../../alerts/AlertsContext";
import { trackPromise } from "react-promise-tracker";

const BrowseFilesStyle = styled.div.attrs(() => ({
  className: "mr-2 mb-3 mt-5"
}))``;

const Logo = styled.img.attrs(() => ({
  alt: "Logo To Upload",
  className: "img-fluid mx-auto d-block"
}))`
  max-height: 14rem;
`;

const DeleteIcon = styled(Icon).attrs(() => ({
  className: "p-2"
}))``;

const LogoFilesComponent = () => {
  const editCustomCardContext = useContext(EditCustomCardContext);
  const showAlert = useAlert();
  const { dispatch } = editCustomCardContext;
  const {
    headerLogoFileName,
    primaryLogoFileName,
    homeTeamLogoFileName,
    awayTeamLogoFileName,
    headerLogo,
    primaryLogo,
    homeTeamLogo,
    awayTeamLogo
  } = editCustomCardContext.state.customCardInfo;

  useEffect(() => {
    if (headerLogoFileName) {
      trackPromise(
        LineupCardApi.getLogoByFileName(headerLogoFileName)
          .then(response => {
            addExistingImage("setHeaderLogo", "headerLogo", headerLogoFileName, response.fileBase64String);
          })
          .catch(() => {
            showAlert(`Error retrieving image!`, `danger`);
          })
      );
    }

    if (primaryLogoFileName) {
      trackPromise(
        LineupCardApi.getLogoByFileName(primaryLogoFileName)
          .then(response => {
            addExistingImage("setPrimaryLogo", "primaryLogo", primaryLogoFileName, response.fileBase64String);
          })
          .catch(() => {
            showAlert(`Error retrieving image!`, `danger`);
          })
      );
    }

    if (homeTeamLogoFileName) {
      trackPromise(
        LineupCardApi.getLogoByFileName(homeTeamLogoFileName)
          .then(response => {
            addExistingImage("setHomeTeamLogo", "homeTeamLogo", homeTeamLogoFileName, response.fileBase64String);
          })
          .catch(() => {
            showAlert(`Error retrieving image!`, `danger`);
          })
      );
    }

    if (awayTeamLogoFileName) {
      trackPromise(
        LineupCardApi.getLogoByFileName(awayTeamLogoFileName)
          .then(response => {
            addExistingImage("setAwayTeamLogo", "awayTeamLogo", awayTeamLogoFileName, response.fileBase64String);
          })
          .catch(() => {
            showAlert(`Error retrieving image!`, `danger`);
          })
      );
    }
  }, [headerLogoFileName, primaryLogoFileName, homeTeamLogoFileName, awayTeamLogoFileName]);

  const addExistingImage = (dispatchType, logoType, fileName, byteArray) => {
    const fileToAdd = {
      name: fileName,
      fileBase64String: byteArray,
      subType: logoType
    };

    dispatch({
      type: dispatchType,
      [logoType]: fileToAdd,
      edited: false
    });
  };

  const addFile = (dispatchType, logoType, file) => {
    if (file.length) {
      const fileToAdd = {
        name: file[0].name,
        fileBase64String: "",
        subType: ""
      };
      const reader = new FileReader();
      reader.onload = () => {
        fileToAdd.fileBase64String = reader.result;
        fileToAdd.subType = logoType;
        dispatch({
          type: dispatchType,
          [logoType]: fileToAdd,
          edited: true
        });
      };
      reader.readAsDataURL(file[0]);
    }
  };

  const removeFile = (dispatchType, logoType) => {
    dispatch({
      type: dispatchType,
      [logoType]: null,
      edited: true
    });
  };

  return (
    <div className="mt-2">
      <BrowseFilesStyle>
        <BestLabel>Header</BestLabel>
        <div className="d-flex align-items-center">
          <div className="col">
            <BrowseFiles
              onFileAdd={file => addFile("setHeaderLogo", "headerLogo", file)}
              onFileDelete={() => {
                dispatch({
                  type: "setHeaderLogo",
                  headerLogo: null
                });
              }}
              fileTypes={["image/png", "image/jpg", "image/jpeg"]}
            />
          </div>
          <div className="col">
            {headerLogo && headerLogo.fileBase64String && headerLogo.fileBase64String.length ? (
              <div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <Logo src={headerLogo.fileBase64String} />
                    <DeleteIcon
                      iconName="fa-times-circle"
                      onClick={() => {
                        removeFile("setHeaderLogo", "headerLogo");
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </BrowseFilesStyle>
      <BrowseFilesStyle>
        <BestLabel>Primary</BestLabel>
        <div className="d-flex align-items-center">
          <div className="col">
            <BrowseFiles
              onFileAdd={file => addFile("setPrimaryLogo", "primaryLogo", file)}
              onFileDelete={() => {
                dispatch({
                  type: "setPrimaryLogo",
                  primaryLogo: null
                });
              }}
              fileTypes={["image/png", "image/jpg", "image/jpeg"]}
            />
          </div>
          <div className="col">
            {primaryLogo && primaryLogo.fileBase64String && primaryLogo.fileBase64String.length ? (
              <div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <Logo src={primaryLogo.fileBase64String} />
                    <DeleteIcon
                      iconName="fa-times-circle"
                      onClick={() => {
                        removeFile("setPrimaryLogo", "primaryLogo");
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </BrowseFilesStyle>
      <BrowseFilesStyle>
        <BestLabel>Home Team</BestLabel>
        <div className="d-flex align-items-center">
          <div className="col">
            <BrowseFiles
              onFileAdd={file => addFile("setHomeTeamLogo", "homeTeamLogo", file)}
              onFileDelete={() => {
                dispatch({
                  type: "setHomeTeamLogo",
                  homeTeamLogo: null
                });
              }}
              fileTypes={["image/png", "image/jpg", "image/jpeg"]}
            />
          </div>
          <div className="col">
            {homeTeamLogo && homeTeamLogo.fileBase64String && homeTeamLogo.fileBase64String.length ? (
              <div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <Logo src={homeTeamLogo.fileBase64String} />
                    <DeleteIcon
                      iconName="fa-times-circle"
                      onClick={() => {
                        removeFile("setHomeTeamLogo", "homeTeamLogo");
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </BrowseFilesStyle>
      <BrowseFilesStyle>
        <BestLabel>Away Team</BestLabel>
        <div className="d-flex align-items-center">
          <div className="col">
            <BrowseFiles
              onFileAdd={file => addFile("setAwayTeamLogo", "awayTeamLogo", file)}
              onFileDelete={() => {
                dispatch({
                  type: "setAwayTeamLogo",
                  awayTeamLogo: null
                });
              }}
              fileTypes={["image/png", "image/jpg", "image/jpeg"]}
            />
          </div>
          <div className="col">
            {awayTeamLogo && awayTeamLogo.fileBase64String && awayTeamLogo.fileBase64String.length ? (
              <div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <Logo src={awayTeamLogo.fileBase64String} />
                    <DeleteIcon
                      iconName="fa-times-circle"
                      onClick={() => {
                        removeFile("setAwayTeamLogo", "awayTeamLogo");
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </BrowseFilesStyle>
    </div>
  );
};

export default LogoFilesComponent;
