import React, { useState, useEffect } from "react";
import { Checkbox } from "best-common-react";
import { useGames } from "../../contexts/GamesContext";
import { useAuth } from "../../contexts/AuthContext";
import { handleLineupSwap, saveLineup } from "../../utils/LineupUtils";
import { adjustLineupPosOptions } from "../../utils/LineupUtils";

const DhCheckbox = () => {
  const [hasDH, setHasDH] = useState(true);

  const {
    state: { teamMap, viewableTeam, selectedGames, selectedOrg },
    dispatch: gamesDispatch
  } = useGames();
  const orgId = viewableTeam?.value;

  const {
    state: { email, isAdmin }
  } = useAuth();

  const handleDhChange = () => {
    // adjust lineup position options
    const adjustedPosOptions = adjustLineupPosOptions(teamMap.get(orgId).posCodeOptions, !hasDH);
    // update lineup in teamMap to include P or not
    const lineup = teamMap.get(orgId)?.lineup;
    if (hasDH && lineup.length === 10) {
      // Removing dh
      const pitcher = lineup[9];
      //check if player in last spot has a lastName, check to see it spot is filled, could refactor to use .player later
      if (pitcher?.lastName != null) {
        // put an empty player into P
        handleLineupSwap(pitcher, null, teamMap, gamesDispatch, orgId);
      }
      const dhIndex = lineup.findIndex(player => player.position == "10");
      if (dhIndex > -1) {
        lineup[dhIndex].position = null;
      }
      // remove "P" batting order slot
      lineup.pop();
      // adding 1 back to posOptions
      adjustedPosOptions.unshift("1");
    } else if (!hasDH && lineup.length === 9) {
      //remove any pitcher position that could be set in lineup already
      const pitcherIndex = lineup.findIndex(player => player.position == "1");
      if (pitcherIndex > -1) {
        lineup[pitcherIndex].position = null;
      }
      //adding dh
      lineup.push({ battingOrder: "P", position: "1" });
      // adding 10 back to posOptions
      adjustedPosOptions.push("10");
    }
    // update hasDH flag and lineup in teamMap
    teamMap.set(orgId, {
      ...teamMap.get(orgId),
      posCodeOptions: adjustedPosOptions,
      lineup: lineup,
      hasDH: !hasDH
    });
    gamesDispatch({ type: "setTeamMap", teamMap: teamMap });
    saveLineup(gamesDispatch, orgId, email, selectedGames, true);
    setHasDH(!hasDH);
  };

  useEffect(() => {
    if (teamMap.has(orgId)) {
      setHasDH(teamMap.get(orgId).hasDH);
    }
  }, [orgId, teamMap]);

  const canEdit = isAdmin || orgId === selectedOrg?.value;

  return (
    <div className="mt-1">
      <Checkbox
        label="Designated Hitter"
        checked={hasDH}
        onChange={() => {
          handleDhChange();
        }}
        disabled={!canEdit}
      />
    </div>
  );
};

export default DhCheckbox;
