import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVICES_URL + "/default";

class DefaultLineupApi {
  static getDefaultLineup = (orgId, lefty) => {
    const url = `${BASE_URL}/${orgId}?lefty=${lefty}`;
    return axios.get(url).then(response => response.data);
  };

  static saveDefaultLineup = defaultLineupDTO => {
    const url = `${BASE_URL}/save`;
    return axios.post(url, defaultLineupDTO).then(response => response.data);
  };
}

export default DefaultLineupApi;
