import React, { useEffect, useState } from "react";
import { DataTable, BestSelect } from "best-common-react";
import { useActiveOrgKey, useGames } from "../../../contexts/GamesContext";
import { handleLineupSwap, handlePositionChange, getPlayerDisplayName, saveLineup } from "../../../utils/LineupUtils";
import { useAuth } from "../../../contexts/AuthContext";
import { LineupTableStyled } from "../../elements/LineupTableStyled";
import SavingSpinner from "../../loading/SavingSpinner";

const LineupNumberFormatter = ({ value }) => {
  return <div style={{ fontWeight: "bold", fontSize: "18px" }}>{value}</div>;
};

const LineupNameFormatter = ({ row }) => {
  const activeOrgKey = useActiveOrgKey();

  const {
    state: { teamMap, selectedOrg, selectedGames },
    dispatch: gamesDispatch
  } = useGames();

  const {
    state: { isAdmin, email }
  } = useAuth();

  const getLineupOptions = () => {
    const players =
      teamMap.get(activeOrgKey) && row?.position !== "1"
        ? teamMap.get(activeOrgKey).availablePlayers.map(player => {
            return getPlayerDisplayName(player);
          })
        : [];
    const pitchers = teamMap.get(activeOrgKey)
      ? teamMap.get(activeOrgKey).pitchers.map(player => {
          return getPlayerDisplayName(player);
        })
      : [];
    // Handle the shohei Ohtani case where twps can be both DH and P
    // find the two way player in the lineup, if two are present it will find dh one in lineup and ignore if logic to act normally
    const twoWayPlayer = { ...teamMap.get(activeOrgKey)?.lineup.find(player => player.twp) };
    if (
      // logic explained: if two way player exists AND:
      // the twp in lienup is a DH AND you are trying to edit the pitcher row
      // OR the twp in lineup is a pitcher AND you are trying to edit an empty position row OR a position row set to dh
      twoWayPlayer &&
      ((twoWayPlayer.position === "10" && row.position === "1") ||
        (twoWayPlayer.position === "1" && (row.position === null || row.position === "10")))
    ) {
      // display two way player as a option
      return [getPlayerDisplayName(twoWayPlayer), ...players, ...pitchers];
    }
    // display all avaliable players and pitchers concatenated
    return [...players, ...pitchers];
  };

  const canEdit = isAdmin || activeOrgKey === selectedOrg?.value;
  const opt = getPlayerDisplayName(row);

  const setLineupDirty = () => {
    gamesDispatch({
      type: "setLineupStatus",
      lineupDirty: true,
      orgId: activeOrgKey
    });
  };

  const handlePlayerSwap = player => {
    row?.setSaveState(true);
    player = player ? player.value : null;
    handleLineupSwap(row, player, teamMap, gamesDispatch, activeOrgKey);
    saveLineup(gamesDispatch, activeOrgKey, email, selectedGames, true);
    setLineupDirty();
    // waits 3/4 second to show user that we are saving the lineup
    setTimeout(() => row?.setSaveState(false), 750);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      {canEdit ? (
        <BestSelect
          options={getLineupOptions()}
          value={opt}
          onChange={player => {
            handlePlayerSwap(player);
          }}
          portal={document.body}
          styles={{
            container: {
              height: "36px",
              fontSize: "14px"
            },
            control: {
              height: "36px",
              minHeight: "32px"
            },
            valueContainer: {
              padding: "0 8px 0 8px"
            }
          }}
          placeHolder="select a player"
          onClick={() => {}}
          clearable={opt.label !== ""}
        />
      ) : (
        <div>{opt?.label || ""}</div>
      )}
    </div>
  );
};

const LineupPositionFormatter = ({ row }) => {
  const {
    state: { teamMap, selectedOrg, selectedGames, viewableTeam },
    dispatch: gamesDispatch
  } = useGames();

  const activeOrgKey = useActiveOrgKey();
  const posCodeOptions = teamMap?.get(activeOrgKey)?.posCodeOptions;

  const {
    state: { isAdmin, email }
  } = useAuth();

  let position;

  if (row.position) {
    if (row.position.value) {
      position = row.position;
    } else {
      position = { value: row.position, label: row.position === "10" ? "DH" : row.position };
    }
  } else {
    position = { value: "", label: "" };
  }

  const positionOptions = posCodeOptions?.map(opt => {
    return { value: opt, label: opt === "10" ? "DH" : opt };
  });

  const isDhLineup = teamMap?.get(viewableTeam?.value)?.hasDH;
  const canEdit =
    (isAdmin && isDhLineup && position.value !== "1") ||
    !isDhLineup ||
    (activeOrgKey === selectedOrg.value && isDhLineup && position.value !== "1");
  const setLineupDirty = () => {
    gamesDispatch({
      type: "setLineupStatus",
      lineupDirty: true,
      orgId: activeOrgKey
    });
  };

  const onPositionChange = newPos => {
    row?.setSaveState(true);
    handlePositionChange(row, newPos, teamMap, gamesDispatch, activeOrgKey);
    saveLineup(gamesDispatch, activeOrgKey, email, selectedGames, true);
    setLineupDirty();
    // waits 3/4 second to show user that we are saving the lineup
    setTimeout(() => row?.setSaveState(false), 750);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      {canEdit ? (
        <BestSelect
          options={positionOptions}
          placeHolder="pos"
          value={position}
          onChange={newPos => {
            onPositionChange(newPos);
          }}
          portal={document.body}
          styles={{
            container: {
              height: "36px",
              fontSize: "14px"
            },
            control: {
              height: "36px",
              minHeight: "32px"
            },
            valueContainer: {
              padding: "0 8px 0px 8px"
            }
          }}
          onClick={() => {}}
          clearable={position.value !== ""}
        />
      ) : (
        <div>{position.value}</div>
      )}
    </div>
  );
};

const LineupTable = ({ lineup = [] }) => {
  const Columns = [
    {
      key: "battingOrder",
      name: "",
      width: 35,
      formatter: LineupNumberFormatter
    },
    {
      key: "",
      name: "Lineup",
      formatter: LineupNameFormatter
    },
    {
      key: "",
      width: 120,
      name: "Pos",
      formatter: LineupPositionFormatter
    }
  ];

  return (
    <>
      <LineupTableStyled>
        <DataTable columns={Columns} data={lineup} height={440} rowHeight={40} />
      </LineupTableStyled>
    </>
  );
};

export default LineupTable;
