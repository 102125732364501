import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVICES_URL;

class UmpireCardApi {
  static generateStatsUmpireCard = statsUmpireCardInfo => {
    let url = `${BASE_URL}/umpireCard/stats`;
    return axios({
      url: url,
      method: "POST",
      data: statsUmpireCardInfo,
      responseType: "blob" // important
    }).then(response => {
      return response;
    });
  };

  static generateOfficialUmpireCard = umpireCardDTO => {
    let url = `${BASE_URL}/umpireCard/`;
    return axios({
      url: url,
      method: "POST",
      data: umpireCardDTO,
      responseType: "blob" // important
    }).then(response => {
      return response;
    });
  };
}

export default UmpireCardApi;
