import React from "react";
import { Modal, ModalHeaderWithTitleAndClose, ModalBody, ModalFooter } from "best-common-react";
import styled from "styled-components";
import { PrimaryOutlinedButtonStyled } from "../../elements/PrimaryOutlinedButton";
import { PrimaryButtonStyled } from "../../elements/PrimaryButton";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { useAlert } from "../../alerts/AlertsContext";

const ConfirmMessage = styled.div.attrs({ className: "mb-5" })`
  font-size: 1.125rem;
  font-weight: 300;
`;

const DeleteCustomCardModal = ({ isOpen, customCardId, onClose, updateCustomCardList }) => {
  const showAlert = useAlert();
  const deleteCustomCard = React.useCallback(() => {
    LineupCardApi.deleteCustomCard(customCardId).then(() => {
      updateCustomCardList();
      showAlert(`Success`, "success");
    });
    onClose(false);
  }, [customCardId]);

  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        onClose={() => {
          onClose(false);
        }}
      >
        <ModalHeaderWithTitleAndClose
          title="Delete Custom Card"
          onClick={() => {
            onClose(false);
          }}
          close={() => {
            onClose(false);
          }}
        />
        <ModalBody>
          <ConfirmMessage>Are you sure? This is a permanent action that can`&apos;`t be undone.</ConfirmMessage>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <PrimaryButtonStyled className="mr-2" onClick={deleteCustomCard} disabled={false}>
              Delete
            </PrimaryButtonStyled>
            <PrimaryOutlinedButtonStyled
              onClick={() => {
                onClose(false);
              }}
            >
              Cancel
            </PrimaryOutlinedButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteCustomCardModal;
