import React, { useState, useEffect } from "react";
import {
  BestLabel,
  BestRadio,
  BestSelect,
  BrowseFiles,
  DatePickerInput,
  DateRangePickerInput,
  Input
} from "best-common-react";
import styled from "styled-components";
import LineupCardApi from "../../../httpClients/LineupCardApi";

const Logo = styled.img.attrs(() => ({
  alt: "Logo To Upload",
  className: "img-fluid mx-auto d-block"
}))`
  max-height: 14rem;
`;

const ClubHolder = styled.div`
  &&& {
    width: 250px;
  }
`;

const TeamLogoForm = ({ setLogoFn, type }) => {
  const [selectedClub, setSelectedClub] = useState("");
  const [dateType, setDateType] = useState(1);
  const [date, setDate] = useState(new Date());
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState({});
  const [fileContent, setFileContent] = useState({});
  const [clubOptions, setClubOptions] = useState([]);
  const [clubInfo, setClubInfo] = useState([]);

  useEffect(() => {
    LineupCardApi.getClubInfo("MLB")
      .then(response => {
        setClubInfo(response);
      })
      .catch(() => {
        // Error
      });
  }, []);

  useEffect(() => {
    setClubOptions(clubInfo.sort((a, b) => (a.name > b.name ? 1 : -1)).map(ci => ({ label: ci.name, value: ci })));
  }, [clubInfo]);

  useEffect(() => {
    setLogoFn({
      date: date,
      dateRange: dateRange,
      fileName: fileName,
      fileContent: fileContent,
      dateType: dateType,
      type: type,
      selectedClubs: [selectedClub.value],
      gameType: "R" //TODO: THIS WILL NEED TO BE CHANGED FOR POST SEASON
    });
  }, [selectedClub, date, dateRange, dateType, fileName, fileContent]);

  const handleFileAdd = fileToUpload => {
    if (fileToUpload.length) {
      const file = fileToUpload[0];
      setFile(fileToUpload[0]);
      const reader = new FileReader();
      reader.onload = () => {
        setFileContent(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <ClubHolder>
                <BestLabel>Club</BestLabel>
                <BestSelect
                  placeholder="Current Team"
                  value={selectedClub}
                  options={clubOptions}
                  onChange={value => setSelectedClub(value)}
                />
              </ClubHolder>
              <div className="ml-3">
                <BestLabel>Game Date</BestLabel>
                <div className="d-flex align-items-center">
                  <div>
                    <BestRadio
                      onChange={event => setDateType(parseInt(event.target.value))}
                      value={1}
                      checked={dateType === 1}
                      id="date-type-single"
                    />
                    <BestLabel htmlFor="date-type-single">Single</BestLabel>
                    <BestRadio
                      className="ml-2"
                      onChange={event => setDateType(parseInt(event.target.value))}
                      value={2}
                      checked={dateType === 2}
                      id="date-type-multiple"
                    />
                    <BestLabel htmlFor="date-type-multiple">Multiple</BestLabel>
                  </div>
                  <div className="ml-4">
                    {dateType === 1 ? (
                      <DatePickerInput value={date} onChange={setDate} />
                    ) : (
                      <DateRangePickerInput value={dateRange} onChange={setDateRange} />
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-4">
                <BestLabel htmlFor="name">File Name</BestLabel>
                <Input
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={fileName}
                  onChange={event => setFileName(event.target.value)}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <BestLabel>File to Upload</BestLabel>
                <BrowseFiles
                  editable={true}
                  onFileAdd={file => {
                    handleFileAdd(file);
                  }}
                  onFileDelete={() => {
                    handleFileAdd([]);
                  }}
                  fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                />
              </div>
            </div>

            {file.type && fileContent.length ? (
              <div className="row mt-2">
                <div className="col-12">
                  <Logo src={fileContent} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamLogoForm;
