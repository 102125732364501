import React from "react";
import { LoadingBlocker } from "best-common-react";
import { usePromiseTracker } from "react-promise-tracker";

const CardsLoadingBlocker = () => {
  const { promiseInProgress } = usePromiseTracker();
  return <div>{promiseInProgress && <LoadingBlocker />}</div>;
};

export default CardsLoadingBlocker;
