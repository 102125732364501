import React, { useContext } from "react";
import { BestRadio } from "best-common-react";
import { EditCustomCardContext } from "../contexts/EditCustomCardContext";
import styled from "styled-components";

const RadioButtonFlexStyle = styled.div.attrs(() => ({
  className: "d-flex mr-3 align-items-center"
}))``;

const PercentageText = styled.div.attrs(() => ({
  className: "pt-1"
}))``;

const LogoTransparency = () => {
  const editCustomCardContext = useContext(EditCustomCardContext);
  const { dispatch } = editCustomCardContext;
  const { customCardInfo } = editCustomCardContext.state;

  const setTransparency = value => {
    dispatch({
      type: "setImageOpacity",
      imageOpacity: parseFloat(value)
    });
  };

  return (
    <div>
      <div>Logo Transparency</div>
      <div className="d-flex mt-2">
        <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
          <BestRadio
            value={0.5}
            onChange={event => setTransparency(event.target.value)}
            checked={customCardInfo.imageOpacity === 0.5}
          />{" "}
          <PercentageText>50%</PercentageText>
        </RadioButtonFlexStyle>
        <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
          <BestRadio
            value={0.25}
            onChange={event => setTransparency(event.target.value)}
            checked={customCardInfo.imageOpacity === 0.25}
          />{" "}
          <PercentageText>25%</PercentageText>
        </RadioButtonFlexStyle>
        <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
          <BestRadio
            value={0}
            onChange={event => setTransparency(event.target.value)}
            checked={customCardInfo.imageOpacity === 0}
          />{" "}
          <PercentageText>0%</PercentageText>
        </RadioButtonFlexStyle>
      </div>
    </div>
  );
};

export default LogoTransparency;
