import React, { useRef, useState, useEffect } from "react";
import "./Switch.css";
import styled from "styled-components";

const ToggleOptionText = styled.div.attrs(() => ({
  className: "d-flex justify-content-center m-2"
}))`
  &&& {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    color: #4c8cee;
    z-index: 1;
  }
`;

const TextButtonToggle = ({ leftOption, rightOption, onLeftActive, onRightActive }) => {
  const componentWidthRef = useRef(null);
  const left = useRef(null);
  const right = useRef(null);
  const [leftOptionActive, setLeftOptionActive] = useState(true);
  const [rightOptionActive, setRightOptionActive] = useState(false);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [componentWidth, setComponentWidth] = useState(0);

  useEffect(() => {
    if (componentWidthRef.current) {
      setComponentWidth(componentWidthRef.current.offsetWidth);
    }
    if (left.current && leftOptionActive) {
      setSliderWidth(left.current.offsetWidth + 10); // account for padding
    }
    if (right.current && rightOptionActive) {
      setSliderWidth(right.current.offsetWidth + 10); // account for padding
    }
  }, [leftOptionActive, rightOptionActive, componentWidthRef.current, left.current, right.current]);

  useEffect(() => {
    leftOptionActive ? onLeftActive() : onRightActive();
  }, [leftOptionActive, rightOptionActive]);

  const onChange = () => {
    setLeftOptionActive(rightOptionActive);
    setRightOptionActive(leftOptionActive);
  };

  return (
    <div ref={componentWidthRef}>
      <input className="react-switch-checkbox" id="react-switch-new" type="checkbox" onChange={onChange} />
      <label className="react-switch-label" htmlFor="react-switch-new" style={{ width: componentWidth + "px" }}>
        <ToggleOptionText>
          <div ref={left}>{leftOption ? leftOption.name : ""}</div>
        </ToggleOptionText>
        <span className="react-switch-button" style={{ width: sliderWidth + "px" }} />
        <ToggleOptionText>
          <div ref={right}>{rightOption ? rightOption.name : ""}</div>
        </ToggleOptionText>
      </label>
    </div>
  );
};

export default TextButtonToggle;
