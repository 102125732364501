import React, { Suspense } from "react";
import { LoadingBlocker } from "best-common-react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LineupCardsProtectedContents from "./LineupCardsProtectedContents";

const App = () => {
  return (
    <Suspense fallback={<LoadingBlocker />}>
      <BrowserRouter>
        <Switch>
          <Route component={LineupCardsProtectedContents} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
