import React from "react";
import { BestRadio, BestLabel, Checkbox } from "best-common-react";
import styled from "styled-components";
import { useSettings } from "../../../contexts/SettingsContext";

const RadioButtonFlexStyle = styled.div.attrs(() => ({
  className: "d-flex mr-3 align-items-center"
}))``;

const PercentageText = styled.div.attrs(() => ({
  className: "pt-1"
}))``;

const SettingsOptions = () => {
  const {
    state: { logoTransparency, lastNameOnly, fullWidthImage },
    dispatch: settingsDispatch
  } = useSettings();

  return (
    <div className="ml-3">
      <BestLabel style={{ color: "#92a5ba" }}>Logo Transparency</BestLabel>
      <div className="d-flex mb-4">
        <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
          <BestRadio
            value={0.5}
            onChange={() => {
              settingsDispatch({ type: "setLogoTransparency", logoTransparency: 0.5 });
            }}
            checked={logoTransparency === 0.5}
          />{" "}
          <PercentageText>50%</PercentageText>
        </RadioButtonFlexStyle>
        <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
          <BestRadio
            value={0.25}
            onChange={() => {
              settingsDispatch({ type: "setLogoTransparency", logoTransparency: 0.25 });
            }}
            checked={logoTransparency === 0.25}
          />{" "}
          <PercentageText>25%</PercentageText>
        </RadioButtonFlexStyle>
        <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
          <BestRadio
            value={0}
            onChange={() => {
              settingsDispatch({ type: "setLogoTransparency", logoTransparency: 0 });
            }}
            checked={logoTransparency === 0}
          />{" "}
          <PercentageText>0%</PercentageText>
        </RadioButtonFlexStyle>
      </div>
      <div className="mb-4">
        <Checkbox
          label="Last name only"
          checked={lastNameOnly}
          onChange={() => {
            settingsDispatch({ type: "setLastNameOnly", lastNameOnly: !lastNameOnly });
          }}
        />
      </div>
      <Checkbox
        label="Full width bottom image"
        checked={fullWidthImage}
        onChange={() => {
          settingsDispatch({ type: "setFullWidthImage", fullWidthImage: !fullWidthImage });
        }}
      />
    </div>
  );
};

export default SettingsOptions;
