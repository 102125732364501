import React, { useState, useEffect } from "react";
import { StickyFooterButtons, PrimaryButton } from "best-common-react";
import Moment from "moment";
import LineupCardApi from "../../httpClients/LineupCardApi";
import { useAlert } from "../alerts/AlertsContext";
import { formatDate } from "../../utils/DateUtils";

const LogoFooter = ({ activeTab, logoToUpload }) => {
  const showAlert = useAlert();
  const [canUpload, setCanUpload] = useState(false);

  useEffect(() => {
    setCanUpload(
      !(
        logoToUpload.fileName &&
        logoToUpload.fileContent &&
        logoToUpload.fileName.length > 0 &&
        logoToUpload.fileContent.length > 0 &&
        seasonCheck() &&
        hasValidDate()
      )
    );
  }, [logoToUpload]);

  const uploadLogo = () => {
    let logoRequest = {
      clubList: logoToUpload.selectedClubs,
      fileBase64String: logoToUpload.fileContent,
      name: logoToUpload.fileName,
      type: logoToUpload.type,
      subType: logoToUpload.gameType
    };

    if (logoToUpload.dateType === 1) {
      logoRequest.startDate = formatDate(logoToUpload.date);
    } else {
      logoRequest.startDate = formatDate(logoToUpload.dateRange.start);
      logoRequest.endDate = formatDate(logoToUpload.dateRange.end);
    }

    if (logoRequest.subType !== "R") {
      LineupCardApi.saveLogoRequestForAll(logoRequest)
        .then(() => {
          showAlert(`Successfully saved logo`, `success`);
        })
        .catch(() => {
          showAlert(`Error saving logo`, `danger`);
        });
    } else {
      LineupCardApi.saveLogoRequest(logoRequest)
        .then(() => {
          showAlert(`Successfully saved logo`, `success`);
        })
        .catch(() => {
          showAlert(`Error saving logo`, `danger`);
        });
    }
  };

  const seasonCheck = () => {
    return (
      (logoToUpload.gameType === "R" && logoToUpload.selectedClubs && logoToUpload.selectedClubs.length > 0) ||
      logoToUpload.gameType !== "R"
    );
  };

  const hasValidDate = () => {
    return (
      logoToUpload.dateType === 1 || Moment(logoToUpload.dateRange.end).isAfter(Moment(logoToUpload.dateRange.start))
    );
  };

  return (
    <div>
      <StickyFooterButtons>
        {activeTab.name === "Bottom Logo" && (
          <PrimaryButton onClick={uploadLogo} disabled={canUpload}>
            Upload Logo
          </PrimaryButton>
        )}
        {activeTab.name === "Top Logo" && (
          <PrimaryButton onClick={uploadLogo} disabled={canUpload}>
            Upload Logo
          </PrimaryButton>
        )}
        {activeTab.name === "Team Logo" && (
          <PrimaryButton onClick={uploadLogo} disabled={canUpload}>
            Upload Logo
          </PrimaryButton>
        )}
      </StickyFooterButtons>
    </div>
  );
};

export default LogoFooter;
