import React from "react";
import Games from "../components/games/Games";
import { CardBody, CardContainer, CardHeader } from "best-common-react";
import SourceCodeLink from "../components/code/SourceCodeLink";
import { GamesContextProvider } from "../contexts/GamesContext";
import LoadingSpinner from "../components/loading/LoadingSpinner";

const GamesContainer = () => {
  return (
    <div>
      <CardContainer>
        <CardHeader>
          <div className="d-flex">
            <div className="mr-2">Games</div>
            <LoadingSpinner />
          </div>
        </CardHeader>
        <CardBody>
          <GamesContextProvider>
            <Games />
          </GamesContextProvider>
        </CardBody>
      </CardContainer>
      <SourceCodeLink />
    </div>
  );
};

export default GamesContainer;
