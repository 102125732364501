import React, { useEffect, useState } from "react";
import { FormInput, DateRangePickerWithLabel } from "best-common-react";
import GamesTable from "./GamesTable";
import { useAuth } from "../../contexts/AuthContext";
import { useGames } from "../../contexts/GamesContext";
import Moment from "moment";
import LineupCardApi from "../../httpClients/LineupCardApi";
import { addToDate, formatDate, getUTCdate, sortGameDates } from "../../utils/DateUtils";
import FileHelper from "../../helper/FileHelper";
import styled from "styled-components";
import UmpireCardApi from "../../httpClients/UmpireCardApi";
import { useColorOptions, useSettings } from "../../contexts/SettingsContext";
import DownloadButton from "../elements/DownloadButton";
import { useAlert } from "../alerts/AlertsContext";
import { trackPromise } from "react-promise-tracker";
import LoadingOverlay from "../loading/LoadingOverlay";
import { AllStarConstants } from "../../constants/AllStarConstants";

const StyledOrgWrapper = styled.div.attrs(() => ({}))`
  width: 100px;
`;

const StyledDatePickerWrapper = styled.div.attrs(() => ({}))`
  padding-top: 3px;
  width: 26rem;
`;

const DisclaimerText = styled.div.attrs({ className: "mt-2" })`
  font-size: 0.9rem;
  font-weight: normal;
  color: black;
`;

const SelectGameSection = () => {
  const [generateDisabled, setGenerateDisabled] = useState(true);
  const {
    state: { selectedOrg, dates, selectedGames, otherOrg, isAllStarGame, selectedLeague, games },
    dispatch: gamesDispatch
  } = useGames();

  const {
    state: { userInfo, clubInfo, isAdmin, isSuperAdmin, isAflAdmin, isWbcAdmin, club: clubAssoc },
    dispatch: authDispatch
  } = useAuth();

  const {
    state: { logoTransparency, fullWidthImage, lastNameOnly }
  } = useSettings();

  const [loadingGames, setLoadingGames] = useState(true);

  const showAlert = useAlert();

  const colorOptions = useColorOptions();

  const leagueOptions = [
    { value: "MLB", label: "MLB", sportId: 1 },
    { value: "WBC", label: "WBC", sportId: 51 },
    { value: "AFL", label: "AFL", sportId: 17 },
    { value: "ASG", label: "All-Star", sportId: 1 }
  ];

  useEffect(() => {
    if (userInfo) {
      if (userInfo.aflClub || userInfo.aflAdmin) {
        onLeagueSelection(leagueOptions.find(leagueOpt => leagueOpt.value === "AFL"));
      } else if (userInfo?.wbcClub || userInfo?.wbcAdmin) {
        onLeagueSelection(leagueOptions.find(leagueOpt => leagueOpt.value === "WBC"));
      } else {
        onLeagueSelection(leagueOptions[0]);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    // set initial date to today
    const today = new Date();
    let oneWeekAhead = new Date();
    oneWeekAhead = oneWeekAhead.setDate(oneWeekAhead.getDate() + 7);
    gamesDispatch({ type: "setDateRange", startDate: today, endDate: oneWeekAhead });

    if (userInfo && clubInfo && Object.keys(clubInfo).length > 0) {
      if (isAdmin) {
        gamesDispatch({ type: "setSelectedOrg", selectedOrg: clubInfo[0] });
      } else {
        const userClub = clubInfo.filter(club => club.name === clubAssoc);
        gamesDispatch({ type: "setSelectedOrg", selectedOrg: userClub[0] });
      }
    }
  }, [clubInfo, userInfo, selectedLeague]);

  useEffect(() => {
    const setSchedule = schedule => {
      if (schedule && schedule.dates && schedule.dates.length > 0) {
        const allGames = [];
        schedule.dates.forEach(date => {
          if (date.games) {
            date.games.forEach(game => {
              if (
                isAdmin &&
                selectedLeague.value === "ASG" &&
                game.gameType === "A" &&
                game.seriesDescription === "All-Star Game"
              ) {
                allGames.push(game);
              } else if (game.gameType !== "A" && game.seriesDescription !== "All-Star Game") {
                allGames.push(game);
              }
            });
          }
        });
        gamesDispatch({
          type: "setGames",
          games: sortGameDates(allGames)
        });
        gamesDispatch({ type: "setSelectedGames", selectedGames: [] });
      } else {
        setLoadingGames(false);
      }
    };

    if (dates.start && dates.end && selectedOrg) {
      const startDateStr = Moment(dates.start).format("MM/DD/YYYY");
      const endDateStr = Moment(dates.end).format("MM/DD/YYYY");
      if (selectedOrg.value && selectedOrg.value !== AllStarConstants.AL && selectedOrg.value !== AllStarConstants.NL) {
        setLoadingGames(true);
        LineupCardApi.getSchedule(startDateStr, endDateStr, selectedOrg.value, 1, selectedLeague.sportId).then(
          response => {
            setSchedule(response);
          }
        );
      } else if (selectedOrg.value === AllStarConstants.AL || selectedOrg.value === AllStarConstants.NL) {
        LineupCardApi.getAllStarSchedule().then(response => {
          setSchedule(response);
        });
      }
    }
  }, [dates, selectedOrg]);

  useEffect(() => {
    setGenerateDisabled(selectedGames.length <= 0);
  }, [selectedGames]);

  // This is specifically to force the date range to match the date of the ASG
  useEffect(() => {
    if (
      isAdmin &&
      (selectedOrg.value === AllStarConstants.AL || selectedOrg.value === AllStarConstants.NL) &&
      games?.length > 0 &&
      games?.length === 1 &&
      dates.start !== games[0]?.gameDate
    ) {
      gamesDispatch({
        type: "setDateRange",
        startDate: games[0]?.gameDate,
        endDate: games[0]?.gameDate
      });
    }
  }, [selectedOrg, games, isAdmin]);

  const generateBlankDugoutCards = () => {
    if (selectedGames && selectedGames.length > 0) {
      for (const game of selectedGames) {
        const lineupCardInfo = {};
        lineupCardInfo[selectedOrg?.value] = {};
        lineupCardInfo[selectedOrg?.value].fullWidthImage = fullWidthImage; //commenting this out for 2023 ASG - JL isAllStarGame ? true : fullWidthImage;
        lineupCardInfo[selectedOrg?.value].imageOpacity = logoTransparency;
        lineupCardInfo[selectedOrg?.value].lastNameOnly = lastNameOnly;

        if (!!otherOrg) {
          lineupCardInfo[otherOrg?.value] = {};
          lineupCardInfo[otherOrg?.value].fullWidthImage = fullWidthImage; //commenting this out for 2023 ASG - JL isAllStarGame ? true : fullWidthImage;
          lineupCardInfo[otherOrg?.value].imageOpacity = logoTransparency;
          lineupCardInfo[otherOrg?.value].lastNameOnly = lastNameOnly;
          lineupCardInfo[otherOrg?.value].gamePk = game.gamePk;
          lineupCardInfo[otherOrg?.value].sportId = selectedLeague.sportId;

          if (game.doubleHeader === "Y") {
            lineupCardInfo[otherOrg?.value] = {};
          }
        }

        if (game.doubleHeader === "Y") {
          lineupCardInfo[selectedOrg?.value] = { doubleHeaderGame: game.gameNumber };
        }

        lineupCardInfo[selectedOrg?.value].gamePk = game.gamePk;
        lineupCardInfo[selectedOrg?.value].sportId = selectedLeague.sportId;

        // Removing this because we should be using the same endpoint for all
        // const seasonType = selectedGames[0].gameType !== "R" ? 2 : 1;
        LineupCardApi.getCardsForRegularSeason(
          lineupCardInfo,
          formatDate(game.gameDate),
          null,
          selectedOrg.value,
          1,
          game.gameType,
          selectedLeague.sportId
        )
          .then(response => {
            FileHelper.downloadFile(response, "pdf");
          })
          .catch(() => {
            showAlert("Error printing blank card. Please try again.", "danger");
          });
      }
    }
  };

  const printBlankUmpireCard = (game, teamType) => {
    const umpireCardInfo = {};
    umpireCardInfo.clubId = selectedOrg.value;
    umpireCardInfo.team = game.teams[teamType].team.name;
    umpireCardInfo.gameDate = Moment(game.gameDate).toDate();
    umpireCardInfo.battingOrder = [];
    umpireCardInfo.availablePlayers = [];
    umpireCardInfo.lineup = [];
    umpireCardInfo.pitchers = [];
    umpireCardInfo.colorOptions = colorOptions;
    umpireCardInfo.homeTeamId = selectedGames[0].teams.home.team.id;
    umpireCardInfo.gamePk = selectedGames[0].gamePk;
    umpireCardInfo.blankCard = true;
    umpireCardInfo.allStarGame = isAllStarGame;
    if (game.doubleHeader === "Y") {
      umpireCardInfo.doubleHeaderGame = game.gameNumber;
    }
    trackPromise(
      UmpireCardApi.generateOfficialUmpireCard(umpireCardInfo)
        .then(response => {
          FileHelper.downloadFile(response, "pdf");
        })
        .catch(() => {
          showAlert("Error printing blank card. Please try again.", "danger");
        })
    );
  };

  const printBlankAllStarCards = game => {
    let umpireCardInfo = {};
    umpireCardInfo.clubId = selectedOrg.value;
    umpireCardInfo.team = game.teams["home"].team.name;
    umpireCardInfo.gameDate = Moment(game.gameDate).toDate();
    umpireCardInfo.battingOrder = [];
    umpireCardInfo.availablePlayers = [];
    umpireCardInfo.lineup = [];
    umpireCardInfo.pitchers = [];
    umpireCardInfo.colorOptions = colorOptions;
    umpireCardInfo.homeTeamId = selectedGames[0].teams.home.team.id;
    umpireCardInfo.gamePk = selectedGames[0].gamePk;
    umpireCardInfo.blankCard = true;
    umpireCardInfo.allStarGame = isAllStarGame;
    UmpireCardApi.generateOfficialUmpireCard(umpireCardInfo)
      .then(response => {
        FileHelper.downloadFile(response, "pdf");
      })
      .catch(() => {
        showAlert("Error printing blank card. Please try again.", "danger");
      });

    umpireCardInfo = {};
    umpireCardInfo.clubId = otherOrg.value;
    umpireCardInfo.team = game.teams["away"].team.name;
    umpireCardInfo.gameDate = Moment(game.gameDate).toDate();
    umpireCardInfo.battingOrder = [];
    umpireCardInfo.availablePlayers = [];
    umpireCardInfo.lineup = [];
    umpireCardInfo.pitchers = [];
    umpireCardInfo.colorOptions = colorOptions;
    umpireCardInfo.homeTeamId = selectedGames[0].teams.home.team.id;
    umpireCardInfo.gamePk = selectedGames[0].gamePk;
    umpireCardInfo.blankCard = true;
    umpireCardInfo.allStarGame = isAllStarGame;
    UmpireCardApi.generateOfficialUmpireCard(umpireCardInfo)
      .then(response => {
        FileHelper.downloadFile(response, "pdf");
      })
      .catch(() => {
        showAlert("Error printing blank card. Please try again.", "danger");
      });
  };

  const generateBlankUmpireCards = () => {
    if (selectedGames && selectedGames.length > 0) {
      for (const game of selectedGames) {
        if (game.gameType === "A" && game.seriesDescription === "All-Star Game") {
          // print both teams for blank lineup cards on All Star Game
          printBlankAllStarCards(game);
        } else {
          const isHomeTeam = selectedOrg.name === selectedGames[0].teams.home.team.name;
          let teamType = "home";
          if (!isHomeTeam) {
            teamType = "away";
          }
          printBlankUmpireCard(game, teamType);
        }
      }
    }
  };

  const onOrgSelection = value => {
    gamesDispatch({ type: "setSelectedOrg", selectedOrg: value });
    gamesDispatch({ type: "setOtherOrg", otherOrg: {} });
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
    gamesDispatch({ type: "setGames", games: [] });
    gamesDispatch({ type: "setSelectedGames", selectedGames: [] });
    gamesDispatch({ type: "setRosterLimits", rosterLimits: null });
  };

  const onLeagueSelection = value => {
    gamesDispatch({ type: "setSelectedLeague", selectedLeague: value });
    gamesDispatch({ type: "setSelectedOrg", selectedOrg: {} });
    gamesDispatch({ type: "setOtherOrg", otherOrg: {} });
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
    gamesDispatch({ type: "setGames", games: [] });
    gamesDispatch({ type: "setSelectedGames", selectedGames: [] });
    gamesDispatch({ type: "setRosterLimits", rosterLimits: null });
    LineupCardApi.getClubInfo(value.value).then(response => {
      authDispatch({ type: "setClubInfo", clubInfo: response });
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between" style={{ width: isAdmin ? "660px" : "550px" }}>
          <StyledOrgWrapper>
            <FormInput
              name="league-select"
              type="select"
              isRequired={false}
              label="League"
              editable={isSuperAdmin}
              value={selectedLeague}
              options={leagueOptions}
              onChangeFn={value => {
                onLeagueSelection(value);
              }}
            />
          </StyledOrgWrapper>
          <StyledOrgWrapper>
            <FormInput
              name="org-select"
              type="select"
              isRequired={false}
              label="Org"
              editable={isAdmin}
              value={selectedOrg}
              options={clubInfo}
              onChangeFn={value => {
                onOrgSelection(value);
              }}
            />
          </StyledOrgWrapper>
          <StyledDatePickerWrapper>
            <DateRangePickerWithLabel
              required={false}
              label="Date"
              value={dates}
              onChange={value => {
                gamesDispatch({
                  type: "setDateRange",
                  startDate: value.start,
                  endDate:
                    getUTCdate(value.start) > value.end ? addToDate(value.start, "weeks", 1).valueOf() : value.end
                });
              }}
            />
          </StyledDatePickerWrapper>
        </div>
      </div>
      <LoadingOverlay loading={loadingGames} top="45%" left="48%">
        <GamesTable loadingGames={loadingGames} setLoadingGames={setLoadingGames} />
      </LoadingOverlay>
      {isAllStarGame ? (
        <div className="d-flex">
          <div className="mt-2 mr-1" style={{ color: "red" }}>
            ⓘ
          </div>
          <DisclaimerText>
            The All Star Game is currently selected. No other game can be selected until the All Star game is unchecked.
          </DisclaimerText>
        </div>
      ) : null}
      <div className="d-flex">
        <div className="mt-2 mr-1" style={{ color: "red" }}>
          ⓘ
        </div>
        <DisclaimerText>
          Please be aware that the available players pulled into the Lineup App are not necessarily based on approved
          moves in eBIS. As always, it is up to each individual Club to ensure that the players added to its roster are
          in fact eligible. If you have any questions about a player’s eligibility, please contact the Baseball
          Operations Department.
        </DisclaimerText>
      </div>
      <div style={{ float: "right" }}>
        <div className="d-flex mt-3 ml-auto">
          <div className="mb-1">
            <DownloadButton
              onClick={generateBlankDugoutCards}
              disabled={generateDisabled}
              text={"Blank Dugout Card" + (selectedGames?.length > 1 ? "s" : "")}
              height={26}
              width={220}
            />
          </div>
          <DownloadButton
            onClick={generateBlankUmpireCards}
            disabled={generateDisabled}
            text={"Blank Official Lineup Card" + (selectedGames?.length > 1 ? "s" : "")}
            height={26}
            width={230}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectGameSection;
