import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { PrimaryOutlinedButtonStyled } from "../../elements/PrimaryOutlinedButton";
import styled from "styled-components";

const ConfirmMessage = styled.div.attrs({ className: "mb-3" })`
  font-size: 1.125rem;
  font-weight: normal;
  color: #77879a;
`;

const RosterErrorModal = ({ isOpen, messages, onClose }) => {
  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        onClose={() => {
          onClose(false);
        }}
      >
        <ModalHeaderWithTitleAndClose
          title="Roster/Lineup error"
          onClick={() => {
            onClose(false);
          }}
          close={() => {
            onClose(false);
          }}
        />
        <ModalBody>
          {messages ? messages.map((message, index) => <ConfirmMessage key={index}>{message}</ConfirmMessage>) : null}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <PrimaryOutlinedButtonStyled
              width={100}
              height={26}
              onClick={() => {
                onClose(false);
              }}
            >
              Dismiss
            </PrimaryOutlinedButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RosterErrorModal;
