import React from "react";
import styled from "styled-components";

const ValueStyle = styled.div.attrs(() => ({}))`
  color: ${props => (props.valid ? "green" : "red")};
  font-style: italic;
  font-weight: bold;
`;

const EbisCounts = ({ activeRosterCount, rosterCountValid, activePitcherCount, pitcherCountValid }) => (
  <>
    {activeRosterCount && activePitcherCount && (
      <div className="d-flex ml-3 mt-3">
        <div className="d-flex mr-3">
          <div className="mr-2">Active Roster Count:</div>
          <ValueStyle valid={rosterCountValid}>{activeRosterCount}</ValueStyle>
        </div>
        <div className="d-flex">
          <div className="mr-2">Active Pitcher Count:</div>
          <ValueStyle valid={pitcherCountValid}>{activePitcherCount}</ValueStyle>
        </div>
      </div>
    )}
  </>
);

export default EbisCounts;
