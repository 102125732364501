import React, { useEffect, useState } from "react";
import LineupTable from "./LineupTable";
import AvailablePlayersTable from "./AvailablePlayersTable";
import PitchersTable from "./PitchersTable";
import { useGames, useActiveOrgKey } from "../../../contexts/GamesContext";
import { useAuth } from "../../../contexts/AuthContext";
import SubSectionHeader from "../../elements/SubSectionHeader";
import ButtonRow from "../../elements/ButtonRow";
import {
  clearLineupFromTeamMap,
  fillInLineup,
  getBlankLineup,
  removeLineupPlayersAndPositions,
  saveLineup
} from "../../../utils/LineupUtils";
import DefaultLineupApi from "../../../httpClients/DefaultLineupApi";
import { useAlert } from "../../alerts/AlertsContext";
import LoadingOverlay from "../../loading/LoadingOverlay";
import DhCheckbox from "../../lineupSettings/DhCheckbox";
import SaveDefaultButtonWrapper from "../../lineupSettings/SaveDefaultButtonWrapper";
import SavingSpinner from "../../loading/SavingSpinner";

const LineupSection = ({ fullWidth }) => {
  const [lineup, setLineup] = useState([]);
  const [pitchers, setPitchers] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [saveLineupDisabled, setSaveLineupDisabled] = useState(true);
  const [showFlag, setShowFlag] = useState(false);
  const [showClubActions, setShowClubActions] = useState(true);
  const [autoFillRightDisabled, setAutofillRightDisabled] = useState(true);
  const [autoFillLeftDisabled, setAutofillLeftDisabled] = useState(true);
  const [autoFillLoading, setAutoFillLoading] = useState(false);
  const [rosterError, setRosterError] = useState(false);
  const [saveLineupState, setSaveLineupState] = useState(false);

  const showAlert = useAlert();

  const {
    state: {
      teamMap,
      clearLineups,
      viewableTeam,
      selectedGames,
      selectedOrg,
      otherOrg,
      selectedLineupDirty,
      otherLineupDirty,
      isSpringTrainingGame,
      selectedRosterHasError,
      otherRosterHasError
    },
    dispatch: gamesDispatch
  } = useGames();

  const orgKey = useActiveOrgKey();

  const {
    state: { club, isUser, email, isAflAdmin, isWbcAdmin, aflClub, wbcClub }
  } = useAuth();

  const submitLineup = showAlert => {
    saveLineup(gamesDispatch, orgKey, email, selectedGames, false, true, showAlert);
    setSaveLineupDisabled(true);
  };

  const autoFillLineup = lefty => {
    setAutoFillLoading(true);
    DefaultLineupApi.getDefaultLineup(viewableTeam?.value, lefty)
      .then(response => {
        if (response && response.lineup) {
          clearLineupFromTeamMap(teamMap, gamesDispatch, orgKey);
          const lineup = fillInLineup(response.lineup, teamMap.get(orgKey)?.hasDH);
          removeLineupPlayersAndPositions(lineup, teamMap, orgKey);
          teamMap.get(orgKey).lineup = lineup;
          gamesDispatch({
            type: "setTeamMap",
            teamMap: teamMap
          });
          // save lineup
          saveLineup(gamesDispatch, orgKey, email, selectedGames, true);
          setAutoFillLoading(false);
        }
      })
      .catch(() => {
        showAlert("Error Autofilling Lineup", "danger");
      });
  };

  const lineupButtonArray = [
    {
      name: "autoFillRight",
      content: "Autofill vs. Righty",
      height: 26,
      width: 153,
      onClick: () => {
        autoFillLineup(false);
      },
      disabled: autoFillRightDisabled || rosterError,
      visible: showClubActions && !isAflAdmin && !isWbcAdmin && !aflClub && !wbcClub
    },
    {
      name: "autoFillLeft",
      content: "Autofill vs. Lefty",
      height: 26,
      width: 143,
      onClick: () => {
        autoFillLineup(true);
      },
      disabled: autoFillLeftDisabled || rosterError,
      visible: showClubActions && !isAflAdmin && !isWbcAdmin && !aflClub && !wbcClub
    },
    // {
    //   name: "clearCards",
    //   content: "Clear Cards",
    //   height: 26,
    //   width: 113,
    //   onClick: clearCardsOnClick,
    //   disabled: false,
    //   visible: showClubActions
    // },
    {
      name: "submitLineup",
      content: "Submit Lineup",
      height: 26,
      width: 117,
      onClick: () => {
        submitLineup(showAlert);
      },
      disabled: saveLineupDisabled,
      style: "primary",
      visible: showClubActions
    }
  ];

  useEffect(() => {
    if (orgKey && teamMap.get(orgKey)) {
      // if a club is signed in they should not see the other teams lineup until it is submitted
      if (
        isUser &&
        orgKey === otherOrg.value &&
        (!teamMap.get(orgKey).lineupSubmitted || !teamMap.get(orgKey).validLineup)
      ) {
        clearLineupFromTeamMap(teamMap, gamesDispatch, orgKey);
      }

      const newLineup = [...teamMap.get(orgKey).lineup];
      // add set save state to row so that we can show user
      if (newLineup.length > 0) {
        newLineup.forEach(row => (row.setSaveState = setSaveLineupState));
      }
      setLineup(newLineup);
      setPitchers(teamMap.get(orgKey).pitchers);
      setAvailablePlayers(teamMap.get(orgKey).availablePlayers);
    } else {
      setLineup(getBlankLineup(selectedGames));
      setPitchers([]);
      setAvailablePlayers([]);
    }
  }, [viewableTeam, selectedOrg, otherOrg, clearLineups, teamMap]);

  useEffect(() => {
    if (teamMap && teamMap.get(selectedOrg?.value) && teamMap.get(otherOrg?.value)) {
      if (isSpringTrainingGame) {
        if (viewableTeam?.value === selectedOrg.value) {
          setSaveLineupDisabled(!selectedLineupDirty);
          setShowFlag(selectedLineupDirty);
        } else if (viewableTeam?.value === otherOrg.value) {
          setSaveLineupDisabled(!otherLineupDirty);
          setShowFlag(otherLineupDirty);
        }
      } else {
        if (viewableTeam?.value === selectedOrg.value) {
          setSaveLineupDisabled(!(teamMap.get(selectedOrg.value).validLineup && selectedLineupDirty));
          setShowFlag(teamMap.get(selectedOrg.value).validLineup && selectedLineupDirty);
        } else if (viewableTeam?.value === otherOrg.value) {
          setSaveLineupDisabled(!(teamMap.get(otherOrg.value).validLineup && otherLineupDirty));
          setShowFlag(teamMap.get(otherOrg.value).validLineup && otherLineupDirty);
        }
      }
    }
  }, [teamMap, selectedOrg, otherOrg, viewableTeam, selectedLineupDirty, otherLineupDirty]);

  useEffect(() => {
    if (isUser && viewableTeam.name && viewableTeam.name !== club) {
      setShowClubActions(false);
    } else if (isUser && viewableTeam.name && viewableTeam.name === club) {
      setShowClubActions(true);
    }
  }, [selectedOrg, otherOrg, viewableTeam, club, isUser]);

  useEffect(() => {
    if (viewableTeam?.value === selectedOrg?.value) {
      setRosterError(selectedRosterHasError);
    } else if (viewableTeam?.value === otherOrg?.value) {
      setRosterError(otherRosterHasError);
    }
  }, [selectedOrg, otherOrg, viewableTeam, selectedRosterHasError, otherRosterHasError]);

  useEffect(() => {
    if (viewableTeam && viewableTeam.value) {
      DefaultLineupApi.getDefaultLineup(viewableTeam.value, true).then(response => {
        if (response) {
          // Button is disabled when there is no default lineup saved
          setAutofillLeftDisabled(!response.lineup);
        }
      });
      DefaultLineupApi.getDefaultLineup(viewableTeam.value, false).then(response => {
        if (response) {
          // Button is disabled when there is no default lineup saved
          setAutofillRightDisabled(!response.lineup);
        }
      });
      return () => {
        setAutofillLeftDisabled(true);
        setAutofillRightDisabled(true);
      };
    }
  }, [selectedOrg, otherOrg, viewableTeam]);

  return (
    <div className={!fullWidth ? "col-7" : "col"}>
      <SubSectionHeader title="Lineup" />
      <div className="d-flex">
        <ButtonRow buttonArray={lineupButtonArray} />
        <div className="mt-3 col-5">
          {showFlag && (
            <div style={{ color: "red", fontSize: "12px" }}>
              *Unsubmitted lineup changes, Please submit to enable card generation
            </div>
          )}
        </div>
      </div>
      <div className="d-flex">
        <div className="mr-3" style={{ width: !fullWidth ? "50%" : "" }}>
          {/* only show spinner when autofill is loading*/}
          <LoadingOverlay loading={autoFillLoading || rosterError} top="55%" left="20%" showSpinner={autoFillLoading}>
            <LineupTable lineup={lineup} fullWidth={fullWidth} />
          </LoadingOverlay>
        </div>
        <div className="d-flex justify-content-between" style={{ width: !fullWidth ? "50%" : "" }}>
          <div className="mr-3" style={{ width: !fullWidth ? "50%" : "" }}>
            <LoadingOverlay loading={rosterError} showSpinner={false}>
              <AvailablePlayersTable availablePlayers={availablePlayers} />
            </LoadingOverlay>
          </div>
          <div style={{ width: "50%" }}>
            <LoadingOverlay loading={rosterError} showSpinner={false}>
              <PitchersTable pitchers={pitchers} />
            </LoadingOverlay>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        {!rosterError && <DhCheckbox />}
        <div className="ml-5">
          <SavingSpinner saving={saveLineupState} />
        </div>
      </div>

      {!rosterError && (
        <SaveDefaultButtonWrapper
          setAutofillLeftDisabled={setAutofillLeftDisabled}
          setAutofillRightDisabled={setAutofillRightDisabled}
        />
      )}
    </div>
  );
};

export default LineupSection;
