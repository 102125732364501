import React, { useContext } from "react";
import { FormInput } from "best-common-react";
import styled from "styled-components";
import { EditCustomCardContext } from "../contexts/EditCustomCardContext";

const TextStyle = styled.div.attrs(() => ({ className: "mt-2 ml-1 mr-2" }))`
  font-size: 0.875rem;
  width: 15px;
`;

const CardInfoBorderColors = () => {
  const editCustomCardContext = useContext(EditCustomCardContext);
  const { dispatch } = editCustomCardContext;
  const { customCardInfo } = editCustomCardContext.state;

  return (
    <>
      <div className="col-lg-3 col-md-6 col-sm-12">
        Outside Border Color
        <div className="d-flex">
          <TextStyle>C</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="c-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.outerColorC}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setOutsideBorderC",
                    outerColorC: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
        <div className="d-flex">
          <TextStyle>M</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="m-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.outerColorM}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setOutsideBorderM",
                    outerColorM: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
        <div className="d-flex">
          <TextStyle>Y</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="y-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.outerColorY}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setOutsideBorderY",
                    outerColorY: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
        <div className="d-flex">
          <TextStyle>K</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="k-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.outerColorK}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setOutsideBorderK",
                    outerColorK: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        Inside Border Color
        <div className="d-flex">
          <TextStyle>C</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="c-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.innerColorC}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setInsideBorderC",
                    innerColorC: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
        <div className="d-flex">
          <TextStyle>M</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="m-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.innerColorM}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setInsideBorderM",
                    innerColorM: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
        <div className="d-flex">
          <TextStyle>Y</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="y-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.innerColorY}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setInsideBorderY",
                    innerColorY: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
        <div className="d-flex">
          <TextStyle>K</TextStyle>
          <div style={{ width: "46px" }}>
            <FormInput
              name="k-border-color-text-input"
              type="text"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={customCardInfo.innerColorK}
              onChangeFn={value => {
                if (!isNaN(value)) {
                  dispatch({
                    type: "setInsideBorderK",
                    innerColorK: value
                  });
                }
              }}
            />
          </div>
          <TextStyle>%</TextStyle>
        </div>
      </div>
    </>
  );
};

export default CardInfoBorderColors;
