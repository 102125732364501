import React from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import PropTypes from "prop-types";

const HttpInterceptor = ({ children }) => {
  const { dispatch: authDispatch } = useAuth();

  axios.interceptors.request.use(config => {
    const { accessToken = {}, idToken = {} } = JSON.parse(localStorage.getItem("okta-token-storage"));

    if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
      config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
      config.headers["x-id-token"] = idToken.idToken;
    }
    return config;
  });

  axios.interceptors.response.use(
    resp => {
      if (!resp) {
        return null;
      }
      return resp;
    },
    err => {
      if (!!err.response && (err.response.status === 401 || err.response.status === 403)) {
        authDispatch({ type: "logout" });
      }
      return Promise.reject(err);
    }
  );

  return <div>{children}</div>;
};

HttpInterceptor.propTypes = {
  children: PropTypes.object
};

export default HttpInterceptor;
