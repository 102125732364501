import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVICES_URL + "/lineup";

class LineupApi {
  static getLineup = (gamePk, orgId) => {
    const url = `${BASE_URL}/?gamePk=${gamePk}&orgId=${orgId}`;
    return axios.get(url).then(response => response.data);
  };

  static saveLineup = lineup => {
    const url = `${BASE_URL}/save`;
    return axios.post(url, lineup).then(response => response.data);
  };
}

export default LineupApi;
