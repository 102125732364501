import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useActiveOrgKey, useGames, useInactiveOrgKey } from "../../contexts/GamesContext";
import { useColorOptions, useSettings } from "../../contexts/SettingsContext";
import FileHelper from "../../helper/FileHelper";
import { formatDate } from "../../utils/DateUtils";
import UmpireCardApi from "../../httpClients/UmpireCardApi";
import Moment from "moment";
import { useAlert } from "../alerts/AlertsContext";
import DownloadButton from "../elements/DownloadButton";
import { useAuth } from "../../contexts/AuthContext";
import { useEbisSectionVisible } from "../../customhooks/useEbisSectionVisible";
import DugoutCardApi from "../../httpClients/DugoutCardApi";
import EbisApi from "../../httpClients/EbisApi";
import LineupDifferencesModal from "./modal/LineupDifferencesModal";
import { adjustLineupPosOptions, getBlankLineup, isLineupValid } from "../../utils/LineupUtils";
import { trackPromise } from "react-promise-tracker";

const FooterStyle = styled.div.attrs(() => ({
  className: "mb-3 mt-5"
}))`
  width: ${props => (props.width ? props.width : "20rem")};
  float: ${props => props.float};
`;

const ButtonWrapper = styled.div.attrs(() => ({
  className: "d-flex justify-content-between align-items-center"
}))``;

//TODO: This needs to be refactored. Remove use of context and move functions up
const GamesFooter = () => {
  const {
    state: {
      selectedOrg,
      otherOrg,
      selectedGames,
      teamMap,
      gumboTimecode,
      selectedTeamMostRecentGumboRoster,
      selectedLineupDirty,
      otherLineupDirty,
      selectedLineupSubmitted,
      otherLineupSubmitted,
      isSpringTrainingGame,
      selectedRosterHasError,
      otherRosterHasError,
      selectedLeague,
      isAllStarGame
    },
    dispatch: gameDispatch
  } = useGames();

  const {
    state: { email }
  } = useAuth();

  const showAlert = useAlert();
  const activeOrgKey = useActiveOrgKey();
  const inactiveOrgKey = useInactiveOrgKey();
  const ebisSectionVisible = useEbisSectionVisible();

  const selectedTeam = teamMap && teamMap.get(activeOrgKey) ? teamMap.get(activeOrgKey) : false;
  const otherTeam = teamMap && teamMap.get(inactiveOrgKey) ? teamMap.get(inactiveOrgKey) : false;
  const {
    state: { logoTransparency, fullWidthImage, lastNameOnly }
  } = useSettings();

  const colorOptions = useColorOptions();

  const {
    state: { isUser, isAdmin, isSuperAdmin, club, clubInfo, isAflAdmin, isWbcAdmin, aflClub, wbcClub }
  } = useAuth();

  const [generateDugoutDisabled, setGenerateDugoutDisabled] = useState(true);
  const [generateHomeTeamUmpireDisabled, setGenerateHomeTeamUmpireDisabled] = useState(true);
  const [generateAwayTeamUmpireDisabled, setGenerateAwayTeamUmpireDisabled] = useState(true);
  const [differencesModalState, setDifferencesModalState] = useState(false);

  useEffect(() => {
    if (selectedGames[0]) {
      const homeTeam = selectedGames[0].teams.home.team;
      // selected is the org selected in dropdown, does not matter home or away
      const selectedSubmittedAndNotDirty = selectedLineupSubmitted && !selectedLineupDirty && !selectedRosterHasError;
      // other is the other org in the gam, does not matter home or away
      const otherSubmittedAndNotDirty = otherLineupSubmitted && !otherLineupDirty && !otherRosterHasError;

      if (isUser) {
        // if you are signed in as a club then you are always the selectedLineup
        setGenerateDugoutDisabled(!selectedSubmittedAndNotDirty);
        if (homeTeam.name === selectedOrg.name) {
          setGenerateHomeTeamUmpireDisabled(!selectedSubmittedAndNotDirty);
        } else {
          setGenerateAwayTeamUmpireDisabled(!selectedSubmittedAndNotDirty);
        }
      } else if (isAdmin) {
        setGenerateDugoutDisabled(!selectedSubmittedAndNotDirty && !otherSubmittedAndNotDirty);
        // if the selected org is the home team use selected values for home team
        if (homeTeam.name === selectedOrg.name) {
          setGenerateHomeTeamUmpireDisabled(!selectedSubmittedAndNotDirty);
          setGenerateAwayTeamUmpireDisabled(!otherSubmittedAndNotDirty);
        } else {
          // otherwise use use other values for home team
          setGenerateHomeTeamUmpireDisabled(!otherSubmittedAndNotDirty);
          setGenerateAwayTeamUmpireDisabled(!selectedSubmittedAndNotDirty);
        }
      }
    }
    return () => {
      setGenerateAwayTeamUmpireDisabled(true);
      setGenerateHomeTeamUmpireDisabled(true);
      setGenerateDugoutDisabled(true);
    };
  }, [
    selectedGames,
    selectedLineupSubmitted,
    selectedLineupDirty,
    otherLineupSubmitted,
    otherLineupDirty,
    otherRosterHasError,
    selectedRosterHasError
  ]);

  const generateDugoutCard = () => {
    const dugoutCardDTO = {};
    if (teamMap.get(selectedOrg.value) && selectedLineupSubmitted) {
      dugoutCardDTO[selectedOrg.value] = getTeamInfo(
        teamMap.get(selectedOrg.value).lineup,
        teamMap.get(selectedOrg.value).availablePlayers,
        teamMap.get(selectedOrg.value).pitchers
      );
    } else {
      dugoutCardDTO[selectedOrg.value] = {};
    }

    if (teamMap.get(otherOrg.value) && otherLineupSubmitted) {
      dugoutCardDTO[otherOrg.value] = getTeamInfo(
        teamMap.get(otherOrg.value).lineup,
        teamMap.get(otherOrg.value).availablePlayers,
        teamMap.get(otherOrg.value).pitchers
      );
    } else {
      dugoutCardDTO[otherOrg.value] = {};
    }

    if (selectedGames[0].doubleHeader === "Y") {
      dugoutCardDTO[selectedOrg.value].doubleHeaderGame = selectedGames[0].gameNumber;
      dugoutCardDTO[otherOrg.value].doubleHeaderGame = selectedGames[0].gameNumber;
    }

    dugoutCardDTO[selectedOrg.value].gamePk = selectedGames[0].gamePk;
    dugoutCardDTO[otherOrg.value].gamePk = selectedGames[0].gamePk;
    dugoutCardDTO[selectedOrg.value].sportId = selectedLeague.sportId;
    dugoutCardDTO[otherOrg.value].sportId = selectedLeague.sportId;

    trackPromise(
      DugoutCardApi.getDugoutCard(
        dugoutCardDTO,
        formatDate(selectedGames[0].gameDate),
        null,
        selectedOrg.value,
        selectedGames[0].gameType
      )
        .then(response => {
          FileHelper.downloadFile(response, "pdf");
        })
        .catch(() => {
          showAlert("Error printing dugout card. Please try again.", "danger");
        })
    );
  };

  const generateUmpireCardBySelectedOrg = useCallback(() => {
    const isHomeTeam = selectedOrg.name === selectedGames[0].teams.home.team.name;
    let umpireCardInfo;
    let teamType = "home";
    if (!isHomeTeam) {
      teamType = "away";
    }

    umpireCardInfo = getUmpireCardInfo(
      selectedGames[0],
      teamType,
      selectedTeam.lineup,
      selectedTeam.availablePlayers,
      selectedTeam.pitchers,
      selectedTeamMostRecentGumboRoster,
      selectedOrg.value,
      true
    );

    umpireCardInfo.homeTeamId = selectedGames[0].teams.home.team.id;
    umpireCardInfo.sportId = selectedLeague.sportId;
    umpireCardInfo.allStarGame = isAllStarGame;

    trackPromise(
      UmpireCardApi.generateOfficialUmpireCard(umpireCardInfo)
        .then(response => {
          FileHelper.downloadFile(response, "pdf");
        })
        .catch(() => {
          showAlert("Error printing official lineup card. Please autofill again and try again.", "danger");
        })
    );
  }, []);

  const generateHomeAwayUmpireCard = useCallback(
    homeTeam => {
      const team = homeTeam ? selectedGames[0].teams.home.team : selectedGames[0].teams.away.team;
      const teamType = homeTeam ? "home" : "away";
      let teamInfo;
      let isSelectedTeam;
      if (team.name === selectedTeam.orgName) {
        teamInfo = selectedTeam;
      } else {
        teamInfo = otherTeam;
      }

      if (!teamMap.get(parseInt(team.id))?.ebisGame) {
        let officialUmpireCardInfo;

        if (isAdmin) {
          isSelectedTeam = true;
        } else {
          const currentClubInfoName = clubInfo.find(c => c.name === club).name;
          const teamInfoOrgName = teamInfo.orgName;
          isSelectedTeam = currentClubInfoName === teamInfoOrgName;
        }

        officialUmpireCardInfo = getUmpireCardInfo(
          selectedGames[0],
          teamType,
          teamInfo.lineup,
          teamInfo.availablePlayers,
          teamInfo.pitchers,
          team.id,
          isSelectedTeam
        );

        officialUmpireCardInfo.homeTeamId = selectedGames[0].teams.home.team.id;
        officialUmpireCardInfo.roster = teamInfo.roster;
        officialUmpireCardInfo.gumboTimecode = teamInfo.gumboTimecode;
        officialUmpireCardInfo.sportId = selectedLeague.sportId;
        officialUmpireCardInfo.allStarGame = isAllStarGame;
        trackPromise(
          UmpireCardApi.generateOfficialUmpireCard(officialUmpireCardInfo)
            .then(response => {
              FileHelper.downloadFile(response, "pdf");
            })
            .catch(() => {
              showAlert("Error printing official lineup card. Please autofill again and try again.", "danger");
            })
        );
      } else {
        trackPromise(
          EbisApi.checkLineup(selectedGames[0].gamePk, team.id, selectedLeague.value)
            .then(response => {
              if (response) {
                if (response.hasChanges) {
                  setDifferencesModalState(true);
                } else {
                  let officialUmpireCardInfo;

                  if (isAdmin) {
                    isSelectedTeam = true;
                  } else {
                    const currentClubInfoName = clubInfo.find(c => c.name === club).name;
                    const teamInfoOrgName = teamInfo.orgName;
                    isSelectedTeam = currentClubInfoName === teamInfoOrgName;
                  }

                  officialUmpireCardInfo = getUmpireCardInfo(
                    selectedGames[0],
                    teamType,
                    teamInfo.lineup,
                    teamInfo.availablePlayers,
                    teamInfo.pitchers,
                    team.id,
                    isSelectedTeam
                  );

                  officialUmpireCardInfo.homeTeamId = selectedGames[0].teams.home.team.id;
                  officialUmpireCardInfo.roster = teamInfo.roster;
                  officialUmpireCardInfo.gumboTimecode = teamInfo.gumboTimecode;
                  officialUmpireCardInfo.sportId = selectedLeague.sportId;
                  officialUmpireCardInfo.allStarGame = isAllStarGame;
                  trackPromise(
                    UmpireCardApi.generateOfficialUmpireCard(officialUmpireCardInfo)
                      .then(response => {
                        FileHelper.downloadFile(response, "pdf");
                      })
                      .catch(() => {
                        showAlert(
                          "Error printing official lineup card. Please autofill again and try again.",
                          "danger"
                        );
                      })
                  );
                }
              }
            })
            .catch(() => {
              showAlert("Error checking lineup against EBIS roster.", "danger");
            })
        );
      }
    },
    [selectedGames, selectedTeam, otherTeam, teamMap]
  );

  const getTeamInfo = (lineup, available, pitchers) => {
    let cardInfo = {};
    cardInfo.batting = lineup;
    cardInfo.available = available;
    cardInfo.pitchers = pitchers;
    cardInfo.positions = lineup.map(player => player.position);
    cardInfo.colorOptions = colorOptions;
    cardInfo.imageOpacity = logoTransparency;
    cardInfo.fullWidthImage = fullWidthImage; //commenting this out for 2023 ASG - JL isAllStarGame ? true : fullWidthImage;
    cardInfo.lastNameOnly = lastNameOnly;
    cardInfo.hasDH = lineup.length === 10;
    return cardInfo;
  };

  const getUmpireCardInfo = (game, teamType, lineup, available, pitchers, clubId, selectedTeam) => {
    const umpireCardInfo = {};
    umpireCardInfo.clubId = clubId;
    umpireCardInfo.team = game.teams[teamType].team.name;
    umpireCardInfo.gameDate = Moment(game.gameDate).toDate();
    umpireCardInfo.lineup = lineup;
    umpireCardInfo.availablePlayers = available;
    umpireCardInfo.pitchers = pitchers;
    umpireCardInfo.colorOptions = colorOptions;
    umpireCardInfo.gamePk = selectedGames[0].gamePk;
    umpireCardInfo.gumboTimecode = gumboTimecode;
    umpireCardInfo.startingPitcher = lineup.find(p => p.position === "1");
    umpireCardInfo.blankCard = false;
    umpireCardInfo.hasDH = lineup.length === 10;
    umpireCardInfo.springTraining = isSpringTrainingGame;
    if (isAdmin) {
      umpireCardInfo.selectedTeam = selectedTeam;
    } else {
      umpireCardInfo.selectedTeam = clubInfo?.find(c => c.name === club)?.value === parseInt(clubId);
    }

    if (game.doubleHeader === "Y") {
      umpireCardInfo.doubleHeaderGame = game.gameNumber;
    }
    return umpireCardInfo;
  };

  return (
    <FooterStyle
      float={ebisSectionVisible ? "right" : "left"}
      width={selectedGames.length > 0 ? (isAdmin ? "35rem" : "25rem") : null}
    >
      <ButtonWrapper>
        <LineupDifferencesModal isOpen={differencesModalState} onClose={setDifferencesModalState} />
        <DownloadButton
          onClick={generateDugoutCard}
          disabled={generateDugoutDisabled}
          text="Dugout Card"
          height={26}
          width={130}
          primary
        />
        <>
          {selectedGames.length > 0 ? (
            <div className="d-flex">
              {(isAdmin || selectedGames[0].teams.away.team.name === club) && (
                <DownloadButton
                  onClick={() => {
                    generateHomeAwayUmpireCard(false);
                  }}
                  disabled={generateAwayTeamUmpireDisabled}
                  text={selectedGames[0].teams.away.team.abbreviation + " Official Lineup Card"}
                  height={26}
                  width={210}
                  primary
                />
              )}
              {(isAdmin || selectedGames[0].teams.home.team.name === club) && (
                <DownloadButton
                  onClick={() => {
                    generateHomeAwayUmpireCard(true);
                  }}
                  disabled={generateHomeTeamUmpireDisabled}
                  text={selectedGames[0].teams.home.team.abbreviation + " Official Lineup Card"}
                  height={26}
                  width={210}
                  primary
                />
              )}
            </div>
          ) : (
            <DownloadButton
              onClick={generateUmpireCardBySelectedOrg}
              disabled={generateDugoutDisabled}
              text="Official Lineup Card"
              height={26}
              width={180}
              primary
            />
          )}
        </>
      </ButtonWrapper>
    </FooterStyle>
  );
};

export default GamesFooter;
