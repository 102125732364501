import React, { createContext, useReducer } from "react";

const EditCustomCardContext = createContext({});

const initialState = {
  edited: false,
  customCardInfo: {
    outerColorC: "",
    outerColorM: "",
    outerColorY: "",
    outerColorK: "",
    innerColorC: "",
    innerColorM: "",
    innerColorY: "",
    innerColorK: ""
  }
};

const EditCustomCardContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setCustomCardInfo":
        return { ...state, customCardInfo: action.customCardInfo };
      case "setTitle":
        return { ...state, edited: true, customCardInfo: { ...state.customCardInfo, title: action.title } };
      case "setVenue":
        return { ...state, edited: true, customCardInfo: { ...state.customCardInfo, venue: action.venue } };
      case "setCity":
        return { ...state, edited: true, customCardInfo: { ...state.customCardInfo, city: action.city } };
      case "setState":
        return { ...state, edited: true, customCardInfo: { ...state.customCardInfo, state: action.state } };
      case "setHomeTeam":
        return { ...state, edited: true, customCardInfo: { ...state.customCardInfo, homeTeamName: action.homeTeam } };
      case "setAwayTeam":
        return { ...state, edited: true, customCardInfo: { ...state.customCardInfo, awayTeamName: action.awayTeam } };
      case "setOutsideBorderC":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            outerColorC: action.outerColorC
          }
        };
      case "setOutsideBorderM":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            outerColorM: action.outerColorM
          }
        };
      case "setOutsideBorderY":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            outerColorY: action.outerColorY
          }
        };
      case "setOutsideBorderK":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            outerColorK: action.outerColorK
          }
        };
      case "setInsideBorderC":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            innerColorC: action.innerColorC
          }
        };
      case "setInsideBorderM":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            innerColorM: action.innerColorM
          }
        };
      case "setInsideBorderY":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            innerColorY: action.innerColorY
          }
        };
      case "setInsideBorderK":
        return {
          ...state,
          edited: true,
          customCardInfo: {
            ...state.customCardInfo,
            innerColorK: action.innerColorK
          }
        };
      case "setGameDate":
        return {
          ...state,
          edited: true,
          customCardInfo: { ...state.customCardInfo, showGameDateText: action.checked }
        };
      case "setGameTime":
        return {
          ...state,
          edited: true,
          customCardInfo: { ...state.customCardInfo, showGameTimeText: action.checked }
        };
      case "setImageOpacity":
        return {
          ...state,
          edited: true,
          customCardInfo: { ...state.customCardInfo, imageOpacity: action.imageOpacity }
        };
      case "setHeaderLogo":
        return {
          ...state,
          edited: action.edited,
          customCardInfo: { ...state.customCardInfo, headerLogo: action.headerLogo }
        };
      case "setPrimaryLogo":
        return {
          ...state,
          edited: action.edited,
          customCardInfo: { ...state.customCardInfo, primaryLogo: action.primaryLogo }
        };
      case "setHomeTeamLogo":
        return {
          ...state,
          edited: action.edited,
          customCardInfo: { ...state.customCardInfo, homeTeamLogo: action.homeTeamLogo }
        };
      case "setAwayTeamLogo":
        return {
          ...state,
          edited: action.edited,
          customCardInfo: { ...state.customCardInfo, awayTeamLogo: action.awayTeamLogo }
        };
      case "setLayout":
        return {
          ...state,
          edited: true,
          customCardInfo: { ...state.customCardInfo, layout: action.layout }
        };
      case "setEdited":
        return { ...state, edited: action.edited };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <EditCustomCardContext.Provider value={{ state, dispatch }}>{children}</EditCustomCardContext.Provider>;
};

export { EditCustomCardContext, EditCustomCardContextProvider };
