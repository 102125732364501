import React from "react";
import styled from "styled-components";
import { DataTable } from "best-common-react";
import { LineupTableStyled } from "../../elements/LineupTableStyled";

const Text = styled.div.attrs(() => ({}))`
  font-size: 14px;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
`;

const PlayerNameFormatter = ({ row }) => {
  return (
    <Text>
      {row.lastName}, {row.preferredName}
    </Text>
  );
};

const PositionFormatter = ({ row }) => {
  return <Text bold={row?.twp}>{row?.twp ? "TWP" : row?.primaryPosition}</Text>;
};

const RosterStatusFormatter = ({ row }) => {
  return <Text>{row.rosterStatus}</Text>;
};

const Columns = [
  {
    key: "",
    name: "",
    width: 35
  },
  {
    key: "",
    name: "Player",
    formatter: PlayerNameFormatter
  },
  {
    key: "primaryPosition",
    name: "Pos",
    formatter: PositionFormatter
  },
  {
    key: "rosterStatus",
    name: "Roster Status",
    formatter: RosterStatusFormatter
  }
];

const EbisTable = ({ roster = [] }) => {
  return (
    <LineupTableStyled>
      <DataTable columns={Columns} data={roster} height={440} rowHeight={40} />
    </LineupTableStyled>
  );
};

export default EbisTable;
