class FileHelper {
  static downloadFile(response, fileExt) {
    let blob = new Blob([response.data], { type: response.headers["content-type"] });
    let nameRegex = new RegExp("filename[^;=\\n]*=(?:(\\\\?['\"])(.*?)\\1|(?:[^\\s]+'.*?')?([^;\\n]*))");
    let matches = nameRegex.exec(response.headers["content-disposition"]);
    let fileName = `download.${fileExt}`;
    if (matches.length > 0) {
      let temp = matches[0].substring(10);
      fileName = temp.substring(0, temp.length - 1);
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export default FileHelper;
