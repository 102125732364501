import React from "react";
import { NavigationFromConfig } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { withRouter } from "react-router-dom";

const ENV = process.env.REACT_APP_ENV;

const LineupCardNavigation = withRouter(({ history }) => {
  const {
    state: { isAdmin, isSuperAdmin, isAflAdmin, isWbcAdmin, loggedIn, email, isUser },
    dispatch: authDispatch
  } = useAuth();

  const NavConfig = {
    env: ENV,
    displayEnvIndicator: ENV !== "prod",
    loggedIn: loggedIn,
    username: email,
    logo: null,
    logoText: "Lineup Cards",
    title: "",
    tabletSupport: false,
    vertical: false,
    usernameDropdownLinks: [
      {
        name: "Sign Out",
        to: "",
        onClick: () => {
          authDispatch({ type: "logout" });
        }
      }
    ],
    navigationLinks: [
      {
        name: "Games",
        to: RouteConstants.GAMES
      },
      {
        name: "Default Lineups",
        to: RouteConstants.DEFAULT_LINEUPS,
        visible: !isAflAdmin && !isWbcAdmin
      },
      {
        name: "Logo Manager",
        to: RouteConstants.LOGO,
        visible: isSuperAdmin
      },
      {
        name: "Custom Cards",
        to: RouteConstants.CUSTOM_CARDS,
        visible: isAdmin
      },
      {
        name: "Roster Settings",
        to: RouteConstants.ROSTER_SETTINGS,
        visible: isSuperAdmin
      }
    ]
  };

  return (
    <div id="lineup-card-navigation">
      <NavigationFromConfig config={NavConfig} />
    </div>
  );
});

export default LineupCardNavigation;
