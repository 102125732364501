import React, { useContext } from "react";
import { BestRadio } from "best-common-react";
import { EditCustomCardContext } from "../contexts/EditCustomCardContext";
import styled from "styled-components";
import defaultLayoutImg from "../../../assets/images/top-center@3x.png";
import secondaryLayoutImg from "../../../assets/images/bottom-center@3x.png";

const Logo = styled.img.attrs(() => ({
  alt: "Logo To Upload",
  className: "img-fluid mx-auto d-block"
}))`
  max-height: 14rem;
`;

const LogoLayoutComponent = () => {
  const editCustomCardContext = useContext(EditCustomCardContext);
  const { dispatch } = editCustomCardContext;
  const { customCardInfo } = editCustomCardContext.state;

  const setLayout = value => {
    dispatch({
      type: "setLayout",
      layout: parseInt(value)
    });
  };

  return (
    <div className="mr-5" style={{ width: "300px" }}>
      <div>Layout</div>
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <BestRadio
            value={0}
            onChange={event => setLayout(event.target.value)}
            checked={customCardInfo.layout === 0}
          />{" "}
          <div>
            <div className="col-lg-12 col-md-12 col-sm-6">
              <Logo src={defaultLayoutImg} />
            </div>
          </div>
        </div>
        <div className="d-flex mr-3 align-items-center">
          <BestRadio
            value={1}
            onChange={event => setLayout(event.target.value)}
            checked={customCardInfo.layout === 1}
          />{" "}
          <div>
            <div className="col-lg-12 col-md-12 col-sm-6">
              <Logo src={secondaryLayoutImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoLayoutComponent;
