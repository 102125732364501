import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  BestLabel,
  BestRadio,
  BrowseFiles,
  Checkbox,
  DatePickerInput,
  DateRangePickerInput,
  Input,
  LightButton,
  PrimaryButton,
  BestSelect
} from "best-common-react";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { handleDateRangeOnChange } from "../../../utils/DateUtils";

const CheckboxHolder = styled.div`
  width: 8rem;
  height: 3.5rem;
`;

const ButtonHolder = styled.div.attrs(() => ({
  className: "d-flex justify-content-center mr-4"
}))`
  width: 22.5rem;
  height: 100%;
`;

const Logo = styled.img.attrs(() => ({
  alt: "Logo To Upload",
  className: "img-fluid mx-auto d-block"
}))`
  max-height: 14rem;
`;

const GameOptions = [
  {
    label: "Regular",
    value: "R"
  },
  {
    label: "AL Wild Card",
    value: "ALWC"
  },
  {
    label: "NL Wild Card",
    value: "NLWC"
  },
  {
    label: "AL Division Series",
    value: "ALDS"
  },
  {
    label: "NL Division Series",
    value: "NLDS"
  },
  {
    label: "AL Championship Series",
    value: "ALCS"
  },
  {
    label: "NL Championship Series",
    value: "NLCS"
  },
  {
    label: "World Series",
    value: "W"
  }
];

const TopAndBottomLogoForm = ({ setLogoFn, type }) => {
  const [dateType, setDateType] = useState(1);
  const [date, setDate] = useState(new Date());
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState({});
  const [fileContent, setFileContent] = useState({});
  const [gameType, setGameType] = useState("R");
  const [allClubsSelected, setAllClubsSelected] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [clubInfo, setClubInfo] = useState([]);

  useEffect(() => {
    LineupCardApi.getClubInfo("MLB")
      .then(response => {
        setClubInfo(response);
      })
      .catch(() => {
        // Error
      });
  }, []);

  useEffect(() => {
    setLogoFn({
      date: date,
      dateRange: dateRange,
      fileName: fileName,
      fileContent: fileContent,
      selectedClubs: selectedClubs,
      gameType: gameType,
      dateType: dateType,
      type: type
    });
  }, [date, dateRange, dateType, fileName, fileContent, gameType, selectedClubs]);

  const handleAllClubsSelected = value => {
    if (value) {
      const clubs = [];
      clubInfo.forEach(club => {
        if (selectedClubs.indexOf(club) === -1) {
          clubs.push(club);
        }
      });
      setSelectedClubs(clubs);
    } else {
      clubInfo.forEach(club => {
        if (selectedClubs.indexOf(club) !== -1) {
          selectedClubs.splice(selectedClubs.indexOf(club), 1);
        }
        setSelectedClubs(selectedClubs);
      });
    }
    setAllClubsSelected(value);
  };

  const handleFileAdd = fileToUpload => {
    if (fileToUpload.length) {
      const file = fileToUpload[0];
      setFile(fileToUpload[0]);
      const reader = new FileReader();
      reader.onload = () => {
        setFileContent(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateCheckedClub = index => {
    const club = clubInfo[index];
    if (selectedClubs.indexOf(club) === -1) {
      setSelectedClubs([...selectedClubs, club]);
    } else {
      selectedClubs.splice(selectedClubs.indexOf(club), 1);
      setSelectedClubs([...selectedClubs]);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <div>
                <BestLabel>Game Date</BestLabel>
                <div className="d-flex align-items-center">
                  <BestRadio
                    onChange={event => setDateType(parseInt(event.target.value))}
                    value={1}
                    checked={dateType === 1}
                    id="date-type-single"
                  />
                  <BestLabel htmlFor="date-type-single">Single</BestLabel>
                  <BestRadio
                    className="ml-2"
                    onChange={event => setDateType(parseInt(event.target.value))}
                    value={2}
                    checked={dateType === 2}
                    id="date-type-multiple"
                  />
                  <BestLabel htmlFor="date-type-multiple">Multiple</BestLabel>
                  <div className="ml-4">
                    {dateType === 1 ? (
                      <DatePickerInput value={date} onChange={setDate} />
                    ) : (
                      <DateRangePickerInput
                        value={dateRange}
                        onChange={value => handleDateRangeOnChange(value, setDateRange)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-3">
                <BestLabel htmlFor="name">File Name</BestLabel>
                <Input
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={fileName}
                  onChange={event => setFileName(event.target.value)}
                />
              </div>
              <div className="ml-3">
                <BestLabel htmlFor="gameType">Game Type</BestLabel>
                <div style={{ width: "250px" }}>
                  <BestSelect
                    value={GameOptions.find(opt => opt.value === gameType)}
                    options={GameOptions}
                    onChange={value => {
                      setGameType(value.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <BestLabel>File to Upload</BestLabel>
          <BrowseFiles
            editable={true}
            onFileAdd={file => {
              handleFileAdd(file);
            }}
            onFileDelete={() => {
              handleFileAdd([]);
            }}
            fileTypes={["image/png", "image/jpg", "image/jpeg"]}
          />
        </div>
      </div>

      {file.type && fileContent.length ? (
        <div className="row mt-2">
          <div className="col-12">
            <Logo src={fileContent} />
          </div>
        </div>
      ) : null}
      {gameType === "R" ? (
        <div className="row mt-3">
          <div className="col-12">
            <BestLabel>Clubs</BestLabel>
            <div className="d-flex align-items-center">
              <ButtonHolder>
                {allClubsSelected ? (
                  <LightButton onClick={() => handleAllClubsSelected(false)}>Remove All</LightButton>
                ) : (
                  <PrimaryButton onClick={() => handleAllClubsSelected(true)}>Select All</PrimaryButton>
                )}
              </ButtonHolder>
              <div>
                <div className="d-flex flex-wrap">
                  {clubInfo.map((club, index) => (
                    <CheckboxHolder key={club.id}>
                      <Checkbox
                        checked={selectedClubs.indexOf(club) !== -1}
                        onChange={() => {
                          updateCheckedClub(index);
                        }}
                        label={club.abbr}
                        id={club.id.toString()}
                      />
                    </CheckboxHolder>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TopAndBottomLogoForm;
