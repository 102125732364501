import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

const LoadingContext = React.createContext({});

const LoadingContextProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  return <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>;
};

const useLoading = () => {
  const loadingContext = useContext(LoadingContext);
  if (!loadingContext) {
    throw new Error("useLoading must be used within a LoadingContextProvider");
  }
  return loadingContext;
};

LoadingContextProvider.propTypes = {
  children: PropTypes.object
};

export { LoadingContext, LoadingContextProvider, useLoading };
