import React from "react";
import { useAuth } from "./contexts/AuthContext";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";

const LineupCardsProtectedContents = () => {
  const { state } = useAuth();

  return state.loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default LineupCardsProtectedContents;
