import React, { useReducer, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import jwt from "jsonwebtoken";
import { useOktaAuth } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaConfig from "../config/OktaConfig";
import LineupCardApi from "../httpClients/LineupCardApi";

const oktaAuth = new OktaAuth(OktaConfig.oidc);
const AuthContext = React.createContext({});

const initialState = {
  loggedIn: false,
  email: "",
  accessToken: "",
  idToken: {},
  groups: [],
  loginError: false,
  mobileNavOpen: false,
  refreshToken: "",
  userInfo: {},
  clubInfo: {},
  isUser: false,
  isAdmin: false,
  isSuperAdmin: false,
  isAflAdmin: false,
  isWbcAdmin: false,
  club: {},
  aflClub: false,
  wbcClub: false
};

const AuthContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "login":
        const { accessToken, idToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
        const emailAddress = idToken.claims.email;
        const groups = jwt.decode(accessToken.accessToken).groups;
        if (groups && groups.length > 0) {
          if (!groups.includes("Lineupcard-Users") && !groups.includes("Lineupcard-Admin")) {
            return {
              ...state,
              authToken: accessToken.accessToken,
              email: emailAddress,
              loggedIn: false,
              userInfo: {},
              groups: []
            };
          } else {
            if (groups.includes("Lineupcard-Admin")) {
              return {
                ...state,
                authToken: accessToken.accessToken,
                email: emailAddress,
                loggedIn: true,
                userInfo: {},
                isSuperAdmin: true,
                isAdmin: true,
                groups: groups
              };
            } else if (groups.includes("Lineupcard-Users")) {
              return {
                ...state,
                authToken: accessToken.accessToken,
                email: emailAddress,
                loggedIn: true,
                userInfo: {},
                isAdmin: false,
                isUser: true,
                groups: groups
              };
            }
          }
        }
        return {
          ...state,
          authToken: accessToken.accessToken,
          email: emailAddress,
          loggedIn: false,
          userInfo: {},
          isAdmin: false,
          isUser: false,
          groups: groups
        };
      case "logout":
        localStorage.clear();
        return {
          loggedIn: false,
          email: "",
          authToken: "",
          refreshToken: "",
          userData: {}
        };
      case "setGroups":
        return { ...state, groups: action.groups };
      case "setUserInfo":
        localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
        if (action.userInfo.admin) {
          return { ...state, userInfo: action.userInfo, isSuperAdmin: true, isAdmin: true };
        } else if (action.userInfo.aflAdmin) {
          LineupCardApi.getClubInfo("AFL").then(response => {
            const clubInfoArr = response
              .map(club => {
                return { label: club.abbr, value: club.id, name: club.name };
              })
              .sort((clubA, clubB) => clubA.label.localeCompare(clubB.label));
            localStorage.setItem("clubInfo", JSON.stringify(clubInfoArr));
            return {
              ...state,
              userInfo: action.userInfo,
              isSuperAdmin: false,
              isAdmin: true,
              isUser: false,
              isAflAdmin: true,
              isWbcAdmin: false,
              clubInfo: clubInfoArr
            };
          });
          return {
            ...state,
            userInfo: action.userInfo,
            isSuperAdmin: false,
            isAdmin: true,
            isUser: false,
            isAflAdmin: true,
            isWbcAdmin: false,
            clubInfo: []
          };
        } else if (action.userInfo.wbcAdmin) {
          LineupCardApi.getClubInfo("WBC").then(response => {
            const clubInfoArr = response
              .map(club => {
                return { label: club.abbr, value: club.id, name: club.name };
              })
              .sort((clubA, clubB) => clubA.label.localeCompare(clubB.label));
            localStorage.setItem("clubInfo", JSON.stringify(clubInfoArr));
            return {
              ...state,
              userInfo: action.userInfo,
              isSuperAdmin: false,
              isAdmin: true,
              isUser: false,
              isAflAdmin: false,
              isWbcAdmin: true,
              clubInfo: clubInfoArr
            };
          });
          return {
            ...state,
            userInfo: action.userInfo,
            isSuperAdmin: false,
            isAdmin: true,
            isUser: false,
            isAflAdmin: false,
            isWbcAdmin: true,
            clubInfo: []
          };
        } else if (action.userInfo.wbcClub) {
          return {
            ...state,
            userInfo: action.userInfo,
            isAdmin: false,
            isUser: true,
            wbcClub: true,
            club: action.userInfo.club
          };
        } else if (action.userInfo.aflClub) {
          return {
            ...state,
            userInfo: action.userInfo,
            isAdmin: false,
            isUser: true,
            aflClub: true,
            club: action.userInfo.club
          };
        } else {
          return { ...state, userInfo: action.userInfo, isAdmin: false, isUser: true, club: action.userInfo.club };
        }
      case "setClubInfo":
        const clubInfoArr = action.clubInfo
          .map(club => {
            return { label: club.abbr, value: club.id, name: club.name };
          })
          .sort((clubA, clubB) => clubA.label.localeCompare(clubB.label));
        localStorage.setItem("clubInfo", JSON.stringify(clubInfoArr));
        return { ...state, clubInfo: clubInfoArr };
      case "checkUserInfo":
        break;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { authState } = useOktaAuth();

  const getClubInfo = clubType => {
    LineupCardApi.getClubInfo(clubType).then(response => {
      dispatch({ type: "setClubInfo", clubInfo: response });
    });
  };

  const getUserInfo = () => {
    LineupCardApi.getUserInfo().then(data => {
      dispatch({ type: "setUserInfo", userInfo: data });
    });
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated && !state.loggedIn) {
      dispatch({
        type: "login"
      });
      getUserInfo();
      getClubInfo("MLB");
    }
  }, [authState]);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within a AuthContextProvider");
  }
  return authContext;
};

AuthContextProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export { AuthContext, AuthContextProvider, useAuth, oktaAuth };
