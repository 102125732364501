import React, { useState, useEffect, useCallback } from "react";
import { CardContainer, CardHeader, CardBody, Collapse, Input } from "best-common-react";
import RosterSettingsTable from "./RosterSettingsTable";
import RosterSettingsApi from "../../httpClients/RosterSettingsApi";
import styled from "styled-components";
import { PrimaryOutlinedButtonStyled } from "../elements/PrimaryOutlinedButton";
import { useAlert } from "../alerts/AlertsContext";

const FooterStyle = styled.div.attrs(() => ({
  className: "d-flex justify-content-between align-items-center mt-2"
}))``;

const AddRowContainer = styled.div.attrs(() => ({
  className: "d-flex align-items-center justify-content-between"
}))`
  width: 175px;
`;

const InputWrapper = styled.div.attrs(() => ({}))`
  width: 50px;
`;

const DisclaimerText = styled.div.attrs({ className: "" })`
  font-size: 0.9rem;
  font-weight: normal;
  color: black;
  margin-top: 2px;
`;

const ADD_ROW_AMT_STARTING_VAL = "1";

const getNewRosterSetting = index => {
  return {
    index: index,
    startDate: "",
    endDate: "",
    rosterCap: "",
    pitcherCap: ""
  };
};

const RosterSettingsComponent = () => {
  const [addRowValue, setAddRowValue] = useState(ADD_ROW_AMT_STARTING_VAL);
  const [rosterSettings, setRosterSettings] = useState([]);
  const [saveRosterSettingsBtnDisabled, setSaveRosterSettingsBtnDisabled] = useState(true);
  const showAlert = useAlert();

  useEffect(() => {
    RosterSettingsApi.getRosterSettings()
      .then(response => {
        if (response && response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            response[i].index = i;
            if (response[i].pitcherCap === 0) {
              response[i].pitcherCap = "";
            }
          }
          setRosterSettings([...response]);
        } else if (response.length === 0) {
          setRosterSettings([getNewRosterSetting(0)]);
        }
      })
      .catch(() => {
        showAlert("Error retrieving current Roster Settings, contact Lineup Card Systems", "danger");
      });
  }, []);

  // TODO: Handle overlapping date ranges
  useEffect(() => {
    if (rosterSettings.length > 0) {
      let invalid = false;
      rosterSettings.forEach(rosterSetting => {
        if (
          rosterSetting.startDate === "" ||
          rosterSetting.startDate === null ||
          rosterSetting.endDate === "" ||
          rosterSetting.endDate === null ||
          rosterSetting.rosterMax === "" ||
          rosterSetting.rosterMax === null
        ) {
          invalid = true;
        }
      });
      setSaveRosterSettingsBtnDisabled(invalid);
    }
  }, [rosterSettings]);

  const editRosterSetting = (index, attribute, newValue) => {
    if (index < rosterSettings.length) {
      const rosterSetting = rosterSettings[index];
      if (rosterSetting) {
        const editedRosterSettings = [...rosterSettings];
        rosterSetting[attribute] = newValue;
        editedRosterSettings[index] = rosterSetting;
        setRosterSettings(editedRosterSettings);
      }
    }
  };

  const removeRosterSetting = index => {
    if (index < rosterSettings.length) {
      const rosterSetting = rosterSettings[index];
      if (rosterSetting) {
        if (rosterSetting.id) {
          RosterSettingsApi.deleteRosterSettingById(rosterSetting.id)
            .then(() => {
              showAlert("Success", "success");
            })
            .catch(() => {
              showAlert("Error removing Roster Setting, contact Lineup Card Systems", "danger");
            });
        }
        const editedRosterSettings = [...rosterSettings];
        editedRosterSettings.splice(index, 1);
        for (let i = 0; i < editedRosterSettings.length; i++) {
          editedRosterSettings[i].index = i;
        }
        setRosterSettings(editedRosterSettings);
      }
    }
  };

  const addRows = useCallback(() => {
    const rowsToAdd = parseInt(addRowValue);
    const newRows = [...rosterSettings];
    for (let i = 0; i < rowsToAdd; i++) {
      newRows.push(getNewRosterSetting(rosterSettings.length + i));
    }

    setRosterSettings(newRows);
  }, [addRowValue, rosterSettings]);

  const saveRosterSettings = () => {
    const rosterSettingsToSave = { rosterSettingsList: rosterSettings };
    RosterSettingsApi.setRosterSettings(rosterSettingsToSave)
      .then(() => {
        showAlert("Saved", "success");
      })
      .catch(() => {
        showAlert("Error saving Roster Settings, contact Lineup Card Systems", "danger");
      });
  };

  return (
    <CardContainer>
      <CardHeader>Settings</CardHeader>
      <CardBody>
        <Collapse title="Roster Thresholds" startsOpen={false} styles={{ borderColor: "var(--fog)" }}>
          <div className="d-flex mb-2">
            <div className="mr-1" style={{ color: "red" }}>
              ⓘ
            </div>
            <DisclaimerText>
              If pitchers max is left empty it is assumed no cap on number of pitchers allowed
            </DisclaimerText>
          </div>
          <RosterSettingsTable
            rosterSettings={rosterSettings}
            editRosterSetting={editRosterSetting}
            removeRosterSetting={removeRosterSetting}
          />
          <FooterStyle>
            <AddRowContainer>
              <InputWrapper>
                <Input placeholder="1" value={addRowValue} onChange={event => setAddRowValue(event.target.value)} />
              </InputWrapper>
              <PrimaryOutlinedButtonStyled height={26} width={112} onClick={addRows}>
                Add Row(s)
              </PrimaryOutlinedButtonStyled>
            </AddRowContainer>
            <PrimaryOutlinedButtonStyled
              height={26}
              width={160}
              disabled={saveRosterSettingsBtnDisabled}
              onClick={saveRosterSettings}
            >
              Save Roster Settings
            </PrimaryOutlinedButtonStyled>
          </FooterStyle>
        </Collapse>
      </CardBody>
    </CardContainer>
  );
};

export default RosterSettingsComponent;
