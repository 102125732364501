import React from "react";
import styled from "styled-components";

const CodeLink = styled.a`
  &&& {
    color: #d2d2d2;
    font-size: 0.7rem;
    &:hover {
      color: #d6d6d6;
    }
  }
`;

const SourceCodeLink = () => (
  <div>
    <CodeLink href="https://s3.amazonaws.com/imagesdev.mlb.com/lineup-card-service.zip">Download Source Code</CodeLink>
  </div>
);

export default SourceCodeLink;
