import React from "react";
import EditCustomCardComponent from "../components/customCards/EditCustomCardComponent";
import { EditCustomCardContextProvider } from "../components/customCards/contexts/EditCustomCardContext";

const EditCustomCardContainer = () => {
  return (
    <EditCustomCardContextProvider>
      <EditCustomCardComponent />
    </EditCustomCardContextProvider>
  );
};

export default EditCustomCardContainer;
