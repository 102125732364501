import React, { useContext } from "react";
import { FormInput } from "best-common-react";
import { EditCustomCardContext } from "../contexts/EditCustomCardContext";

const CardInfoTextInputs = () => {
  const editCustomCardContext = useContext(EditCustomCardContext);
  const { dispatch } = editCustomCardContext;
  const { customCardInfo } = editCustomCardContext.state;

  return (
    <div>
      <div className="d-flex">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <FormInput
            name="title-text-input"
            type="text"
            isRequired={false}
            isDisabled={false}
            label="Title"
            editable={true}
            value={customCardInfo.title}
            onChangeFn={value => {
              dispatch({
                type: "setTitle",
                title: value
              });
            }}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <FormInput
            name="venue-text-input"
            type="text"
            isRequired={false}
            isDisabled={false}
            label="Venue"
            editable={true}
            value={customCardInfo.venue}
            onChangeFn={value => {
              dispatch({
                type: "setVenue",
                venue: value
              });
            }}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <FormInput
            name="city-text-input"
            type="text"
            isRequired={false}
            isDisabled={false}
            label="City"
            editable={true}
            value={customCardInfo.city}
            onChangeFn={value => {
              dispatch({
                type: "setCity",
                city: value
              });
            }}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <FormInput
            name="state-text-input"
            type="text"
            isRequired={false}
            isDisabled={false}
            label="State/Province"
            editable={true}
            value={customCardInfo.state}
            onChangeFn={value => {
              dispatch({
                type: "setState",
                state: value
              });
            }}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <FormInput
            name="home-team-text-input"
            type="text"
            isRequired={false}
            isDisabled={false}
            label="Home Team"
            editable={true}
            value={customCardInfo.homeTeamName}
            onChangeFn={value => {
              dispatch({
                type: "setHomeTeam",
                homeTeam: value
              });
            }}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <FormInput
            name="away-team-text-input"
            type="text"
            isRequired={false}
            isDisabled={false}
            label="Away Team"
            editable={true}
            value={customCardInfo.awayTeamName}
            onChangeFn={value => {
              dispatch({
                type: "setAwayTeam",
                awayTeam: value
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CardInfoTextInputs;
