import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVICES_URL;

class AuditApi {
  static getAuditInfoForGames = games => {
    let url = `${BASE_URL}/audit/`;
    return axios.post(url, games).then(response => response.data);
  };
  static printAuditByGamePk = gamePk => {
    let url = `${BASE_URL}/audit/printAudit/${gamePk}`;
    return axios.get(url, {
      responseType: "blob"
    });
  };
}

export default AuditApi;
