import React, { useCallback, useEffect, useState } from "react";
import LineupSection from "./LineupSection";
import EbisSection from "../ebis/EbisSection";
import { useEbisSectionVisible } from "../../../customhooks/useEbisSectionVisible";
import { useAlert } from "../../alerts/AlertsContext";
import EbisApi from "../../../httpClients/EbisApi";
import { useGames } from "../../../contexts/GamesContext";
import { adjustLineupPosOptions, getBlankLineup, configurePitchers, isLineupValid } from "../../../utils/LineupUtils";
import RosterErrorModal from "../modal/RosterErrorModal";
import { trackPromise } from "react-promise-tracker";
import { useAuth } from "../../../contexts/AuthContext";

const SectionHolder = () => {
  const [modalState, setModalState] = useState(false);
  const {
    state: { selectedOrg, otherOrg, viewableTeam, selectedGames, rosterErrors, selectedLeague },
    dispatch: gamesDispatch
  } = useGames();

  const {
    state: { email, clubInfo }
  } = useAuth();

  const selectedGame = selectedGames[0];

  const showAlert = useAlert();

  const showEbisSection = useEbisSectionVisible();

  const rosterErrorModalOnClose = () => {
    setModalState(false);
    gamesDispatch({ type: "resetRosterErrors" });
  };

  const handleRosterRulesResults = rosterRulesResults => {
    if (rosterRulesResults) {
      const errors = [];
      let selectedError = false;
      let otherError = false;
      const selectedTeamErrors = rosterRulesResults.selectedTeamResults
        ? rosterRulesResults.selectedTeamResults.rosterErrors
        : [];
      const otherTeamErrors = rosterRulesResults.otherTeamResults
        ? rosterRulesResults.otherTeamResults.rosterErrors
        : [];

      if (selectedTeamErrors.length > 0) {
        errors.push(...selectedTeamErrors[0].split("\n"));
        selectedError = true;
      }

      if (otherTeamErrors.length > 0) {
        errors.push(...otherTeamErrors[0].split("\n"));
        otherError = true;
      }

      // Show modal if there are errors
      setModalState(errors.length > 0);

      gamesDispatch({
        type: "setRosterErrors",
        rosterErrors: [...errors],
        selectedRosterHasError: selectedError,
        otherRosterHasError: otherError
      });
    }
  };

  const setLineupsSubmittable = orgId => {
    gamesDispatch({
      type: "setLineupStatus",
      lineupDirty: true,
      orgId: orgId
    });
  };

  const getEbisRostersAndLineups = useCallback(() => {
    const newTeamMap = new Map();
    if (selectedOrg && otherOrg && selectedOrg.value && otherOrg.value) {
      trackPromise(
        EbisApi.getEbisRostersAndLineupsByOrgIds(
          selectedGames[0].gamePk,
          selectedOrg.value,
          otherOrg.value,
          selectedLeague.value
        )
          .then(response => {
            if (response) {
              // TODO -- Abstract out into a utility class, used also in GamesFooter
              const lineupPlayers = response.lineups[0].players;
              const hasDH = lineupPlayers?.length === 0 ? true : lineupPlayers?.length === 10;
              newTeamMap.set(selectedOrg.value, {
                roster: response.ebisRosters[0].players.sort((a, b) => a.lastName.localeCompare(b.lastName)),
                availablePlayers: response.ebisRosters[0].availablePlayers.sort((a, b) =>
                  a.lastName.localeCompare(b.lastName)
                ),
                pitchers: configurePitchers(response.ebisRosters[0].pitchers),
                lineup: lineupPlayers?.length > 0 ? lineupPlayers : getBlankLineup(selectedGames),
                validLineup: lineupPlayers ? isLineupValid(lineupPlayers) : false,
                hasDH: hasDH,
                lineupSubmitted: !response.lineups[0].draft,
                orgName: selectedOrg.name,
                posCodeOptions: adjustLineupPosOptions(response.ebisRosters[0].playerPosCodes, hasDH),
                gumboTimecode: response.gumboTimecode,
                ebisRoster: response.ebisRosters[0],
                ebisGame: response.ebisGame,
                teamName: selectedOrg.name,
                nonActivePlayers: response.ebisRosters[0].nonActivePlayers?.sort((a, b) =>
                  a.lastName.localeCompare(b.lastName)
                )
              });
              const otherLineupPlayers = response.lineups[1].players;
              const otherHasDH = otherLineupPlayers?.length === 0 ? true : otherLineupPlayers?.length === 10;
              newTeamMap.set(otherOrg.value, {
                roster: response.ebisRosters[1].players.sort((a, b) => a.lastName.localeCompare(b.lastName)),
                availablePlayers: response.ebisRosters[1].availablePlayers.sort((a, b) =>
                  a.lastName.localeCompare(b.lastName)
                ),
                pitchers: configurePitchers(response.ebisRosters[1].pitchers),
                lineup: otherLineupPlayers?.length > 0 ? otherLineupPlayers : getBlankLineup(selectedGames),
                lineupSubmitted: !response.lineups[1].draft,
                orgName: otherOrg.name,
                validLineup: otherLineupPlayers ? isLineupValid(otherLineupPlayers) : false,
                hasDH: otherHasDH,
                posCodeOptions: adjustLineupPosOptions(response.ebisRosters[1].playerPosCodes, otherHasDH),
                gumboTimecode: response.gumboTimecode,
                ebisRoster: response.ebisRosters[1],
                ebisGame: response.ebisGame,
                teamName: otherOrg.name,
                nonActivePlayers: response.ebisRosters[1].nonActivePlayers?.sort((a, b) =>
                  a.lastName.localeCompare(b.lastName)
                )
              });

              handleRosterRulesResults(response.rosterRulesResults);

              gamesDispatch({ type: "setTeamMap", teamMap: newTeamMap });
              // Save the potentially changed lineup
              gamesDispatch({
                type: "saveLineup",
                orgId: selectedOrg.value,
                draft: !newTeamMap.get(selectedOrg.value).lineupSubmitted,
                user: email,
                gamePk: selectedGames[0]?.gamePk,
                silentSave: true,
                gameType: selectedGames[0]?.gameType,
                onSubmit: false
              });

              if (
                !newTeamMap.get(selectedOrg.value).lineupSubmitted &&
                (newTeamMap.get(selectedOrg.value).validLineup || selectedGames[0]?.gameType === "S")
              ) {
                setLineupsSubmittable(selectedOrg.value);
              }

              if (
                !newTeamMap.get(otherOrg.value).lineupSubmitted &&
                (newTeamMap.get(otherOrg.value).validLineup || selectedGames[0]?.gameType === "S")
              ) {
                setLineupsSubmittable(otherOrg.value);
              }
            }
          })
          .catch(() => {
            showAlert("Error retrieving EBIS Rosters, Please contact Lineup Cards Support!", "danger");
          })
      );
    }
  }, [showEbisSection, selectedOrg, otherOrg]);

  useEffect(() => {
    gamesDispatch({
      type: "setRefreshEbisRosterFn",
      refreshEbisRoster: getEbisRostersAndLineups
    });
  }, [showEbisSection, selectedOrg, otherOrg]);

  useEffect(() => {
    if (selectedOrg && selectedGame && clubInfo) {
      let unselectedOrg;
      if (selectedOrg.name === selectedGame.teams.home.team.name) {
        unselectedOrg = clubInfo.filter(club => club.name === selectedGame.teams.away.team.name)[0];
      } else {
        unselectedOrg = clubInfo.filter(club => club.name === selectedGame.teams.home.team.name)[0];
      }
      gamesDispatch({ type: "setOtherOrg", org: unselectedOrg });
    }
  }, [selectedOrg, selectedGame, clubInfo]);

  useEffect(() => {
    getEbisRostersAndLineups();
  }, [viewableTeam, selectedOrg, otherOrg]);

  return (
    <>
      <div className="d-flex">
        <RosterErrorModal
          isOpen={modalState}
          messages={rosterErrors}
          onClose={() => {
            rosterErrorModalOnClose();
          }}
        />
        <LineupSection fullWidth={!showEbisSection} />
        {showEbisSection && <EbisSection />}
      </div>
    </>
  );
};

export default SectionHolder;
