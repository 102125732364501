import React from "react";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";

const StyledOverlay = styled.div.attrs(() => ({}))`
  position: absolute;
  zindex: ${props => (props.loading ? 999 : -1)};
  display: ${props => (props.loading ? "block" : "none")};
  top: ${props => props.top};
  left: ${props => props.left};
`;

const Loader = ({ loading, top, left }) => {
  return (
    <StyledOverlay loading={loading} top={top} left={left}>
      <LoadingSpinner isLoading={loading} />
    </StyledOverlay>
  );
};

const LoadingOverlay = ({ children, loading, top, left, showSpinner = true }) => {
  return (
    <>
      <div style={{ opacity: loading ? 0.3 : 1, pointerEvents: loading ? "none" : "auto" }}>{children}</div>
      {showSpinner && <Loader loading={loading} top={top} left={left} />}
    </>
  );
};

export default LoadingOverlay;
