import React, { useReducer, useContext } from "react";

const initialState = {
  colorOptions: {},
  logoTransparency: 0.25,
  lastNameOnly: false,
  fullWidthImage: false
};

const SettingsContext = React.createContext({});

const SettingsContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setLogoTransparency":
        return { ...state, logoTransparency: action.logoTransparency };
      case "setColorOptions":
        return { ...state, colorOptions: action.colorOptions };
      case "setRightyColor":
        return { ...state, colorOptions: { ...state.colorOptions, righty: action.righty } };
      case "setLeftyColor":
        return { ...state, colorOptions: { ...state.colorOptions, lefty: action.lefty } };
      case "setSwitchColor":
        return { ...state, colorOptions: { ...state.colorOptions, switchHitter: action.switch } };
      case "setLastNameOnly":
        return { ...state, lastNameOnly: action.lastNameOnly };
      case "setFullWidthImage":
        return { ...state, fullWidthImage: action.fullWidthImage };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  return <SettingsContext.Provider value={{ state, dispatch }}>{children}</SettingsContext.Provider>;
};

const useSettings = () => {
  const settingsContext = useContext(SettingsContext);
  if (!settingsContext) {
    throw new Error("useSettings must be used within a SettingsContextProvider");
  }
  return settingsContext;
};

const useColorOptions = () => {
  const { state: colorOptions } = useSettings();
  const options = [];
  if (
    colorOptions &&
    colorOptions.colorOptions.lefty &&
    colorOptions.colorOptions.righty &&
    colorOptions.colorOptions.switchHitter
  ) {
    options.push({ key: "lefty", hex: colorOptions.colorOptions.lefty.value });
    options.push({ key: "righty", hex: colorOptions.colorOptions.righty.value });
    options.push({ key: "switch", hex: colorOptions.colorOptions.switchHitter.value });
  }
  return options;
};

export { SettingsContext, SettingsContextProvider, useSettings, useColorOptions };
