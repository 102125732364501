import React, { useEffect, useCallback, useState } from "react";
import { CardContainer, CardBody, CardHeader, BestSelect } from "best-common-react";
import { useAuth } from "../../contexts/AuthContext";
import DefaultLineup from "./DefaultLineup";
import {
  getEmptyDefaultLineup,
  setDefaultLineupPos,
  handleDefaultLineupSwap,
  handleDefaultPositionChange,
  lineupMapper,
  clearDefaultLineups,
  removeDefaultLineupPlayers,
  isEmptyLineup
} from "../../utils/LineupUtils";
import styled from "styled-components";
import ButtonRow from "../elements/ButtonRow";
import { useAlert } from "../alerts/AlertsContext";
import { useGames } from "../../contexts/GamesContext";
import { getEbisRoster } from "../../utils/EbisUtils";
import LoadingSpinner from "../loading/LoadingSpinner";
import DefaultLineupApi from "../../httpClients/DefaultLineupApi";
import { trackPromise } from "react-promise-tracker";

const FooterStyle = styled.div.attrs(() => ({
  className: "mb-3 mt-5"
}))`
  width: ${props => (props.width ? props.width : "20rem")};
  float: right;
`;

const LineupSettingsComponent = () => {
  const showAlert = useAlert();
  const {
    state: { isAdmin, clubInfo, club: clubAssoc, email }
  } = useAuth();
  const {
    state: { defaultTeamMap },
    dispatch: gamesDispatch
  } = useGames();
  const [clubId, setClubId] = useState(null);
  const [clubOptions, setClubOptions] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const setDefaults = (orgId, leftDefault, rightDefault) => {
    getEbisRoster(orgId)
      .then(response => {
        if (response) {
          const playerOpt = response.players.sort((a, b) => a.lastName.localeCompare(b.lastName));
          defaultTeamMap.set(orgId, {
            ...defaultTeamMap.get(orgId),
            leftyPlayerOptions: removeDefaultLineupPlayers(leftDefault, playerOpt),
            rightyPlayerOptions: removeDefaultLineupPlayers(rightDefault, [...playerOpt]),
            leftyLineup: leftDefault,
            rightyLineup: rightDefault,
            leftyPosCodeOptions: setDefaultLineupPos([...response.playerPosCodes], leftDefault),
            rightyPosCodeOptions: setDefaultLineupPos(response.playerPosCodes, rightDefault)
          });
          gamesDispatch({ type: "setDefaultTeamMap", defaultTeamMap: defaultTeamMap });
          setIsLoading(false);
        }
      })
      .catch(() => {
        showAlert("Error retrieving EBIS Rosters, Please contact Lineup Cards Support!", "danger");
      });
  };

  const changeClub = clubObj => {
    const orgId = clubObj.value.value;
    setClubId(orgId);
    setDefaultLineups(orgId);
  };

  const handlePlayerSwap = (lefty, newPlayer, originalPlayer) => {
    handleDefaultLineupSwap(originalPlayer, newPlayer, defaultTeamMap, clubId, gamesDispatch, lefty);
    setShowButtons(true);
  };

  const handlePositionSwap = (lefty, newPos, originalPos) => {
    handleDefaultPositionChange(originalPos, newPos, defaultTeamMap, clubId, gamesDispatch, lefty);
    if (lefty && !isEmptyLineup(defaultTeamMap?.get(clubId)?.leftyLineup)) {
      setShowButtons(true);
    }
    if (!lefty && !isEmptyLineup(defaultTeamMap?.get(clubId)?.rightyLineup)) {
      setShowButtons(true);
    }
  };

  const setDefaultLineups = orgId => {
    setIsLoading(true);
    trackPromise(
      Promise.all([DefaultLineupApi.getDefaultLineup(orgId, true), DefaultLineupApi.getDefaultLineup(orgId, false)])
        .then(values => {
          // lineupMapper converts lineupDTO from api to frontend lineup structure
          // if team does not have default it returns '', can add more checks here when needed
          const leftDefault = values[0]?.lineup ? lineupMapper(values[0].lineup) : getEmptyDefaultLineup();
          const rightDefault = values[1]?.lineup ? lineupMapper(values[1].lineup) : getEmptyDefaultLineup();
          setDefaults(orgId, leftDefault, rightDefault);
        })
        .catch(() => {
          showAlert("Error setting default lineups.", "danger");
        })
    );
  };

  const saveAsOnClick = () => {
    const leftLineup = [...defaultTeamMap.get(clubId).leftyLineup];
    const rightLineup = [...defaultTeamMap.get(clubId).rightyLineup];
    // converts a lineup with rows {battingOrder: int, player: obj, position:  string}
    // to a lineup where player obj is flattened and battingOrder and position are added to match backend LineupPlayerDTO
    let promiseArray = [];
    if (!isEmptyLineup(leftLineup)) {
      const leftDefaultLineupDTO = {
        orgId: clubId,
        lineup: leftLineup.map(row => {
          return { battingOrder: row.battingOrder, position: row.position, ...row.player };
        }),
        lefty: true,
        user: email
      };
      promiseArray.push(DefaultLineupApi.saveDefaultLineup(leftDefaultLineupDTO));
    }
    if (!isEmptyLineup(rightLineup)) {
      const rightDefaultLineupDTO = {
        orgId: clubId,
        lineup: rightLineup.map(row => {
          return { battingOrder: row.battingOrder, position: row.position, ...row.player };
        }),
        lefty: false,
        user: email
      };
      promiseArray.push(DefaultLineupApi.saveDefaultLineup(rightDefaultLineupDTO));
    }

    Promise.all(promiseArray)
      .then(() => {
        showAlert("Successfully saved default lineups", "success");
        setShowButtons(false);
      })
      .catch(() => {
        showAlert("Error saving default lineup.", "danger");
      });
  };

  const cancelChanges = () => {
    clearDefaultLineups(defaultTeamMap, gamesDispatch, clubId);
    setShowButtons(false);
  };

  useEffect(() => {
    if (clubInfo && Object.keys(clubInfo).length > 0) {
      let userClub = {};
      if (isAdmin) {
        userClub = clubInfo[0];
      } else {
        userClub = clubInfo.filter(club => club.name === clubAssoc)[0];
      }
      setClubId(userClub.value);
      setDefaultLineups(userClub.value);
      setClubOptions(clubInfo?.sort((a, b) => (a.name > b.name ? 1 : -1)).map(ci => ({ label: ci.name, value: ci })));
    }
  }, [clubInfo]);

  const buttonArray = [
    {
      name: "saveButton",
      content: "Save",
      height: 26,
      width: 62,
      onClick: () => {
        saveAsOnClick();
      },
      disabled: !showButtons,
      visible: true,
      style: "primary"
    },
    {
      name: "clearButton",
      content: "Clear Lineups",
      height: 26,
      width: 140,
      onClick: () => {
        cancelChanges();
      },
      disabled: !showButtons,
      visible: true
    }
  ];

  return (
    <CardContainer>
      <CardHeader>Default Lineup</CardHeader>
      <CardBody>
        {isAdmin && (
          <div className="d-flex">
            <div className="ml-3 mb-2 mr-3" style={{ width: "300px" }}>
              <BestSelect
                options={clubOptions}
                placeHolder="pos"
                value={clubOptions?.find(club => club.value.value === clubId)}
                onChange={clubObj => {
                  changeClub(clubObj);
                }}
                styles={{
                  container: {
                    height: "36px",
                    fontSize: "14px"
                  },
                  control: {
                    height: "36px",
                    minHeight: "32px"
                  },
                  valueContainer: {
                    padding: "0 8px 0px 8px"
                  }
                }}
                onClick={() => {}}
              />
            </div>
            <LoadingSpinner isLoading={isLoading} />
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div className="col-6 mt-2">
            <DefaultLineup
              lefty={false}
              lineup={defaultTeamMap?.get(clubId)?.rightyLineup || []}
              posOptions={defaultTeamMap?.get(clubId)?.rightyPosCodeOptions || []}
              playerOptions={defaultTeamMap?.get(clubId)?.rightyPlayerOptions || []}
              handlePlayerSwap={handlePlayerSwap}
              handlePositionSwap={handlePositionSwap}
            />
          </div>
          <div className="col-6 mt-2">
            <DefaultLineup
              lefty
              lineup={defaultTeamMap?.get(clubId)?.leftyLineup || []}
              posOptions={defaultTeamMap?.get(clubId)?.leftyPosCodeOptions || []}
              playerOptions={defaultTeamMap?.get(clubId)?.leftyPlayerOptions || []}
              handlePlayerSwap={handlePlayerSwap}
              handlePositionSwap={handlePositionSwap}
            />
          </div>
        </div>

        <FooterStyle width="15rem">
          <ButtonRow buttonArray={buttonArray} />
        </FooterStyle>
      </CardBody>
    </CardContainer>
  );
};

export default LineupSettingsComponent;
