import React from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

const StyledOverlay = styled.div.attrs(() => ({}))`
  z-index: ${props => (props.saving ? 999999 : -1)};
  display: ${props => (props.saving ? "inline" : "none")};
  align-items: center;
`;

const StyledText = styled.div.attrs(() => ({}))`
  color: gray;
  font-size: 11px;
  display: inline;
  margin-left: 5px;
`;

const SavingSpinner = ({ saving, top, left }) => {
  return (
    <StyledOverlay saving={saving} top={top} left={left}>
      <ClipLoader color="#4c8cee" sizeUnit="px" size={10} loading={saving} />
      <StyledText>Saving...</StyledText>
    </StyledOverlay>
  );
};

export default SavingSpinner;
