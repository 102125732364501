import React, { useState } from "react";
import styled from "styled-components";

const MLB_STATIC_IMG_URL = "https://www.mlbstatic.com/team-logos/";

const Image = styled.img`
  height: 1.7rem;
  width: 1.7rem;
`;

const Matchup = ({ home, away }) => {
  const [homeNotFound, setHomeNotFound] = useState(false);
  const [awayNotFound, setAwayNotFound] = useState(false);

  const handleError = (event, type) => {
    event.target.onerror = null;
    if (type === "away") {
      setAwayNotFound(true);
    } else if (type === "home") {
      setHomeNotFound(true);
    }
  };

  return (
    <div>
      {awayNotFound ? (
        <span>{away.abbreviation}</span>
      ) : (
        <Image src={MLB_STATIC_IMG_URL + away.id + ".svg"} onError={event => handleError(event, "away")} />
      )}
      <span style={{ margin: "0 .5rem" }}>@</span>
      {homeNotFound ? (
        <span>{home.abbreviation}</span>
      ) : (
        <Image src={MLB_STATIC_IMG_URL + home.id + ".svg"} onError={event => handleError(event, "home")} />
      )}
    </div>
  );
};

export default Matchup;
