import React, { useState, useEffect } from "react";
import { DataTable, Icon } from "best-common-react";
import styled from "styled-components";
import LineupCardApi from "../../httpClients/LineupCardApi";
import { formatDate, DateFormats } from "../../utils/DateUtils";
import { RouteConstants } from "../../constants/RouteConstants";
import { withRouter } from "react-router-dom";
import DeleteCustomCardModal from "./modal/DeleteCustomCardModal";
import _ from "lodash";
import { trackPromise } from "react-promise-tracker";

const HoverIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const MenuFormatter = withRouter(({ history, row }) => {
  const navToEditCustomCard = () => {
    localStorage.setItem("customCardInfo", JSON.stringify(row));
    history.push(RouteConstants.EDIT_CUSTOM_CARD);
  };

  const deleteCustomCard = () => {
    row.setCustomCardIdToDelete(row.customCardId);
    row.modalFn(true);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <HoverIcon iconName="fa-pencil-alt" onClick={navToEditCustomCard} />
      <HoverIcon iconName="fa-trash-alt" onClick={deleteCustomCard} />
    </div>
  );
});

const DataTableWrapper = styled.div`
  .react-grid-Grid {
    border: none;
  }

  .react-grid-Cell {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    color: ${props => props.theme.black};
  }

  .react-grid-Main {
    outline: none;
  }

  /* Tried using &&&, didn't work */
  .react-grid-HeaderCell {
    font-weight: bold;
    color: #22356f !important;
    border-bottom: 1px solid #f2f3f4 !important;
    background: #dee5ed !important;
  }
`;

const Columns = [
  {
    key: "",
    name: "",
    width: 60,
    sortable: false,
    formatter: <MenuFormatter />
  },
  {
    key: "",
    name: "",
    width: 20,
    sortable: false
  },
  {
    key: "title",
    name: "Title",
    width: 186,
    sortable: true
  },
  {
    key: "venue",
    name: "Venue",
    width: 140,
    sortable: true
  },
  {
    key: "city",
    name: "City",
    width: 110,
    sortable: true
  },
  {
    key: "state",
    name: "State",
    width: 100,
    sortable: true
  },
  {
    key: "date",
    name: "Date",
    width: 160,
    sortable: true
  },
  {
    key: "homeTeamName",
    name: "Home Team",
    width: 150,
    sortable: true
  },
  {
    key: "awayTeamName",
    name: "Away Team",
    sortable: true
  }
];

const sortTable = (values, sortFilters, defaultKey = "name") => {
  if (sortFilters.dir === "NONE") {
    return _.sortBy(values, [value => _.toLower(value[defaultKey])]);
  } else if (sortFilters.dir === "ASC") {
    return _.sortBy(values, [value => _.toLower(value[sortFilters.col])]);
  } else {
    return _.sortBy(values, [value => _.toLower(value[sortFilters.col])]).reverse();
  }
};

const CustomCardsDataTable = ({ className, columns: cols, ...rest }) => {
  return (
    <DataTableWrapper className={className}>
      <DataTable columns={cols} {...rest} />
    </DataTableWrapper>
  );
};

const CustomCardsTable = ({ generationList, addToGenerationList, removeFromGenerationList }) => {
  const [customCards, setCustomCards] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [customCardIdToDelete, setCustomCardIdToDelete] = useState("");
  const [sortFilters, setSortFilters] = useState({ col: "date", dir: "DESC" });

  useEffect(() => {
    updateCustomCardsList();
  }, [generationList, sortFilters]);

  const updateCustomCardsList = () => {
    trackPromise(
      LineupCardApi.getAllCustomCards().then(response => {
        response.forEach(customCard => {
          customCard.date = formatDate(customCard.date, DateFormats.MONTH_NAME_DAY_YEAR);
          customCard.modalFn = setModalOpen;
          customCard.setCustomCardIdToDelete = setCustomCardIdToDelete;
          customCard.isSelected = generationList.includes(customCard.customCardId);
        });

        setCustomCards(sortTable(response, sortFilters));
      })
    );
  };

  return (
    <div>
      <DeleteCustomCardModal
        isOpen={modalOpen}
        customCardId={customCardIdToDelete}
        onClose={setModalOpen}
        updateCustomCardList={updateCustomCardsList}
      />
      <CustomCardsDataTable
        id="custom-cards-table"
        data={customCards}
        columns={Columns}
        sortFunc={(col, dir) => setSortFilters({ col, dir })}
        sortColumn={sortFilters.col}
        sortDirection={sortFilters.dir}
        headerRowHeight={40}
        minHeight={400}
        rowHeight={45}
        rowSelection={{
          showCheckbox: true,
          onRowsSelected: addToGenerationList,
          onRowsDeselected: removeFromGenerationList,
          selectBy: {
            isSelectedKey: "isSelected"
          },
          enableShiftSelect: true
        }}
      />
    </div>
  );
};

export default CustomCardsTable;
