import React from "react";
import { DataTable } from "best-common-react";
import { LineupTableStyled } from "../../elements/LineupTableStyled";
import styled from "styled-components";

const TWPhighlightStyle = styled.div.attrs(() => ({}))`
  color: black;
  background-color: yellow;
  font-weight: bold;
  font-size: 14px;
`;

const AvailablePlayerNameFormatter = ({ row }) => {
  const displayName = row?.displayName || row?.lastName + ", " + row?.preferredName?.substring(0, 1);
  return (
    <div className="d-flex justify-content-between">
      <div style={{ fontSize: "14px" }}>{displayName}</div>
      {row?.twp && <TWPhighlightStyle>TWP</TWPhighlightStyle>}
    </div>
  );
};

const Columns = [
  {
    key: "",
    name: "Available Players",
    formatter: AvailablePlayerNameFormatter
  }
];

const AvailablePlayersTable = ({ availablePlayers = [] }) => {
  return (
    <LineupTableStyled>
      <DataTable columns={Columns} data={availablePlayers} height={440} rowHeight={40} minColumnWidth={100} />
    </LineupTableStyled>
  );
};

export default AvailablePlayersTable;
