import React, { useReducer, useContext } from "react";
import PropTypes from "prop-types";
import LineupApi from "../httpClients/LineupApi";
import { useAlert } from "../components/alerts/AlertsContext";
import { getBlankLineup } from "../utils/LineupUtils";

const initialState = {
  selectedOrg: {},
  otherOrg: {},
  viewableTeam: {},
  dates: {
    startDate: {},
    endDate: {}
  },
  games: [],
  selectedGames: [],
  headerInfo: {},
  personsMap: {},
  autoFillLineups: false,
  clearLineups: false,
  rosterErrors: "",
  selectedRosterHasError: false,
  otherRosterHasError: false,
  rosterLimits: "",
  playerOverrideRule: "",
  pitcherOverrideRule: "",
  validSelectedLineup: true,
  validOtherLineup: true,
  teamMap: new Map(),
  defaultTeamMap: new Map(),
  rosterEditCount: 0,
  gumboTimecode: "",
  selectedTeamMostRecentGumboRoster: {},
  otherTeamMostRecentGumboRoster: {},
  isAllStarGame: false,
  isSpringTrainingGame: false,
  prevSelectedOrg: {},
  refreshEbisRoster: () => {},
  posCodeOptions: [],
  selectedLineupSubmitted: false,
  otherLineupSubmitted: false,
  selectedLineupDirty: false,
  otherLineupDirty: false,
  selectedLeague: {}
};

const GamesContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case "setSelectedOrg":
      return { ...state, selectedOrg: action.selectedOrg };
    case "setOtherOrg":
      return { ...state, otherOrg: action.org };
    case "setDateRange":
      return { ...state, dates: { start: action.startDate, end: action.endDate }, selectedGames: [] };
    case "setGames":
      return { ...state, games: action.games };
    case "setSelectedGames":
      return { ...state, selectedGames: action.selectedGames };
    case "setPersonsMap":
      return { ...state, personsMap: action.personsMap };
    case "setTeamMap":
      const newTeamMap = new Map(action.teamMap);
      return {
        ...state,
        teamMap: newTeamMap,
        selectedLineupSubmitted: newTeamMap.get(state.selectedOrg.value)
          ? newTeamMap.get(state.selectedOrg.value).lineupSubmitted
          : false,
        otherLineupSubmitted: newTeamMap.get(state.otherOrg.value)
          ? newTeamMap.get(state.otherOrg.value).lineupSubmitted
          : false
      };
    case "setDefaultTeamMap":
      const newDefaultTeamMap = new Map(action.defaultTeamMap);
      return {
        ...state,
        defaultTeamMap: newDefaultTeamMap
      };
    case "setViewableTeam":
      return { ...state, viewableTeam: action.team };
    case "setAutoFillLineups":
      return { ...state, autoFillLineups: action.autoFillLineups };
    case "setClearLineups": {
      if (state.selectedGames && state.teamMap?.get(action.orgId)?.lineup) {
        const clearedTeamMap = new Map(state.teamMap);
        clearedTeamMap.get(action.orgId).lineup = getBlankLineup(state.selectedGames);
        clearedTeamMap.get(action.orgId).validLineup = false;
        return {
          ...state,
          teamMap: clearedTeamMap,
          clearLineups: action.clearLineups,
          lineupPosOptions: [],
          otherLineupPosOptions: []
        };
      }
      return {
        ...state
      };
    }
    case "setRosterErrors":
      return {
        ...state,
        rosterErrors: [...state.rosterErrors, ...action.rosterErrors],
        selectedRosterHasError: action.selectedRosterHasError,
        otherRosterHasError: action.otherRosterHasError
      };
    case "setRosterLimits":
      if (action.rosterLimits) {
        const limits = action.rosterLimits.split("/");
        return {
          ...state,
          rosterLimits: action.rosterLimits,
          playerOverrideRule: limits[0],
          pitcherOverrideRule: limits[1]
        };
      } else {
        return {
          ...state,
          rosterLimits: "",
          playerOverrideRule: "",
          pitcherOverrideRule: ""
        };
      }
    case "resetRosterErrors":
      return { ...state, rosterErrors: [] };
    case "resetRosterEditsCount":
      return {
        ...state,
        rosterEditCount: 0
      };
    case "incrementRosterEditsCount":
      return {
        ...state,
        rosterEditCount: state.rosterEditCount + 1
      };
    case "setGumboTimecode":
      return { ...state, gumboTimecode: action.gumboTimecode };
    case "setSelectedTeamMostRecentGumboRoster":
      return { ...state, selectedTeamMostRecentGumboRoster: action.selectedTeamMostRecentGumboRoster };
    case "setOtherTeamMostRecentGumboRoster":
      return { ...state, otherTeamMostRecentGumboRoster: action.otherTeamMostRecentGumboRoster };
    case "clearMostRecentGumboRosters": {
      return { ...state, selectedTeamMostRecentGumboRoster: {}, otherTeamMostRecentGumboRoster: {} };
    }
    case "setIsAllStarGame": {
      if (action.isAllStarGame) {
        return { ...state, prevSelectedOrg: state.selectedOrg, isAllStarGame: action.isAllStarGame };
      } else {
        return { ...state, isAllStarGame: action.isAllStarGame };
      }
    }
    case "setIsSpringTrainingGame": {
      return { ...state, isSpringTrainingGame: action.isSpringTrainingGame };
    }
    case "setRefreshEbisRosterFn": {
      return { ...state, refreshEbisRoster: action.refreshEbisRoster };
    }
    case "setPosCodeOptions": {
      return { ...state, posCodeOptions: action.posCodeOptions };
    }
    case "setLineupStatus": {
      const selectedOrg = state.selectedOrg;
      if (action.orgId === selectedOrg.value) {
        return { ...state, selectedLineupDirty: action.lineupDirty };
      } else {
        return { ...state, otherLineupDirty: action.lineupDirty };
      }
    }

    // TODO -- THIS NEEDS TO BE MOVED AND NOT IN A CONTEXT - JL
    case "saveLineup": {
      const lineup = {
        gamePk: action.gamePk,
        user: action.user,
        draft: action.draft,
        orgId: action.orgId,
        players: state.teamMap.get(action.orgId)?.lineup.map(player => {
          return { ...player, position: player.position ? player.position : null };
        }),
        ebisRoster: state.teamMap.get(action.orgId)?.ebisRoster,
        gumboTimecode: state.teamMap.get(action.orgId)?.gumboTimecode,
        teamName: state.teamMap.get(action.orgId)?.teamName,
        gameType: action.gameType,
        onSubmit: !!action.onSubmit,
        sportId: state.selectedLeague.sportId
      };
      LineupApi.saveLineup(lineup)
        .then(() => {
          if (action.onSubmit) {
            action?.showAlert("Successfully saved lineup", "success");
          }
        })
        .catch(() => {
          action?.showAlert("Error saving lineup!", "danger");
        });

      const selectedOrg = state.selectedOrg;
      if (action.orgId === selectedOrg.value) {
        return {
          ...state,
          selectedLineupSubmitted: !action.draft,
          selectedLineupDirty: false
        };
      } else {
        return {
          ...state,
          otherLineupSubmitted: !action.draft,
          otherLineupDirty: false
        };
      }
    }
    case "setSelectedLeague": {
      return { ...state, selectedLeague: action.selectedLeague };
    }
    default:
      return { ...state };
  }
};

const GamesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <GamesContext.Provider value={{ state, dispatch }}>{children}</GamesContext.Provider>;
};

// Custom Hooks
const useGames = () => {
  const gamesContext = useContext(GamesContext);
  if (!gamesContext) {
    throw new Error("useGames must be used within a GamesContextProvider");
  }
  return gamesContext;
};

const useActiveOrgKey = () => {
  const gamesContext = useContext(GamesContext);
  const { viewableTeam, selectedOrg, otherOrg } = gamesContext.state;
  if (viewableTeam && selectedOrg && viewableTeam.value === selectedOrg.value) {
    return selectedOrg.value;
  } else if (viewableTeam && otherOrg && viewableTeam.value === otherOrg.value) {
    return otherOrg.value;
  }
};

const useInactiveOrgKey = () => {
  const activeOrgKey = useActiveOrgKey();
  const {
    state: { viewableTeam, selectedOrg, otherOrg }
  } = useGames();
  if (viewableTeam && selectedOrg && otherOrg && selectedOrg.value === activeOrgKey) {
    return otherOrg.value;
  } else if (viewableTeam && selectedOrg && otherOrg && otherOrg.value === activeOrgKey) {
    return selectedOrg.value;
  }
};

GamesContextProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export { GamesContext, GamesContextProvider, useGames, useActiveOrgKey, useInactiveOrgKey };
