import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { PrimaryOutlinedButtonStyled } from "../../elements/PrimaryOutlinedButton";
import styled from "styled-components";
import { useGames } from "../../../contexts/GamesContext";

const MessageContainer = styled.div.attrs(() => ({}))`
  height: 9.325rem;
`;

const ConfirmMessage = styled.div.attrs({ className: "mt-2 mb-3" })`
  font-size: 1.125rem;
  font-weight: normal;
  color: red;
`;

const LineupDifferencesModal = ({ isOpen, onClose }) => {
  const {
    state: { refreshEbisRoster }
  } = useGames();
  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        onClose={() => {
          onClose(false);
        }}
      >
        <ModalHeaderWithTitleAndClose
          title="EBIS Roster check"
          onClick={() => {
            onClose(false);
          }}
          close={() => {
            onClose(false);
          }}
        />
        <ModalBody>
          <MessageContainer>
            <ConfirmMessage>
              There have been recent updates to the EBIS roster please check your lineup and try again!
            </ConfirmMessage>
          </MessageContainer>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <PrimaryOutlinedButtonStyled
              width={100}
              height={26}
              onClick={() => {
                onClose(false);
                refreshEbisRoster();
              }}
            >
              Dismiss
            </PrimaryOutlinedButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LineupDifferencesModal;
