import React from "react";
import styled from "styled-components";
import GamesFooter from "./GamesFooter";
import SelectGameSection from "./SelectGameSection";
import SettingsSection from "./settings/SettingsSection";
import { CardBody } from "best-common-react";
import SubSectionHeader from "../elements/SubSectionHeader";
import { useGames } from "../../contexts/GamesContext";
import { SettingsContextProvider } from "../../contexts/SettingsContext";
import SectionHolder from "./lineup/SectionHolder";
import LineupHeader from "./lineup/LineupHeader";

const Container = styled.div.attrs(() => ({
  className: "d-flex"
}))``;

const FormHolder = styled.div.attrs(() => ({
  className: "mt-1"
}))`
  width: 100%;
`;

const SectionDivider = styled.hr.attrs(() => ({
  className: "mb-3 mt-3 mr-3 ml-3"
}))`
  height: 1px;
  width: 100%;
  color: #dee5ed;
`;

const Games = () => {
  const {
    state: { selectedGames }
  } = useGames();
  return (
    <Container>
      <FormHolder>
        <SettingsContextProvider>
          <div className="d-flex">
            <div className="col-9">
              <SubSectionHeader title="Select a Game" />
              <CardBody>
                <SelectGameSection />
              </CardBody>
            </div>
            <div className="col-3">
              <SubSectionHeader title="Settings" />
              <CardBody>
                <SettingsSection />
              </CardBody>
            </div>
          </div>
          <SectionDivider />
          <div className="row">
            <div className="col">
              {selectedGames.length === 1 ? (
                <div>
                  <LineupHeader />
                  <SectionHolder />
                </div>
              ) : (
                "Please select only one game to set lineup"
              )}
            </div>
          </div>
          <GamesFooter />
        </SettingsContextProvider>
      </FormHolder>
    </Container>
  );
};

export default Games;
