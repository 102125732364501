import React from "react";
import SubSectionHeader from "../elements/SubSectionHeader";
import DefaultLineupHolder from "./DefaultLineupHolder";

const DefaultLineup = ({ lefty, lineup, playerOptions, posOptions, handlePlayerSwap, handlePositionSwap }) => {
  const title = lefty ? "Lefty" : "Righty";
  return (
    <div>
      <SubSectionHeader title={"Default Lineup vs. " + title} />
      <DefaultLineupHolder
        lineup={lineup}
        playerOptions={playerOptions}
        posOptions={posOptions}
        lefty={lefty}
        handlePlayerSwap={handlePlayerSwap}
        handlePositionSwap={handlePositionSwap}
      />
    </div>
  );
};

export default DefaultLineup;
