import styled from "styled-components";

const DisabledButton = styled.button.attrs(() => ({
  name: "disabled-button"
}))`
  border: 1px solid;
  cursor: ${props => (props.disabled ? "disabled" : "pointer")};
  ${props => (props.disabled ? "background-color: #f2f3f4 !important;" : "")}
  ${props => (props.disabled ? `color: ${props.theme.grey} !important;` : "")}
  ${props => (props.disabled ? "border-color: #c9c9c9 !important;" : "")}
`;

const PrimaryOutlineButton = styled(DisabledButton).attrs(() => ({
  className: "btn btn-outline-primary",
  name: "primary-outline-button"
}))``;

export const PrimaryOutlinedButtonStyled = styled(PrimaryOutlineButton).attrs(() => ({
  className: "justify-content-center d-flex align-items-center",
  type: "submit"
}))`
  &&& {
    width: ${props => `${props.width}px`} !important;
    height: ${props => `${props.height}px`} !important;
    border-radius: 6px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #ffffff;
    border-color: #4c8cee;
    color: #4c8cee;

    /* firefox only */
    @supports (-moz-appearance: none) {
      line-height: ${props => `${props.height - 2}px`};
    }
  }
`;
