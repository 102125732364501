import Moment from "moment";

export const formatDate = (date, format = "MM/DD/YYYY") => {
  if (date && isTBDDate(date)) {
    date = addToDate(date, 6, "hour");
  }

  const m = Moment.isMoment(date) ? date : Moment(date);

  if (format.includes("z")) {
    return Moment(date)
      .tz(Moment.tz.guess())
      .format(format);
  }

  return m.format(format);
};

export const isTBDDate = date => {
  const converted = Moment(date).utc();
  return converted.hour() === 3 && converted.minutes() === 33;
};

export const getUTCdate = date => {
  return Moment(date)
    .utc()
    .valueOf();
};

export const addToDate = (date, amt, unit) => {
  return Moment(date).add(amt, unit);
};

export const subtractFromDate = (date, amt, unit) => {
  return Moment(date).subtract(amt, unit);
};

export const handleDateRangeOnChange = (value, setDateFn) => {
  setDateFn({
    start: value.start,
    end: getUTCdate(value.start) > value.end ? addToDate(value.start, "weeks", 1).valueOf() : value.end
  });
};

export const DateFormats = {
  SHORT_DATETIME_WITH_TIMEZONE: "ddd M/D - hh:mma z",
  MONTH_NAME_DAY_YEAR: "MMMM DD, YYYY",
  DATETIME: "YYYY-MM-DD"
};

export const withinDateRange = (startDate, endDate, targetDate) => {};

export const sortGameDates = games => {
  return games.sort((gameA, gameB) => {
    if (gameA.gameNumber === 2) {
      return 1;
    }
    return new Date(gameA.gameDate) - new Date(gameB.gameDate);
  });
};

export const isValidDate = date => {
  return Moment(date).isValid();
};

export const isAfterSept1 = date => {
  const currentYear = Moment().year();
  const septemberFirst = Moment(`${currentYear}-09-01`);
  return Moment(date).isAfter(septemberFirst);
};
