import React from "react";
import styled from "styled-components";
import LogoFilesComponent from "./LogoFilesComponent";
import LogoSettings from "./LogoSettings";

const LogoSectionStyle = styled.div.attrs(() => ({
  className: "ml-2 mt-2"
}))``;

const LogosSection = () => {
  return (
    <LogoSectionStyle>
      <LogoSettings />
      <LogoFilesComponent />
    </LogoSectionStyle>
  );
};

export default LogosSection;
