import React, { useState, useEffect } from "react";
import { DataTable } from "best-common-react";
import { useGames } from "../../contexts/GamesContext";
import { formatDate, DateFormats, isTBDDate, addToDate } from "../../utils/DateUtils";
import Matchup from "../elements/Matchup";
import { useAuth } from "../../contexts/AuthContext";
import RosterSettingsApi from "../../httpClients/RosterSettingsApi";
import { PrimaryOutlinedButtonStyled } from "../elements/PrimaryOutlinedButton";
import AuditApi from "../../httpClients/AuditApi";
import FileHelper from "../../helper/FileHelper";
import { useAlert } from "../alerts/AlertsContext";
import { trackPromise } from "react-promise-tracker";
import LineupCardVersionsModal from "./modal/LineupCardVersionsModal";
import styled from "styled-components";

const GamePkStyle = styled.div.attrs()`
  color: #007bff;
  cursor: pointer;
`;

const GameDateFormatter = ({ value }) => {
  if (value && isTBDDate(value)) {
    const offsetDate = addToDate(value, 6, "hour");
    const formattedDate = formatDate(offsetDate, "ddd M/D");
    const tbdString = " - TBD";
    return (
      <div className="d-flex">
        <div>{formattedDate}</div> <div className="ml-1">{tbdString}</div>
      </div>
    );
  }

  return <div>{value ? formatDate(value, DateFormats.SHORT_DATETIME_WITH_TIMEZONE) : null}</div>;
};

const MatchUpFormatter = ({ value }) => {
  return <Matchup away={value.away.team} home={value.home.team} />;
};

const VenueFormatter = ({ value }) => {
  return <div>{value ? value.name : null}</div>;
};

const DoubleHeaderFormatter = ({ row }) => {
  if (row && (row.doubleHeader === "S" || row.doubleHeader === "Y") && !row.resumedFrom) {
    return <div>{row.gameNumber} of 2</div>;
  } else if (row && row.resumedFrom) {
    return <div>Susp.</div>;
  }
  return <div />;
};

const RosterLimitFormatter = ({ row }) => {
  return (
    <>
      {row.isAdmin && row.hasRosterLimit ? (
        <div>
          {row.activeRosterCap.rosterCap === 0 || row.activeRosterCap.rosterCap === 99
            ? "Unlimited"
            : row.activeRosterCap.rosterCap}
          /
          {row.activeRosterCap.pitcherCap === 0 || row.activeRosterCap.pitcherCap === 99
            ? "Unlimited"
            : row.activeRosterCap.pitcherCap}
        </div>
      ) : null}
    </>
  );
};

const GamePkFormatter = ({ row }) => {
  const { selectedOrg } = useGames();
  const versionUrls = row.teams?.team?.name === selectedOrg?.name ? row.homeStorageUrls : row.awayStorageUrls;
  return (
    <>
      {versionUrls && versionUrls.length > 0 ? (
        <GamePkStyle
          onClick={() => {
            row.setVersionsUrls(versionUrls);
            row.setVersionsModalOpen(true);
          }}
        >
          {row.gamePk}
        </GamePkStyle>
      ) : (
        <div>{row.gamePk}</div>
      )}
    </>
  );
};

const PrintAuditFormatter = ({ row }) => {
  const showAlert = useAlert();
  return (
    <>
      {row.isAdmin && row.hasAuditTrail ? (
        <PrimaryOutlinedButtonStyled
          height={26}
          width={150}
          onClick={() => {
            trackPromise(
              AuditApi.printAuditByGamePk(row.gamePk).then(response => {
                if (response && response.data.size > 0) {
                  FileHelper.downloadFile(response, "pdf");
                } else {
                  showAlert("This game has no audit trail yet. Nothing to be printed.", "danger");
                }
              })
            );
          }}
        >
          Download Audit Trail
        </PrimaryOutlinedButtonStyled>
      ) : null}
    </>
  );
};

const GamesTable = ({ loadingGames, setLoadingGames }) => {
  const {
    state: { games, selectedGames, rosterLimits, isAllStarGame, prevSelectedOrg },
    dispatch: gamesDispatch
  } = useGames();

  const {
    state: { isAdmin }
  } = useAuth();

  const [data, setData] = useState([...games]);

  const [versionsModalOpen, setVersionsModalOpen] = useState(false);
  const [versionsUrls, setVersionsUrls] = useState([]);

  useEffect(() => {
    if (games.length > 0) {
      AuditApi.getAuditInfoForGames(games).then(auditResponseGames => {
        if (auditResponseGames && auditResponseGames.length > 0) {
          const gameRosterSettingsList = auditResponseGames.map(game => {
            return {
              game: game,
              rosterSettings: null
            };
          });
          RosterSettingsApi.getActiveRosterSettingsByGameDateList(gameRosterSettingsList)
            .then(response => {
              if (response) {
                auditResponseGames.forEach(game => {
                  const gameWsettings = response.find(
                    g =>
                      g.game.gamePk.toString() === game.gamePk &&
                      g.game.gameDate === game.gameDate &&
                      g.activeRosterCap !== null
                  );
                  if (gameWsettings) {
                    game.hasRosterLimit = true;
                    game.activeRosterCap = gameWsettings.activeRosterCap;
                  }
                });
                // Set selected games again and isAdmin
                auditResponseGames.forEach(game => {
                  game.isAdmin = isAdmin;
                  const selectedGame = selectedGames.find(selectedGame => selectedGame.gamePk === game.gamePk);
                  if (selectedGame) {
                    game.isSelected = true;
                  }
                });
                auditResponseGames.forEach(game => {
                  game.setVersionsModalOpen = setVersionsModalOpen;
                  game.setVersionsUrls = setVersionsUrls;
                });
                setData([...auditResponseGames]);
                gamesDispatch({ type: "setSelectedGames", selectedGames: [...selectedGames] });
                gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
                gamesDispatch({ type: "setClearLineups", clearLineups: true });
                gamesDispatch({
                  type: "resetRosterErrors"
                });
                setLoadingGames(false);
              }
            })
            .catch(() => {});
        }
      });
    } else {
      setData([]);
      gamesDispatch({ type: "setSelectedGames", selectedGames: [...selectedGames] });
      gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
      gamesDispatch({ type: "setClearLineups", clearLineups: true });
      gamesDispatch({
        type: "resetRosterErrors"
      });
      setLoadingGames(false);
    }
  }, [games, rosterLimits]);

  const handleRowSelection = indexes => {
    const currentSelectedGames = [];
    indexes.forEach(index => {
      const foundGame = data.find(
        g =>
          g.gamePk === index.row.gamePk &&
          g.officialDate === index.row.officialDate &&
          g.gameNumber === index.row.gameNumber
      );
      if (foundGame) {
        foundGame.isSelected = true;
        currentSelectedGames.push(foundGame);
      }
    });
    gamesDispatch({ type: "setSelectedGames", selectedGames: [...selectedGames, ...currentSelectedGames] });
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
    gamesDispatch({ type: "setRosterLimits", rosterLimits: null });
    gamesDispatch({
      type: "resetRosterErrors"
    });
  };

  const handleRowDeselection = indexes => {
    const unselectedGames = [];
    indexes.forEach(index => {
      index.row.isSelected = false;
      unselectedGames.push(index.row.gamePk);
    });
    const currentSelectedGames = selectedGames.filter(game => {
      if (!unselectedGames.find(g => g === game.gamePk)) {
        return game;
      }
    });
    gamesDispatch({ type: "setSelectedGames", selectedGames: [...currentSelectedGames] });
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
  };

  const GamesColumns = [
    {
      key: "gameDate",
      name: "",
      width: 200,
      formatter: GameDateFormatter
    },
    {
      key: "teams",
      name: "",
      width: 175,
      formatter: MatchUpFormatter
    },
    {
      key: "venue",
      name: "",
      width: 200,
      formatter: VenueFormatter
    },
    {
      key: "",
      name: "",
      formatter: DoubleHeaderFormatter
    }
  ];

  const columns = isAdmin
    ? [
        ...GamesColumns,
        { key: "gamePk", name: "", formatter: GamePkFormatter },
        {
          key: "",
          name: isAdmin ? "Max Players/Pitchers" : "",
          formatter: RosterLimitFormatter
        },
        {
          key: "",
          name: "",
          formatter: isAdmin ? PrintAuditFormatter : null
        }
      ]
    : GamesColumns;

  return (
    <div>
      {!loadingGames && data.length === 0 ? (
        <div className="m-3" style={{ fontSize: "20px", fontWeight: "bold" }}>
          * No games founds for give date range.
        </div>
      ) : (
        <>
          <LineupCardVersionsModal isOpen={versionsModalOpen} onClose={setVersionsModalOpen} urls={versionsUrls} />
          <DataTable
            columns={columns}
            data={data}
            height={150}
            rowHeight={35}
            minColumnWidth={100}
            rowSelection={{
              showCheckbox: true,
              onRowsSelected: indexes => {
                handleRowSelection(indexes);
              },
              onRowsDeselected: indexes => {
                handleRowDeselection(indexes);
              },
              selectBy: {
                isSelectedKey: "isSelected"
              },
              enableShiftSelect: true
            }}
          />
        </>
      )}
    </div>
  );
};

export default GamesTable;
