import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext";
import { useGames } from "../../contexts/GamesContext";
import DefaultLineupApi from "../../httpClients/DefaultLineupApi";
import { useAlert } from "../alerts/AlertsContext";
import { createLineupRowObj, isEmptyLineup } from "../../utils/LineupUtils";

const SaveAsButtonStyle = styled.div.attrs(() => ({}))`
  color: #4c8cee;
  cursor: pointer;
  font-size: 14px;
`;

const SaveDefaultButtonWrapper = ({ setAutofillLeftDisabled, setAutofillRightDisabled }) => {
  const [show, setShow] = useState(true);

  const showAlert = useAlert();

  const {
    state: { club, isUser, email }
  } = useAuth();

  const {
    state: { viewableTeam, selectedOrg, otherOrg, teamMap },
    dispatch: gamesDispatch
  } = useGames();

  useEffect(() => {
    if (isUser && viewableTeam.name && viewableTeam.name !== club) {
      setShow(false);
    }
  }, [selectedOrg, otherOrg, viewableTeam, club, isUser]);

  const saveAsOnClick = lefty => {
    // Do not save the pitcher in the default lineup if there is one
    const lineup = [...teamMap.get(viewableTeam?.value).lineup];
    const pitcherIndex = lineup.findIndex(player => player.position === "1");
    if (pitcherIndex > -1) {
      // if pitcher is at index 9 aka. there is a dh and they are in the P slot, remove them from lineup
      if (pitcherIndex === 9) {
        lineup.pop();
      } else {
        // add blank player with no position to batting order spot
        lineup.splice(pitcherIndex, 1, createLineupRowObj(lineup[pitcherIndex].battingOrder, null, null));
      }
    }
    const defaultLineupDTO = {
      orgId: viewableTeam?.value,
      lineup: lineup,
      lefty: lefty,
      user: email
    };
    DefaultLineupApi.saveDefaultLineup(defaultLineupDTO)
      .then(() => {
        showAlert("Successfully saved default lineup", "success");
        gamesDispatch({ type: "setTeamMap", teamMap: teamMap });
        if (lefty) {
          setAutofillLeftDisabled(false);
        } else {
          setAutofillRightDisabled(false);
        }
      })
      .catch(() => {
        showAlert("Error saving default lineup.", "danger");
      });
  };

  return (
    <>
      {show && !isEmptyLineup(teamMap?.get(viewableTeam?.value)?.lineup) && (
        <div className="d-flex mt-3">
          <SaveAsButtonStyle
            className="mr-5"
            onClick={() => {
              saveAsOnClick(false);
            }}
          >
            Save as Default vs. Righty
          </SaveAsButtonStyle>
          <SaveAsButtonStyle
            onClick={() => {
              saveAsOnClick(true);
            }}
          >
            Save as Default vs. Lefty
          </SaveAsButtonStyle>
        </div>
      )}
    </>
  );
};

export default SaveDefaultButtonWrapper;
