import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DataTable, DateRangePickerInput, BestSelect, BestLabel, Icon } from "best-common-react";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { formatDate, handleDateRangeOnChange } from "../../../utils/DateUtils";
import LogoDeleteModal from "./LogoDeleteModal";

const HoverIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const LogoDeleteFormatter = ({ row }) => {
  const deleteLogo = () => {
    row.setLogoToDelete(row);
    row.modalFn(true);
  };

  return <HoverIcon iconName="fa-trash-alt" onClick={deleteLogo} />;
};

const Columns = [
  {
    key: "logoRequestId",
    name: "",
    formatter: LogoDeleteFormatter,
    locked: true,
    width: 35
  },
  {
    key: "type",
    name: "Type"
  },
  {
    key: "subType",
    name: "GameType"
  },
  {
    key: "team",
    name: "Team"
  },
  {
    key: "gameDate",
    name: "Game Date"
  },
  {
    key: "game",
    name: "Game"
  },
  {
    key: "fileName",
    name: "File Name"
  }
];

const ClubHolder = styled.div`
  width: 20rem;
`;

const LogoManager = () => {
  const [clubOptions, setClubOptions] = useState([]);
  const [clubInfo, setClubInfo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [clubId, setClubId] = useState(null);
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [logoToDelete, setLogoToDelete] = useState({});

  useEffect(() => {
    LineupCardApi.getClubInfo("MLB")
      .then(response => {
        response.push({ name: "All Clubs", id: null });
        setClubInfo(response);
      })
      .catch(() => {
        // Error
      });
  }, []);

  useEffect(() => {
    setClubOptions(clubInfo.sort((a, b) => (a.name > b.name ? 1 : -1)).map(ci => ({ label: ci.name, value: ci })));
  }, [clubInfo]);

  useEffect(() => {
    getLogos();
  }, [clubId, dateRange.start, dateRange.end]);

  const getLogos = () => {
    const start = formatDate(dateRange.start);
    const end = formatDate(dateRange.end);
    LineupCardApi.getLogos(clubId, start, end).then(data => {
      if (data.entity.length > 0) {
        data.entity.forEach(logoRequest => {
          logoRequest.modalFn = setDeleteModalStatus;
          logoRequest.setLogoToDelete = setLogoToDelete;
        });
      }
      setTableData(data.entity);
    });
  };

  return (
    <div>
      <LogoDeleteModal
        isOpen={deleteModalStatus}
        logoToDelete={logoToDelete}
        onClose={setDeleteModalStatus}
        refreshLogoList={getLogos}
      />
      <div className="d-flex">
        <div className="mr-2">
          <BestLabel>Date Range</BestLabel>
          <DateRangePickerInput value={dateRange} onChange={value => handleDateRangeOnChange(value, setDateRange)} />
        </div>
        <ClubHolder>
          <BestLabel>Club</BestLabel>
          <BestSelect
            options={clubOptions}
            onChange={value => setClubId(value.value.id)}
            placeholder="Team"
            value={clubOptions.find(club => club.value.id === clubId)}
          />
        </ClubHolder>
      </div>
      <div className="mt-3">
        <DataTable data={tableData} columns={Columns} />
      </div>
    </div>
  );
};

export default LogoManager;
