export const handleLineupSwap = (originalPlayer, newPlayer, teamMap, gamesDispatch, activeOrgKey) => {
  if (activeOrgKey && teamMap && teamMap.get(activeOrgKey)) {
    const team = teamMap.get(activeOrgKey);
    const lineup = team.lineup;
    const pitchers = team.pitchers;
    const availablePlayers = team.availablePlayers;
    const lineupIndex = lineup.findIndex(lineupRow => lineupRow.battingOrder === originalPlayer.battingOrder);

    if (newPlayer && lineupIndex > -1) {
      if (originalPlayer.position !== "1" || isPitcher(newPlayer) || newPlayer.twp) {
        // add new player to lineup
        newPlayer.battingOrder = originalPlayer.battingOrder;
        newPlayer.position = originalPlayer.position;
        lineup[lineupIndex] = newPlayer;
      }
      if (isPitcher(newPlayer) || newPlayer.twp) {
        // remove new player from available pitchers
        const availablePitcherIndex = pitchers.indexOf(newPlayer);
        if (!originalPlayer) {
          pitchers.splice(availablePitcherIndex, 1);
        } else if (!isPitcher(originalPlayer) && !originalPlayer.twp && originalPlayer.lastName) {
          availablePlayers.push(originalPlayer);
          pitchers.splice(availablePitcherIndex, 1);
        } else {
          if (!originalPlayer.lastName) {
            pitchers.splice(availablePitcherIndex, 1);
          } else {
            pitchers[availablePitcherIndex] = originalPlayer;
          }
        }
      } else if (originalPlayer.position !== "1") {
        // remove new player from available players
        const availableIndex = availablePlayers.indexOf(newPlayer);
        if (!originalPlayer) {
          availablePlayers.splice(availableIndex, 1);
        } else if (isPitcher(originalPlayer)) {
          pitchers.push(originalPlayer);
          availablePlayers.splice(availableIndex, 1);
        } else {
          if (!originalPlayer.lastName) {
            availablePlayers.splice(availableIndex, 1);
          } else {
            availablePlayers[availableIndex] = originalPlayer;
          }
        }
      }
    } else {
      clearPlayerFromLineup(lineup, lineupIndex, availablePlayers, originalPlayer, pitchers);
    }

    availablePlayers.sort((a, b) => a.lastName.localeCompare(b.lastName));
    teamMap.set(activeOrgKey, {
      ...team,
      lineup: [...lineup],
      availablePlayers: [...availablePlayers],
      pitchers: [...configurePitchers(pitchers)],
      posCodeOptions: team.posCodeOptions,
      validLineup: isLineupValid(lineup),
      orgName: team.orgName,
      roster: team.roster,
      gumboTimecode: team.gumboTimecode
    });

    gamesDispatch({ type: "setTeamMap", teamMap: teamMap });
  }
};

export const handleDefaultLineupSwap = (
  originalPlayer,
  newPlayer,
  defaultTeamMap,
  activeOrgKey,
  gamesDispatch,
  lefty
) => {
  if (activeOrgKey && defaultTeamMap && defaultTeamMap.get(activeOrgKey)) {
    const team = defaultTeamMap.get(activeOrgKey);
    const leftyPlayers = team.leftyPlayerOptions;
    const rightyPlayers = team.rightyPlayerOptions;
    const leftyLineup = team.leftyLineup;
    const rightyLineup = team.rightyLineup;

    const swapPlayers = (availablePlayers, lineup) => {
      // set lineupIdx of row we are editing
      const lineupIndex = lineup.findIndex(lineupRow => lineupRow.battingOrder === originalPlayer.battingOrder);
      if (newPlayer && newPlayer.value) {
        const newLineupObj = createLineupRowObj(originalPlayer.battingOrder, newPlayer.value, originalPlayer.position);
        // if lineupIdx doesn't exist, do nothing
        if (lineupIndex > -1) {
          // update lineup to include newPlayer
          // add a safety check to make sure newPlayer isnt already in lineup, should never happen
          const newPlayerExistsInLineup =
            lineup.findIndex(lineupRow => lineupRow.player?.lastName == newLineupObj.player?.lastName) !== -1;
          if (!newPlayerExistsInLineup) {
            lineup[lineupIndex] = newLineupObj;
          }
          // logic to remove player from avilable players
          const availableIndex = availablePlayers.indexOf(newLineupObj.player);
          // if player field is null then swapping with empty spot so just remove player from available players
          if (!originalPlayer?.player?.lastName) {
            availableIndex > -1 && availablePlayers.splice(availableIndex, 1);
          } else {
            // add a safety check to make sure original player isnt already available, should never happen
            if (availablePlayers.indexOf(originalPlayer.player) === -1) {
              availablePlayers[availableIndex] = originalPlayer.player;
            }
          }
        }
      } else {
        // clear logic
        //set lineup index to empty player
        lineup[lineupIndex] = createLineupRowObj(originalPlayer.battingOrder, null, originalPlayer.position);
        // add player back to avilable players
        availablePlayers.push(originalPlayer.player);
      }
      // sort available players so player is in correct position
      availablePlayers.sort((a, b) => a.lastName.localeCompare(b.lastName));
    };

    if (lefty) {
      swapPlayers(leftyPlayers, leftyLineup);
    } else {
      swapPlayers(rightyPlayers, rightyLineup);
    }
    defaultTeamMap.set(activeOrgKey, {
      ...team,
      leftyPlayerOptions: leftyPlayers,
      rightyPlayerOptions: rightyPlayers,
      leftyLineup: leftyLineup,
      rightyLineup: rightyLineup
    });
    gamesDispatch({ type: "setDefaultTeamMap", defaultTeamMap: defaultTeamMap });
  }
};

export const handlePositionChange = (origPosPlayer, newPos, teamMap, gamesDispatch, activeOrgKey) => {
  if (activeOrgKey && teamMap && teamMap.get(activeOrgKey)) {
    const team = teamMap.get(activeOrgKey);
    const lineup = team.lineup;
    const positionCodes = team.posCodeOptions;
    const origPosition = origPosPlayer?.position;
    const lineupIndex = lineup.findIndex(lineupRow => lineupRow.battingOrder === origPosPlayer.battingOrder);
    if (newPos && newPos.value) {
      // if lineupIdx doesn't exist do nothing
      if (lineupIndex > -1) {
        const posIndex = positionCodes.indexOf(newPos.value);
        // remove selected position from options
        posIndex > -1 && positionCodes.splice(posIndex, 1);

        // add original position back to the position options if an original position exists, and is not already in the list
        if (origPosition && origPosition !== "" && positionCodes.indexOf(origPosition) === -1) {
          positionCodes.push(origPosition);
        }
        //update lineup position
        lineup[lineupIndex].position = newPos.value;

        // TWO WAY PLAYER LISTING TWICE BS
        // if original player is a twp and not setting him at position 1 (non-DH game)
        if (origPosPlayer && origPosPlayer.twp && newPos.value !== "1") {
          // if there is a two way player in the pitchers spot, and the person is trying to set position of another twp in the lineup
          // to something other than 10(DH) remove this twp from lineup
          const pitcherIsTWP = lineup.find(p => p.position === "1" && p.id === origPosPlayer.id);
          if (pitcherIsTWP && newPos.value !== "10") {
            clearPlayerFromLineup(
              lineup,
              lineupIndex,
              teamMap.get(activeOrgKey).availablePlayers,
              origPosPlayer,
              teamMap.get(activeOrgKey).pitchers
            );
          }
        }
        // if position being switch to is 1/pitcher then check if the player isn't/wasn't a pitcher; then clear him
        if (newPos.value === "1") {
          if (origPosPlayer && origPosPlayer.lastName && !isPitcher(origPosPlayer) && !origPosPlayer.twp) {
            clearPlayerFromLineup(
              lineup,
              lineupIndex,
              teamMap.get(activeOrgKey).availablePlayers,
              origPosPlayer,
              teamMap.get(activeOrgKey).pitchers
            );
          }
        }
      }
    } else {
      // clear option
      // if no new position, check if original Pos is in options and if not add it
      if (origPosition && origPosition !== "" && positionCodes.indexOf(origPosition) === -1) {
        positionCodes.push(origPosition);
      }
      // set lineup position to null
      lineup[lineupIndex].position = null;
    }

    teamMap.get(activeOrgKey).lineup = [...lineup];
    teamMap.get(activeOrgKey).validLineup = isLineupValid(lineup);
    teamMap.get(activeOrgKey).posCodeOptions = positionCodes.sort((a, b) => a - b);

    gamesDispatch({ type: "setTeamMap", teamMap: teamMap });
  }
};

export const handleDefaultPositionChange = (
  originalPos,
  newPos,
  defaultTeamMap,
  activeOrgKey,
  gamesDispatch,
  lefty
) => {
  if (activeOrgKey && defaultTeamMap && defaultTeamMap.get(activeOrgKey)) {
    const team = defaultTeamMap.get(activeOrgKey);
    const leftyPositions = team.leftyPosCodeOptions;
    const rightyPositions = team.rightyPosCodeOptions;
    const leftyLineup = team.leftyLineup;
    const rightyLineup = team.rightyLineup;
    const swapPositions = (positions, lineup) => {
      // set lineupIdx of row we are editing
      const lineupIndex = lineup.findIndex(lineupRow => lineupRow.battingOrder === originalPos.battingOrder);
      if (newPos && newPos.value) {
        // if lineupIdx doesn't exist do nothing
        if (lineupIndex > -1) {
          const posIndex = positions.indexOf(newPos.value);
          // remove selected position from options
          posIndex > -1 && positions.splice(posIndex, 1);

          // add original position back to the position options if an original position exists
          // originalPos structure { battingOrder: int, player: [] || obj, position: int || null}
          if (originalPos.position && originalPos.position !== "" && positions.indexOf(originalPos.position) === -1) {
            positions.push(originalPos.position);
          }
          //update lineup position
          lineup[lineupIndex].position = newPos.value;
        }
      } else {
        // if no new position, check if original Pos is in options and if not add it
        if (originalPos.position && positions.indexOf(originalPos.position) === -1) {
          positions.push(originalPos.position);
        }
        // set lineup position to null
        lineup[lineupIndex].position = null;
      }
      // sort positions so they are in the right order
      positions.sort((a, b) => a - b);
    };

    if (lefty) {
      swapPositions(leftyPositions, leftyLineup);
    } else {
      swapPositions(rightyPositions, rightyLineup);
    }
    // update default team map to reflect new values
    defaultTeamMap.set(activeOrgKey, {
      ...team,
      leftyPosCodeOptions: leftyPositions,
      rightyPosCodeOptions: rightyPositions,
      leftyLineup: leftyLineup,
      rightyLineup: rightyLineup
    });
    gamesDispatch({ type: "setDefaultTeamMap", defaultTeamMap: defaultTeamMap });
  }
};

const isPitcher = player => {
  return player.primaryPosition
    ? player.primaryPosition === "LHS" ||
        player.primaryPosition === "LHR" ||
        player.primaryPosition === "RHS" ||
        player.primaryPosition === "RHR" ||
        player.primaryPosition === "P" // statsapi
    : false;
};

const clearPlayerFromLineup = (lineup, lineupIndex, availablePlayers, originalPlayer, pitchers) => {
  lineup[lineupIndex] = {
    battingOrder: originalPlayer.battingOrder,
    position: originalPlayer.position,
    player: null // doesn't match current structure
  };
  // if its a two way player
  if (originalPlayer.twp) {
    // if twp is still in the lineup after clearing don't put
    // them back in pitchers section untill completely gone from the lineup
    if (lineup.findIndex(lineupPlayer => lineupPlayer.id === originalPlayer.id) < 0) {
      // add twp to pitchers section now that there is no other instance in the lineup
      pitchers.push(originalPlayer);
      configurePitchers(pitchers);
    }
  } else if (isPitcher(originalPlayer)) {
    // doesn't sort by name
    pitchers.push(originalPlayer);
    configurePitchers(pitchers);
  } else {
    availablePlayers.push(originalPlayer);
    // sort players
    availablePlayers.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }
};

export const checkLineupValidity = lineup => {
  let result = true;
  if (lineup) {
    lineup.forEach(player => {
      if (!player.person || player.person.fullName === "" || !player.allPositions[0]) {
        result = false;
      }
    });
  } else {
    result = false;
  }

  return result;
};

export const getBlankLineup = selectedGames => {
  // Universal DH to add P to all Blank lineups
  if (selectedGames) {
    const emptyLineup = [];
    for (let i = 1; i <= 9; i++) {
      emptyLineup.push({ battingOrder: i, player: null, position: null });
    }
    emptyLineup.push({ battingOrder: "P", player: null, position: "1" });
    return emptyLineup;
  }
};

export const getEmptyDefaultLineup = () => {
  const emptyLineup = [];
  for (let i = 1; i <= 9; i++) {
    emptyLineup.push({ battingOrder: i, player: null, position: null });
  }

  return emptyLineup;
};

export const setDefaultLineupPos = (playerPosCodesFromApi, lineup) => {
  // remove pitcher index for default lineups
  const pitcherIndex = playerPosCodesFromApi.indexOf("1");
  pitcherIndex > -1 && playerPosCodesFromApi.splice(pitcherIndex, 1);

  lineup.forEach(row => {
    const availableIndex = playerPosCodesFromApi.findIndex(posCode => posCode === row.position);
    if (availableIndex > -1) {
      playerPosCodesFromApi.splice(availableIndex, 1);
    }
  });

  return playerPosCodesFromApi;
};

export const fillInLineup = (lineup, hasDH) => {
  for (let i = 0; i < 9; i++) {
    // if autofill lineup has DH but game doesnt
    if (!hasDH && lineup[i]?.position == "10") {
      // set player whose position was DH to null
      lineup[i].position = null;
    }
    if (!lineup[i]) {
      lineup.push({ battingOrder: i + 1, position: null });
    }
  }

  // if the team map is supposed to have DH and the autofill lineup does not already include it, add it
  if (hasDH && lineup.length === 9) {
    lineup.push({ battingOrder: "P", position: "1" });
  }

  return lineup;
};

export const clearLineupFromTeamMap = (teamMap, gamesDispatch, orgKey) => {
  teamMap?.get(orgKey)?.lineup?.forEach(player => {
    if (player?.lastName) {
      handleLineupSwap(player, null, teamMap, gamesDispatch, orgKey);
    }
    if (player?.position) {
      handlePositionChange(player, null, teamMap, gamesDispatch, orgKey);
    }
  });
};

// TODO - could refactor this to use function above
export const clearDefaultLineups = (defaultTeamMap, gamesDispatch, orgKey) => {
  defaultTeamMap?.get(orgKey)?.leftyLineup?.forEach(row => {
    if (row.player?.lastName) {
      handleDefaultLineupSwap(row, null, defaultTeamMap, orgKey, gamesDispatch, true);
    }
    if (row.position) {
      handleDefaultPositionChange(row, null, defaultTeamMap, orgKey, gamesDispatch, true);
    }
  });
  defaultTeamMap?.get(orgKey)?.rightyLineup?.forEach(row => {
    if (row.player?.lastName) {
      handleDefaultLineupSwap(row, null, defaultTeamMap, orgKey, gamesDispatch, false);
    }
    if (row.position) {
      handleDefaultPositionChange(row, null, defaultTeamMap, orgKey, gamesDispatch, false);
    }
  });
};

export const removeLineupPlayersAndPositions = (lineup, teamMap, orgKey) => {
  lineup?.forEach(player => {
    // remove from available players
    const availableIndex = teamMap?.get(orgKey)?.availablePlayers.findIndex(aPlayer => aPlayer.id === player.id);
    if (availableIndex > -1) {
      teamMap?.get(orgKey)?.availablePlayers.splice(availableIndex, 1);
    }
    // remove fro available positions
    const posIndex = teamMap?.get(orgKey)?.posCodeOptions.findIndex(pos => pos === player.position);
    if (posIndex > -1) {
      teamMap?.get(orgKey)?.posCodeOptions.splice(posIndex, 1);
    }
    // remove from available pitchers
    const pitcherIndex = teamMap?.get(orgKey)?.pitchers.findIndex(aPlayer => aPlayer.id === player.id);
    if (pitcherIndex > -1) {
      teamMap?.get(orgKey)?.pitchers.splice(pitcherIndex, 1);
    }
  });
};

export const removeDefaultLineupPlayers = (lineup, availablePlayers) => {
  lineup.forEach(row => {
    const availableIndex = availablePlayers.findIndex(aPlayer => aPlayer.id === row.player?.id);
    if (availableIndex > -1) {
      availablePlayers.splice(availableIndex, 1);
    }
  });
  return availablePlayers;
};

export const isLineupValid = lineup => {
  let result = true;
  if (lineup) {
    lineup.forEach(player => {
      if (!player.lastName || !player.position) {
        result = false;
      }
    });
  } else {
    result = false;
  }
  return result;
};

export const createLineupRowObj = (battingOrder, player, position) => {
  return { battingOrder: battingOrder, player: player, position: position };
};

export const adjustLineupPosOptions = (playerPosCodes, hasDH) => {
  let positionCodeOptions = [];
  if (playerPosCodes) {
    // if lineup has a dh remove pitcher option, and add dh if it doesn't exist
    if (hasDH) {
      const pitcherIndex = playerPosCodes.indexOf("1");
      if (pitcherIndex >= 0) {
        playerPosCodes.splice(pitcherIndex, 1);
      }
    } else {
      // remove dh option, add pitcher if doesn't exist
      const dhIndex = playerPosCodes.indexOf("10");
      if (dhIndex >= 0) {
        playerPosCodes.splice(dhIndex, 1);
      }
    }
    positionCodeOptions = playerPosCodes.sort((a, b) => a - b);
  }

  return positionCodeOptions;
};

export const lineupMapper = lineupDTO => {
  return lineupDTO.map(player => {
    const battingOrder = player?.battingOrder;
    const position = player?.position;
    if (player.lastName != null) {
      delete player.position;
      delete player.battingOrder;
      return createLineupRowObj(battingOrder, player, position);
    } else {
      return createLineupRowObj(battingOrder, null, position);
    }
  });
};

export const getPlayerDisplayName = player => {
  if (player?.displayName) {
    return { value: player, label: player.displayName + addTWPPitcherDenotionToName(player) };
  } else {
    if (player?.lastName && player?.preferredName) {
      return {
        value: player,
        label: player.lastName + ", " + player.preferredName.substring(0, 1) + addTWPPitcherDenotionToName(player)
      };
    } else {
      return { value: player, label: "" };
    }
  }
};

// LC - 313
const addTWPPitcherDenotionToName = player => {
  if (player.twp) {
    return " (TWP)";
  }
  if (player.pitcher && player.position && player.position !== "1") {
    return " (P)";
  }
  return "";
};

export const isEmptyLineup = lineup => {
  // a lineup is empty if it does not contain a single player
  for (let i = 0; i < lineup?.length; i++) {
    const row = lineup[i];
    // defualt lineup rows have a player object
    if (row.player && row.player.lastName != null) {
      return false;
    }
    // game lineup rows are a flattened player object
    if (row.lastName != null) {
      return false;
    }
  }
  return true;
};

export const saveLineup = (gamesDispatch, orgId, email, selectedGames, draft, onSubmit = false, showAlert) => {
  gamesDispatch({
    type: "saveLineup",
    orgId: orgId,
    draft: draft,
    user: email,
    gamePk: selectedGames[0]?.gamePk,
    gameType: selectedGames[0]?.gameType,
    onSubmit: onSubmit,
    showAlert: showAlert
  });
};

export const configurePitchers = pitchers => {
  //sort pitchers
  pitchers.sort((a, b) => a.lastName.localeCompare(b.lastName));
  // check for twp, we are assuming only one exists per pitching staff, can add logic to change if needed
  const twpIdx = pitchers.findIndex(pitcher => pitcher.twp === true);
  let count = 1;
  if (twpIdx > -1) {
    const twoWayPLayer = pitchers[twpIdx];
    // remove player from current position
    pitchers.splice(twpIdx, 1);
    // add player to front
    pitchers.unshift(twoWayPLayer);
    count = 0;
  }
  pitchers.forEach(pitcher => {
    pitcher.pitcherCount = count;
    count++;
  });
  return pitchers;
};
