import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { PrimaryOutlinedButtonStyled } from "../../elements/PrimaryOutlinedButton";
import styled from "styled-components";

const Message = styled.div.attrs({ className: "mb-3" })`
  font-size: 1.125rem;
  font-weight: normal;
  color: #77879a;
`;

const LineupCardVersionsModal = ({ isOpen, onClose, urls }) => {
  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        onClose={() => {
          onClose(false);
        }}
      >
        <ModalHeaderWithTitleAndClose
          title="Lineup Card Versions"
          onClick={() => {
            onClose(false);
          }}
          close={() => {
            onClose(false);
          }}
        />
        <ModalBody>
          {urls
            ? urls.map((url, index) => (
                <Message key={url}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    Version {index + 1}
                  </a>
                </Message>
              ))
            : null}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <PrimaryOutlinedButtonStyled
              width={100}
              height={26}
              onClick={() => {
                onClose(false);
              }}
            >
              Dismiss
            </PrimaryOutlinedButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LineupCardVersionsModal;
