import React from "react";
import { PrimaryOutlinedButtonStyled } from "./PrimaryOutlinedButton";
import { PrimaryButtonStyled } from "./PrimaryButton";

const ButtonRow = ({ buttonArray }) => {
  return (
    <div className="d-flex mt-3" style={{ height: "50px" }}>
      {buttonArray &&
        buttonArray.map(button => {
          if (button.visible) {
            return (
              <>
                {button.style === "primary" ? (
                  <div key={button.name} className="mr-2">
                    <PrimaryButtonStyled
                      height={button.height}
                      width={button.width}
                      onClick={button.onClick}
                      disabled={button.disabled}
                    >
                      {button.content}
                    </PrimaryButtonStyled>
                  </div>
                ) : (
                  <div key={button.name} className="mr-2">
                    <PrimaryOutlinedButtonStyled
                      height={button.height}
                      width={button.width}
                      onClick={button.onClick}
                      disabled={button.disabled}
                    >
                      {button.content}
                    </PrimaryOutlinedButtonStyled>
                  </div>
                )}
              </>
            );
          }
        })}
    </div>
  );
};

export default ButtonRow;
