import React, { useContext, useEffect } from "react";
import { Checkbox } from "best-common-react";
import { EditCustomCardContext } from "../contexts/EditCustomCardContext";

const GameDateAndTime = () => {
  const editCustomCardContext = useContext(EditCustomCardContext);
  const { customCardInfo } = editCustomCardContext.state;
  const { dispatch } = editCustomCardContext;

  useEffect(() => {
    if (customCardInfo.showGameDateText == null) {
      dispatch({
        type: "setGameDate",
        checked: false
      });
    }

    if (customCardInfo.showGameTimeText == null) {
      dispatch({
        type: "setGameTime",
        checked: false
      });
    }
  }, []);

  return (
    <div className="col-lg-6 col-md-12 col-sm-12">
      <div className="d-flex">
        <div className="mr-4">
          <Checkbox
            label="Game Date"
            checked={customCardInfo.showGameDateText}
            onChange={() => {
              dispatch({
                type: "setGameDate",
                checked: !customCardInfo.showGameDateText
              });
            }}
          />
        </div>
        <Checkbox
          label="Game Time"
          checked={customCardInfo.showGameTimeText}
          onChange={() => {
            dispatch({
              type: "setGameTime",
              checked: !customCardInfo.showGameTimeText
            });
          }}
        />
      </div>
    </div>
  );
};

export default GameDateAndTime;
