import React from "react";
import LogoTransparency from "./LogoTransparency";
import LogoLayoutComponent from "./LogoLayoutComponent";

const LogoSettings = () => {
  return (
    <div className="d-flex">
      <LogoLayoutComponent />
      <LogoTransparency />
    </div>
  );
};

export default LogoSettings;
