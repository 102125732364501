import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useActiveOrgKey, useGames } from "../contexts/GamesContext";

export const useEbisSectionVisible = () => {
  const {
    state: { isAdmin }
  } = useAuth();

  const {
    state: { selectedOrg, viewableTeam }
  } = useGames();

  const orgKey = useActiveOrgKey();

  const [showEbisSection, setShowEbisSection] = useState(true);

  useEffect(() => {
    if (selectedOrg && viewableTeam) {
      setShowEbisSection(isAdmin || selectedOrg.value === viewableTeam.value);
    }
  }, [isAdmin, orgKey, viewableTeam]);

  return showEbisSection;
};
