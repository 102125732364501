import React from "react";
import styled from "styled-components";
import CardInfoTextInputs from "./CardInfoTextInputs";
import CardInfoBorderColors from "./CardInfoBorderColors";
import GameDateAndTime from "./GameDateAndTime";

const CardInfoStyle = styled.div.attrs(() => ({
  className: "mt-2"
}))``;

const CardInfoSection = () => {
  return (
    <CardInfoStyle>
      <CardInfoTextInputs />
      <div className="d-flex">
        <CardInfoBorderColors />
        <GameDateAndTime />
      </div>
    </CardInfoStyle>
  );
};

export default CardInfoSection;
