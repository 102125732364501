import React from "react";
import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";
import { Theme } from "best-common-react";
import { usePromiseTracker } from "react-promise-tracker";

const LoadingSpinner = ({ isLoading }) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div className="d-flex align-items-center">
      {promiseInProgress || isLoading ? (
        <PulseLoader color="#4c8cee" sizeUnit="px" size={10} loading={promiseInProgress || isLoading} />
      ) : null}
    </div>
  );
};

LoadingSpinner.defaultProps = {
  sizeUnit: "px",
  size: 5,
  color: Theme["mlb-blue"]
};

LoadingSpinner.propTypes = {
  sizeUnit: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string
};

export default LoadingSpinner;
