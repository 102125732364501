import React, { useEffect } from "react";
import { FormInput } from "best-common-react";
import styled from "styled-components";
import SettingsOptions from "./SettingsOptions";
import { useSettings } from "../../../contexts/SettingsContext";

const colorDropdownOptions = {
  black: {
    value: "#000000",
    label: "Black"
  },
  green: {
    value: "#1fec3b",
    label: "Green"
  },
  red: {
    value: "#ec2735",
    label: "Red"
  },
  blue: {
    value: "#33aaff",
    label: "Blue"
  }
};

const Title = styled.h5.attrs(() => ({
  className: "mr-auto mt-2"
}))`
  &&& {
    color: ${props => (props.color ? props.color : "#000000")};
    width: 200px;
  }
`;

const SelectHolder = styled.div`
  width: 15rem;
`;

const ColorSelector = ({ type }) => {
  const {
    state: { colorOptions },
    dispatch: settingsDispatch
  } = useSettings();
  const colorDropdownOpts = [];
  colorDropdownOpts.push(
    colorDropdownOptions.black,
    colorDropdownOptions.red,
    colorDropdownOptions.green,
    colorDropdownOptions.blue
  );

  let color = type === "Switch" ? colorOptions["switchHitter"] : colorOptions[type.toLowerCase()];

  return (
    <div>
      {color ? (
        <div className="d-flex mt-3">
          <Title color={color.value}>{type}</Title>
          <SelectHolder>
            <FormInput
              name="color-select"
              type="select"
              isRequired={false}
              isDisabled={false}
              label=""
              editable={true}
              value={type === "Switch" ? colorOptions["switchHitter"] : colorOptions[type.toLowerCase()]}
              options={colorDropdownOpts}
              onChangeFn={value => {
                const typeStr = "set" + type + "Color";
                settingsDispatch({ type: typeStr, [type.toLowerCase()]: value });
              }}
            />
          </SelectHolder>
        </div>
      ) : null}
    </div>
  );
};

const SettingsSection = () => {
  const {
    state: { colorOptions },
    dispatch: settingsDispatch
  } = useSettings();

  const lefty = { label: colorDropdownOptions.red.label, value: colorDropdownOptions.red.value };
  const righty = { label: colorDropdownOptions.black.label, value: colorDropdownOptions.black.value };
  const switchHitter = { label: colorDropdownOptions.blue.label, value: colorDropdownOptions.blue.value };

  useEffect(() => {
    settingsDispatch({ type: "setColorOptions", colorOptions: { lefty, righty, switchHitter } });
  }, []);

  useEffect(() => {}, [colorOptions]);

  return (
    <div>
      <div>
        <div className="col mb-2">
          <ColorSelector type="Righty" />
          <ColorSelector type="Lefty" />
          <ColorSelector type="Switch" />
        </div>
        <div>
          <SettingsOptions />
        </div>
      </div>
    </div>
  );
};

export default SettingsSection;
