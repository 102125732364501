import React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalHeaderWithTitleAndClose,
  ModalBody,
  ModalFooter,
  PrimaryButton,
  LightButton
} from "best-common-react";
import { useAlert } from "../../alerts/AlertsContext";
import LineupCardApi from "../../../httpClients/LineupCardApi";

const ConfirmMessage = styled.div.attrs({ className: "mb-3" })`
  font-size: 1.125rem;
  font-weight: 300;
`;

const LogoDeleteModal = ({ isOpen, logoToDelete, onClose, refreshLogoList }) => {
  const showAlert = useAlert();

  const deleteLogo = React.useCallback(() => {
    LineupCardApi.deleteLogo(logoToDelete.logoRequestId)
      .then(() => {
        refreshLogoList();
        showAlert(`Success`, "success");
      })
      .catch(() => {
        showAlert(`Error deleting logo`, "danger");
      });
    onClose(false);
  }, [logoToDelete]);

  return (
    <div>
      {logoToDelete && (
        <Modal show={isOpen} size="lg" onClose={() => onClose(false)}>
          <ModalHeaderWithTitleAndClose
            title="Delete Message"
            onClose={() => onClose(false)}
            close={() => onClose(false)}
          />
          <ModalBody>
            <ConfirmMessage>
              Are you sure you want to delete the logo for {logoToDelete.team} on {logoToDelete.gameDate}
            </ConfirmMessage>
          </ModalBody>
          <ModalFooter>
            <div>
              <PrimaryButton className="mr-2" onClick={deleteLogo} disabled={false}>
                Delete
              </PrimaryButton>
              <LightButton onClick={() => onClose(false)}>Cancel</LightButton>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default LogoDeleteModal;
