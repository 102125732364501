import React, { useEffect, useState } from "react";
import { useGames } from "../../../contexts/GamesContext";
import { formatDate, DateFormats, isTBDDate, addToDate, isAfterSept1 } from "../../../utils/DateUtils";
import styled from "styled-components";
import { useAuth } from "../../../contexts/AuthContext";
import { BestRadio } from "best-common-react";
import Moment from "moment";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { useAlert } from "../../alerts/AlertsContext";
import TextButtonToggle from "../../elements/TextButtonToggle";
import RosterSettingsApi from "../../../httpClients/RosterSettingsApi";

const BoldText = styled.div`
  font-weight: bold;
`;

const RadioButtonFlexStyle = styled.div.attrs(() => ({
  className: "d-flex mr-3 align-items-center"
}))``;

const RadioButtonText = styled.div.attrs(() => ({
  className: "pt-1"
}))``;

const AlertIcon = styled.i.attrs(() => ({
  className: "fas fa-exclamation-circle pr-1"
}))`
  color: orange;
  font-size: 16px;
  &&& {
    line-height: inherit;
  }
`;

const LineupNotSubmittedText = styled.div.attrs(() => ({}))`
  margin-top: 105px;
  margin-left: 10px;
  color: #ff9800;
  font-size: 12px;
  font-family: Helvetica;
  font-style: oblique;
`;

const LineupHeader = () => {
  const {
    state: { isAdmin, isUser, clubInfo, userInfo, club: clubAssoc }
  } = useAuth();
  const {
    state: {
      selectedGames,
      games,
      selectedOrg,
      otherOrg,
      rosterLimits,
      isAllStarGame,
      selectedLineupSubmitted,
      otherLineupSubmitted,
      viewableTeam
    },
    dispatch: gamesDispatch
  } = useGames();
  const selectedGame = selectedGames[0];

  const showAlert = useAlert();

  const [overrideOpts, setOverrideOpts] = useState(false);
  const [rosterRules, setRosterRules] = useState({});

  useEffect(() => {
    if (selectedGames.length === 1) {
      gamesDispatch({
        type: "setIsAllStarGame",
        isAllStarGame:
          selectedGame && selectedGame.gameType === "A" && selectedGame.seriesDescription === "All-Star Game"
      });
      gamesDispatch({
        type: "setIsSpringTrainingGame",
        isSpringTrainingGame:
          selectedGame && selectedGame.gameType === "S" && selectedGame.seriesDescription === "Spring Training"
      });
    } else {
      gamesDispatch({
        type: "setIsAllStarGame",
        isAllStarGame: false
      });
      if (userInfo && clubInfo && Object.keys(clubInfo).length > 0) {
        if (isAdmin) {
          gamesDispatch({ type: "setSelectedOrg", selectedOrg: clubInfo[0] });
        } else {
          const userClub = clubInfo.filter(club => club.name === clubAssoc);
          gamesDispatch({ type: "setSelectedOrg", selectedOrg: userClub[0] });
        }
      }
    }
  }, [selectedGame]);

  useEffect(() => {
    gamesDispatch({ type: "setViewableTeam", team: selectedOrg });
  }, [selectedOrg]);

  const isGameOneNotRescheduledWithin24Hours = () => {
    if (
      selectedGame &&
      (selectedGame.doubleHeader === "Y" || selectedGame.doubleHeader === "S") &&
      selectedGame.gameNumber === 1
    ) {
      const gameOneGameDate = formatDate(selectedGame.gameDate);
      const secondGameOfDoubleHeader = games.find(
        game => formatDate(selectedGame.gameDate) === gameOneGameDate && game.doubleHeader && game.rescheduledFrom
      );
      if (secondGameOfDoubleHeader) {
        const rescheduledDate = Moment(secondGameOfDoubleHeader.rescheduledFrom);
        const originalDate = Moment(secondGameOfDoubleHeader.gameDate);
        if (originalDate.diff(rescheduledDate, "hours") > 24) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (selectedGame && selectedGame.gamePk && overrideOpts && rosterRules && !isAllStarGame) {
      LineupCardApi.getAdminOverrideRule(parseInt(selectedGame.gamePk)).then(response => {
        // This game has no current override settings, but check if DH gets + 1
        if (
          selectedGame &&
          !isAfterSept1(selectedGame.gameDate) &&
          (selectedGame.doubleHeader === "Y" || selectedGame.doubleHeader === "S") &&
          (isGameOneNotRescheduledWithin24Hours() || selectedGame.gameNumber === 2)
        ) {
          setRosterLimitRules(rosterRules.rosterCap + 1, rosterRules.pitcherCap + 1, false);
        } else if (!response.entity && isAdmin) {
          // Set default to RosterCap/13
          setRosterLimitRules(rosterRules.rosterCap, rosterRules.pitcherCap, false);
        } else if (!response.entity && isUser) {
          gamesDispatch({
            type: "setRosterLimits",
            rosterLimits: rosterRules.rosterCap + "/" + rosterRules.pitcherCap
          });
        } else {
          gamesDispatch({
            type: "setRosterLimits",
            rosterLimits: response.entity.playersCap + "/" + response.entity.pitchersCap
          });
        }
      });
    }
  }, [selectedGame, overrideOpts, rosterRules, selectedLineupSubmitted, otherLineupSubmitted]);

  useEffect(() => {
    if (!isAllStarGame) {
      RosterSettingsApi.getActiveRosterSettingsByGameDate(selectedGame.officialDate).then(response => {
        setRosterRules(response);
      });
    }
  }, [selectedGame, isAllStarGame]);

  useEffect(() => {
    if (rosterRules && rosterRules.rosterCap) {
      RosterSettingsApi.getActiveRosterSettingsByGameDate(selectedGame.officialDate).then(response => {
        setOverrideOpts(response);
      });
    }
  }, [rosterRules]);

  const clearTables = () => {
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
  };

  const handleGameDateDisplay = () => {
    if (isTBDDate(selectedGame.gameDate)) {
      const offsetDate = addToDate(selectedGame.gameDate, 6, "hour");
      const formattedDate = formatDate(offsetDate, "ddd M/D");
      const tbdString = " - TBD";
      return formattedDate + tbdString;
    } else {
      return formatDate(selectedGame.gameDate, DateFormats.SHORT_DATETIME_WITH_TIMEZONE);
    }
  };

  const setRosterLimitRules = (numPlayers, numPitchers, displayAlert = false) => {
    gamesDispatch({ type: "setRosterLimits", rosterLimits: numPlayers + "/" + numPitchers });
    LineupCardApi.setAdminOverrideRule(selectedGame.gamePk, formatDate(selectedGame.gameDate), numPlayers, numPitchers)
      .then(() => {
        if (displayAlert) {
          showAlert(`Set Roster Limit to ${numPlayers}/${numPitchers !== "0" ? numPitchers : "Unlimited"}`, `success`);
        }
      })
      .catch(() => {
        showAlert(
          `Error setting Roster Limit to ${numPlayers}/${numPitchers !== "0" ? numPitchers : "Unlimited"}`,
          `danger`
        );
      });
    clearTables();
  };

  return (
    <div>
      <div className="d-flex col">
        <div>
          <div>{handleGameDateDisplay()}</div>
          <BoldText>{selectedGame.teams.away.team.name + " @ " + selectedGame.teams.home.team.name}</BoldText>
          <div>{selectedGame.venue.name}</div>
          <div className="mb-4">
            {selectedOrg && otherOrg && selectedOrg.value && otherOrg.value ? (
              <TextButtonToggle
                leftOption={selectedOrg}
                rightOption={otherOrg}
                onLeftActive={() => {
                  gamesDispatch({ type: "setViewableTeam", team: selectedOrg });
                }}
                onRightActive={() => {
                  gamesDispatch({ type: "setViewableTeam", team: otherOrg });
                }}
              />
            ) : null}
          </div>
        </div>
        <>
          {viewableTeam === otherOrg && !selectedLineupSubmitted && (
            <div className="d-flex">
              <LineupNotSubmittedText>
                <AlertIcon />
                {selectedOrg.name + " have not submitted a lineup"}
              </LineupNotSubmittedText>
            </div>
          )}
        </>
        <>
          {viewableTeam === selectedOrg && !otherLineupSubmitted && (
            <div className="d-flex">
              <LineupNotSubmittedText>
                <AlertIcon />
                {otherOrg?.name + " have not submitted a lineup"}
              </LineupNotSubmittedText>
            </div>
          )}
        </>
        {isAdmin && selectedGames && overrideOpts && !isAllStarGame ? (
          <div style={{ marginLeft: "15rem" }}>
            <div>Roster Limits</div>
            <div className="d-flex">
              <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
                <BestRadio
                  value={rosterRules.rosterCap + "/" + rosterRules.pitcherCap}
                  onChange={() => {
                    setRosterLimitRules(rosterRules.rosterCap.toString(), rosterRules.pitcherCap.toString(), true);
                  }}
                  checked={rosterLimits && rosterLimits === rosterRules.rosterCap + "/" + rosterRules.pitcherCap}
                />{" "}
                <RadioButtonText>
                  {rosterRules.rosterCap + "/" + (rosterRules.pitcherCap !== 0 ? rosterRules.pitcherCap : "Unlimited")}
                </RadioButtonText>
              </RadioButtonFlexStyle>
              <RadioButtonFlexStyle className="d-flex mr-3 align-items-center">
                <BestRadio
                  value={rosterRules.rosterCap + 1 + "/" + rosterRules.pitcherCap}
                  onChange={() => {
                    setRosterLimitRules(
                      (rosterRules.rosterCap + 1).toString(),
                      rosterRules.pitcherCap !== 0 ? (rosterRules.pitcherCap + 1).toString() : "0",
                      true
                    );
                  }}
                  checked={
                    rosterLimits &&
                    rosterLimits ===
                      rosterRules.rosterCap + 1 + "/" + (rosterRules.pitcherCap !== 0 ? rosterRules.pitcherCap + 1 : 0)
                  }
                />{" "}
                <RadioButtonText>
                  {rosterRules.rosterCap +
                    1 +
                    "/" +
                    (rosterRules.pitcherCap !== 0 ? rosterRules.pitcherCap + 1 : "Unlimited")}
                </RadioButtonText>
              </RadioButtonFlexStyle>
            </div>
          </div>
        ) : isUser && selectedGames && overrideOpts && rosterLimits ? (
          <div className="d-flex" style={{ marginLeft: "10rem" }}>
            {"You are allowed " +
              rosterLimits.split("/")[0] +
              " players for this game. Please contact BOC if you think this is incorrect."}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LineupHeader;
