import React, { useState } from "react";
import { CardBody, CardContainer, CardFooter, CardHeader, TabSwitcher } from "best-common-react";
import LogoFooter from "../components/logo/LogoFooter";
import CardsLoadingBlocker from "../components/loading/CardsLoadingBlocker";
import TopAndBottomLogoForm from "../components/logo/bottom/TopAndBottomLogoForm";
import TeamLogoForm from "../components/logo/team/TeamLogoForm";
import LogoManager from "../components/logo/manager/LogoManager";

const LogoContainer = () => {
  const [logo, setLogo] = useState({});

  const LogoTabs = [
    {
      name: "Bottom Logo",
      component: <TopAndBottomLogoForm setLogoFn={setLogo} type="bottom" />
    },
    {
      name: "Top Logo",
      component: <TopAndBottomLogoForm setLogoFn={setLogo} type="top" />
    },
    {
      name: "Team Logo",
      component: <TeamLogoForm setLogoFn={setLogo} type="team" />
    },
    {
      name: "Logo Manager",
      component: LogoManager
    }
  ];

  const [activeTab, setActiveTab] = useState(LogoTabs[0]);

  const onTabSwitch = ({ index }) => {
    if (index) {
      setActiveTab(LogoTabs[index]);
    }
  };

  return (
    <div>
      <CardContainer>
        <CardHeader>Logos</CardHeader>
        <CardBody>
          <CardsLoadingBlocker />
          <TabSwitcher tabs={LogoTabs} onSwitch={onTabSwitch} />
        </CardBody>
        <CardFooter>
          <LogoFooter activeTab={activeTab} logoToUpload={logo} />
        </CardFooter>
      </CardContainer>
    </div>
  );
};

export default LogoContainer;
