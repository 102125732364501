import React from "react";
import { BestSelect } from "best-common-react";
import styled from "styled-components";
import { getPlayerDisplayName } from "../../utils/LineupUtils";

const DivisionHolderStyle = styled.div.attrs(() => ({}))`
  width: ${props => (props.width ? props.width : 50)}px;
`;

const LineupRow = ({ number, playerOptions, posOptions, row, lefty, handlePlayerSwap, handlePositionSwap }) => {
  const playerOptionsFormatted = playerOptions?.map(playerOpt => {
    return {
      value: playerOpt,
      label:
        (playerOpt?.displayName || playerOpt?.lastName + ", " + playerOpt?.preferredName?.substring(0, 1)) +
        " - " +
        playerOpt?.primaryPosition
    };
  });

  const positionOptionsFormatted = posOptions?.map(opt => {
    return { value: opt, label: opt === "10" ? "DH" : opt };
  });

  const playerFormatted = getPlayerDisplayName(row.player);
  const positionFormatted =
    row && row.position ? { value: row.position, label: row.position === "10" ? "DH" : row.position } : [];

  return (
    <div className="d-flex mt-3 ml-3 align-items-center">
      <DivisionHolderStyle width={25}>
        <div style={{ fontWeight: "bold", fontSize: "18px" }}>{number}</div>
      </DivisionHolderStyle>
      <DivisionHolderStyle width={290} className="mr-1">
        <div onClick={e => e.stopPropagation()}>
          <BestSelect
            options={playerOptionsFormatted}
            value={playerFormatted}
            onChange={newPlayer => {
              handlePlayerSwap(lefty, newPlayer, row);
            }}
            portal={document.body}
            styles={{
              container: {
                height: "36px",
                fontSize: "14px"
              },
              control: {
                height: "36px",
                minHeight: "32px"
              },
              valueContainer: {
                padding: "0 8px 0 8px"
              }
            }}
            onClick={() => {}}
            clearable={row.player != null}
            placeholder="select a player"
          />
        </div>
      </DivisionHolderStyle>
      <DivisionHolderStyle width={100}>
        <div onClick={e => e.stopPropagation()}>
          <BestSelect
            options={positionOptionsFormatted}
            placeHolder="pos"
            value={positionFormatted}
            onChange={newPos => {
              handlePositionSwap(lefty, newPos, row);
            }}
            portal={document.body}
            styles={{
              container: {
                height: "36px",
                fontSize: "14px"
              },
              control: {
                height: "36px",
                minHeight: "32px"
              },
              valueContainer: {
                padding: "0 8px 0px 8px"
              }
            }}
            onClick={() => {}}
            clearable={row && row.position && row.position != ""}
            placeholder="pos"
          />
        </div>
      </DivisionHolderStyle>
    </div>
  );
};

const DefaultLineupHolder = ({ lineup, playerOptions, posOptions, lefty, handlePlayerSwap, handlePositionSwap }) => {
  return (
    <div>
      {lineup?.map((player, index) => {
        return (
          <LineupRow
            key={index + 1}
            number={index + 1}
            playerOptions={playerOptions}
            posOptions={posOptions}
            row={player}
            lefty={lefty}
            handlePlayerSwap={handlePlayerSwap}
            handlePositionSwap={handlePositionSwap}
          />
        );
      })}
    </div>
  );
};

export default DefaultLineupHolder;
